import makeAPICall from "../../utilities/apiUtils";
import {
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesError,
} from "../reducers/roleReducer";

const fetchRolesAction = () => (dispatch) => {
  dispatch(fetchRoles());
  return makeAPICall({
    path: "settings/roles",
    method: "GET",
  })
    .then((res) => {
      dispatch(fetchRolesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchRolesError(err.message));
    });
};

export { fetchRolesAction };
