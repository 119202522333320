import React, { useState, useEffect } from "react";
import { Form, Button, Select, Typography, Tooltip } from "antd";
import { useSelector, useDispatch } from "../../../../utilities/hooks";
import {
  formatMoney,
  formatBalanceCrypto,
} from "../../../../utilities/helperFunctions";
import {
  fetchCryptoAction,
  fetchCryptoChainAction,
  deleteFetchCryptoAction,
  deleteFetchCryptoChainAction,
} from "../../../../services/actions";
import Search from "../../../../library/Search";
import Loader from "../../../loaders/loading";
import QRCode from "qrcode.react";
import notify from "../../../../assets/images/notify-bank.svg";
import disableIcon from "../../../../assets/images/disableIcon.png";
import { useNavigate } from "react-router-dom";
import notifyWhite from "../../../../assets/images/notify-white.svg";

const { Paragraph } = Typography;

export default function First({ closeModal, defaultCrypto }) {
  const { wallets } = useSelector((state) => state.walletsReducer);
  const [defaultWallet, setDefauleWallet] = useState(defaultCrypto);
  const { cryptoWallets: cryptoAddress, loading } = useSelector(
    (state) => state.cryptoReducer
  );
  const { chains, loading: chainLoading } = useSelector(
    (state) => state.cryptoChainReducer
  );
  const navigate = useNavigate();
  //   const [defaultWallet, setDefauleWallet] = useState(defaultCrypto);
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [networkType, setNetworkType] = useState([]);
  const [showAddressView, setShowAddressView] = useState(false);
  const [showWallets, setShowWallets] = useState(true);
  const [addressDetails, setAddressDetails] = useState("");
  const [showTabs, setShowTabs] = useState(false);

  const { Option } = Select;

  const cryptoWallets = wallets.filter((wallet) => {
    return wallet.isCrypto === true && wallet.enabled === true;
  });

  useEffect(() => {
    if (defaultWallet.currency) {
      // console.log(defaultCrypto)
      dispatch(fetchCryptoChainAction(defaultWallet.currency));
    }
  }, [defaultWallet, dispatch]);

  useEffect(() => {
    if (chains === null) {
      return false;
    } else {
      // console.log(chains, "test");
      var output = Object.entries(chains).map(([key, value]) => ({
        key,
        value,
      }));
      // console.log(output, "out");
      setNetworkType(output);
      setShowSelect(true);
    }
  }, [chains]);

  useEffect(() => {
    if (cryptoAddress !== null) {
      setAddressDetails(cryptoAddress);
      // console.log(cryptoAddress.address, "cryptoAddress");
      setShowAddressView(true);
    } else {
      setShowAddressView(false);
      setSelectValue("");
      return false;
    }
  }, [cryptoAddress]);

  //   console.log(cryptoAddress, "test");

  const showCryptoAddress = (value) => {
    setSelectValue(value);
    dispatch(fetchCryptoAction(value));
  };

  function returnName(name, value) {
    const yes = name.filter((network) => {
      return network.value === value;
    });
    return yes;
  }

  const networkName = returnName(networkType, selectValue);
  // console.log(returnName(networkType, selectValue), "networkType");

  const selectedWallet = (item) => {
    setDefauleWallet(item);
    dispatch(deleteFetchCryptoAction());
    setSelectValue("");
    setShowTabs(false);
    setShowWallets(true);
  };

  function showOthers() {
    dispatch(deleteFetchCryptoChainAction());
    setShowWallets(false);
    setShowTabs(true);
  }

  const toolTipCurrency = (
    <div className="d-flex align-items-start">
      <img src={notifyWhite} alt="notify" />
      <h1 className="tooltip-h1 ms-2">
        This is the crypto wallet you want to add to
      </h1>
    </div>
  );

  return (
    <>
      <div>
        {loading || chainLoading ? (
          <Loader />
        ) : (
          <div>
            {showWallets ? (
              <div className="banktransfer-others">
                <h2 className="text-center">Funding details</h2>
                <br />
                <p className="wallet-addmoney-title mb-2">
                  Select top up wallet
                </p>
                <Tooltip placement="right" title={toolTipCurrency}>
                  <div
                    className="bank-transfer-modal"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={showOthers}
                    >
                      <img
                        src={defaultWallet.icon}
                        alt=""
                        className="curImage"
                      />

                      <p className="">
                        <span>{defaultWallet.currency} balance</span>
                        <br />
                        {defaultWallet.currency}&nbsp;
                        {formatMoney(defaultWallet.amount)}
                      </p>
                      <div className="ms-auto">
                        <i className="fa fa-angle-right showNow"></i>
                      </div>
                    </div>
                  </div>
                </Tooltip>

                <br />
                <div className="addmoney-form-crypto">
                  <br />
                  <p className="mb-2">Network</p>
                  {showSelect && (
                    <Select
                      name="network"
                      style={{ width: "100%" }}
                      onChange={(value) => showCryptoAddress(value)}
                      value={selectValue || undefined}
                      placeholder="Choose network"
                    >
                      {networkType.map((network, index) => {
                        return (
                          <Option value={network.value} key={index}>
                            {network.key}
                          </Option>
                        );
                      })}
                    </Select>
                  )}

                  <br />

                  {showAddressView ? (
                    <div>
                      <br />
                      <div className="notify-ban-account">
                        <div className="d-flex">
                          <img src={notify} alt="notify" />
                          <h1 className="header-bank">
                            Important! <br />
                            <span className="header-bank-par">
                              Send only {defaultWallet.currency} to this deposit
                              address
                            </span>
                            <br />
                            <span className="header-bank-par">
                              Ensure that the network is {networkName[0]?.key}
                            </span>
                          </h1>
                        </div>
                      </div>
                      <br />
                      <p>Address</p>
                      <div className="d-flex">
                        <div style={{ maxWidth: "288px" }}>
                          <h4 className="crypto-address">
                            {addressDetails.address}
                          </h4>
                        </div>

                        <div className="ms-auto align-self-center">
                          <Paragraph
                            copyable={{ text: addressDetails.address }}
                          ></Paragraph>
                        </div>
                      </div>
                      <br />
                      <div className="d-flex">
                        <div style={{ marginRight: "20px" }}>
                          <QRCode
                            value={addressDetails.address || "test"}
                            size={80}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                            renderAs={"svg"}
                          />
                        </div>
                        <div>
                          <p className="qr-text">
                            Scan QR code to get {defaultWallet.currency}
                            address
                          </p>
                          <h4 className="qr-text-link">How to scan QR code?</h4>
                        </div>
                      </div>
                      <br />
                      <Form layout="vertical">
                        <Form.Item>
                          <Button
                            size="large"
                            type="default"
                            className="pay-blue-btn"
                            onClick={closeModal}
                            style={{ width: "93%", marginLeft: "5px" }}
                          >
                            Continue
                          </Button>
                        </Form.Item>
                      </Form>
                      <h3 className="notaBena">
                        *By clicking continue you agree to the terms and
                        conditions of adding money to your eversend wallet via
                        Crypto.
                      </h3>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      {showTabs ? (
        <div
          style={{ margin: "0 auto 0 auto", width: "80%" }}
          className="tabs-for-add-money"
        >
          <h2 className="text-center wallet-add-money">Choose wallet</h2>
          <br />
          <div>
            <Search
              viewSize="header-search"
              onChange={() => {}}
              placeholder="Search for wallet"
            />
            <br />
            <p>{cryptoWallets.length} active wallets</p>
            <br />
            <div>
              {cryptoWallets.length === 0 ? (
                <div>
                  <img src={disableIcon} alt="disable" />
                  <br />
                  <h2 className="crypto-h2">
                    You have no active crypto wallet
                  </h2>
                  <p className="crypto-p">
                    Click on “Activate crypto wallet” to start transacting with
                    crypto
                  </p>
                  <br />
                  <br />
                  <div className="text-center">
                    <button
                      className="btn-btn-btn"
                      onClick={() => navigate("/wallets")}
                    >
                      Activate crypto wallet
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {wallets.map((wallet, index) =>
                    wallet.enabled === true && wallet.isCrypto === true ? (
                      <div
                        className="d-flex align-items-center wallet-add"
                        key={index}
                        onClick={() => selectedWallet(wallet)}
                      >
                        <img src={wallet.icon} alt="" className="img-flag" />
                        <p className="currency-type ms-3">
                          {wallet.currency}&nbsp;
                          {wallet.isStableCoin === false
                            ? formatBalanceCrypto(wallet.amount)
                            : formatMoney(wallet.amount)}
                          <br />
                          <span>{wallet.name}</span>
                        </p>
                        <div className="ms-auto">
                          <i className="fa fa-angle-right showNow"></i>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
