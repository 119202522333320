import React, { useEffect } from "react";

import { Alert, Button } from "antd";
import { WarningFilled, DeleteFilled } from "@ant-design/icons";

import { useDispatch, useSelector } from "../../../utilities/hooks";
import { fetchProfileAction } from "../../../services/reducers/authReducers/profileReducer";

import StyledModal from "../styled-modal";

export default function DeleteIPAddressModal({ show, onClose, item, onOk }) {
  const dispatch = useDispatch();
  const { wasUpdated } = useSelector((state) => state.ipAddressReducer);

  useEffect(() => {
    if (wasUpdated) {
      dispatch(fetchProfileAction());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasUpdated]);

  return (
    <StyledModal show={show} onClose={onClose}>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 pt-5"></div>

          <div className="col-sm-4 pt-5">
            <div className=" pt-5">
              <h5 className="mt-5 text-bold text-center">Delete IP Address?</h5>
              <p className="mb-5">
                Are you sure you want to delete {item?.label}&nbsp; IP Address
                from the list? you will not be able to undo this action.
              </p>

              <Alert
                message="Error"
                description={
                  "By deleting " +
                  item?.label +
                  "'s IP Address, the IP Address wont be able to talk to our server."
                }
                type="error"
                showIcon="true"
                icon={<WarningFilled />}
              />

              <div className="d-flex justify-content-between mt-5">
                <Button
                  style={{ width: "47%" }}
                  type="default"
                  size={"large"}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  style={{ width: "47%" }}
                  type="danger"
                  icon={<DeleteFilled />}
                  size={"large"}
                  onClick={onOk}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
