import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import _ from "lodash";
import DashboardOverview from "../pages/overview";
import PayoutsContainer from "../pages/payouts/PayoutsContainer";
import { Dashboard } from "../components/dashboardLayout/Dashboard";
import BeneficiariesContainer from "../pages/beneficiaries/BeneficiariesContainer";
import BeneficiarySingle from "../pages/beneficiaries/BeneficiarySingle";
import WalletsContainer from "../pages/wallets/WalletsContainer";
import CardsContainer from "../pages/cards/CardsContainer";
import CardsUsers from "../pages/cards/CardsUsers";
import SingleWallet from "../pages/wallets/singleWallet";
import SingleTransaction from "../pages/transactions/singleTransaction";
import TransactionsContainer from "../pages/transactions/TransactionsContainer";
import CryptoContainer from "../pages/crypto";
import SettingsContainer from "../pages/settings/SettingsContainer";
import SettingsGeneral from "../pages/settings/GeneralSettings";
import { getUser } from "../utilities/authUtils";
import ActivityLogs from "../pages/activityLogs";

const MainRoute = (props) => {
  const user = getUser();

  if (!_.get(user, "account", "")) {
    return <DashboardOverview />;
  }

  return (
    <Dashboard>
      <Routes>
        <Route path="/" element={<Navigate to="/overview" replace />} />
        <Route path="/overview" element={<DashboardOverview {...props} />} />
        <Route
          path="/overview/:id"
          element={<DashboardOverview {...props} />}
        />
        <Route
          path="/transactions"
          element={<TransactionsContainer {...props} />}
        />
        <Route
          path="/transaction/details/:name"
          element={<SingleTransaction {...props} />}
        />
        <Route path="/crypto" element={<CryptoContainer {...props} />} />
        <Route path="/cards" element={<CardsContainer {...props} />} />
        <Route path="/cards-users" element={<CardsUsers {...props} />} />
        <Route path="/wallets" element={<WalletsContainer {...props} />} />
        <Route
          path="/wallets/currency/:name"
          element={<SingleWallet {...props} />}
        />
        <Route path="/payouts" element={<PayoutsContainer {...props} />} />

        <Route
          path="/beneficiaries/details/:id"
          element={<BeneficiarySingle {...props} />}
        />
        <Route
          path="/beneficiaries"
          element={<BeneficiariesContainer {...props} />}
        />
        <Route
          path="/settings/:source?"
          element={<SettingsContainer {...props} />}
        />
        <Route
          path="/settings-general/:sourcepane?"
          element={<SettingsGeneral {...props} />}
        />
        <Route path="/logs" element={<ActivityLogs {...props} />} />
        <Route path="*" element={<Navigate to="/overview" replace />} />
      </Routes>
    </Dashboard>
  );
};

export default MainRoute;
