import { Tabs } from "antd";
import SettingsProfile from "./pages/profileSettings";
import { useNavigate, useParams } from "react-router-dom";
import SettingsSecurity from "./pages/securitySettings";
import { useIntercom } from "react-use-intercom";
import { useSelector } from "../../utilities/hooks";

const { TabPane } = Tabs;

export default function SettingsGeneral() {
  const { boot } = useIntercom();
  const { sourcepane } = useParams();
  const navigate = useNavigate();

  const { user, loading } = useSelector((state) => state.profileReducer);

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });

  return (
    <div>
      <div className="row"></div>
      <br />
      <div className="row">
        <div className="col-md-12 settings-tabs-settings">
          <Tabs
            activeKey={sourcepane || "profile"}
            onChange={(key) => {
              navigate(`/settings-general/${key}`);
            }}
            tabBarGutter={0}
          >
            <TabPane tab="My Profile" key="profile">
              <SettingsProfile user={user} loading={loading} />
            </TabPane>
            <TabPane tab="Security" key="security">
              <SettingsSecurity user={user} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
