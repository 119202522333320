import React from "react";
import accessIcon from "../../assets/images/noAccess.svg";

const NoTransactions = () => {
  return (
    <div className="completed-kyc-state">
      <div className="row">
        <div className="col-md-12 text-center">
          <img src={accessIcon} alt="" />
          <h1>No transactions</h1>
          <p>You have not perfomed any transactions yet</p>
        </div>
      </div>
    </div>
  );
};

export default NoTransactions;
