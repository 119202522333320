import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { CheckOutlined } from "@ant-design/icons";
import useMobile from "../../../hooks/useMobile";
import styles from "./steps.module.scss";

const Steps = ({
  steps,
  currentStep,
  direction = "vertical",
  className = "",
  onStepClick,
  allStepsCompleted,
}) => {
  const isMobile = useMobile();

  const handleStepClick = (index) => {
    if (onStepClick) {
      onStepClick(index);
    }
  };

  return (
    <div
      className={clsx(
        styles.wrapper,
        { [styles.horizontal]: direction === "horizontal" || isMobile },
        className
      )}
    >
      {steps.map((item, index) => {
        const isCompleted = allStepsCompleted || index < currentStep;
        const isActive = index === currentStep;

        return (
          <button
            className={clsx(styles.stepWrapper, {
              [styles.clickable]: onStepClick,
            })}
            key={index}
            onClick={() => handleStepClick(index)}
            aria-label={`Step ${index + 1}: ${item.title}`}
          >
            <div
              className={clsx(styles.step, {
                [styles.completed]: isCompleted,
                [styles.active]: isActive && !isCompleted,
              })}
            >
              {isCompleted ? <CheckOutlined /> : index + 1}
            </div>
            <p
              className={clsx(styles.title, {
                [styles.activeTitle]: isActive,
              })}
            >
              {item.title}
            </p>
          </button>
        );
      })}
    </div>
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  className: PropTypes.string,
  onStepClick: PropTypes.func,
  allStepsCompleted: PropTypes.bool,
};

export default Steps;
