import React, { useState, useEffect } from "react";
import {
  formatBalance,
  formatDateTransaction,
} from "../../utilities/helperFunctions";
// import { companyData } from "./data";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "../../utilities/hooks";
import { fetchSingleStatementAction } from "../../services/actions";
import Loader from "../../components/loaders/loading";
import { Tag, Typography } from "antd";
// import check from "../../assets/images/check-success.png";
// import pendingTrans from "../../assets/images/pending-trans-new.svg";
// import failedTrans from "../../assets/images/failed-trans.svg";
import { ReactComponent as DebitIcon } from "../../assets/images/debit-icon.svg";
import { ReactComponent as CreditIcon } from "../../assets/images/credit-icon.svg";
import { ReactComponent as ExchangeIcon } from "../../assets/images/exchange-improve.svg";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const { Paragraph } = Typography;

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "40px",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "20%",
  },
  rowTwo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "50px",
  },
  imageSocial: {
    width: "15px",
    height: "15px",
  },
  imageGray: {
    width: "80px",
    height: "20px",
  },
  date: {
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#444052",
  },
  mainText: {
    marginTop: 30,
  },
  userName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    color: "#344054",
    marginTop: 10,
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 13,
    color: "#040052",
    padding: "10px 0",
    marginBottom: 10,
    borderBottom: "1px solid #5A50FE",
  },
  newSection: {
    flexDirection: "row",
    marginTop: 20,
  },
  leftText: {
    color: "#444052",
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "normal",
    marginRight: "20px",
    marginBottom: "10px",
    width: "25%",
  },
  rightText: {
    color: "#040052",
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: "10px",
  },
});

export default function SingleTransaction() {
  const { name } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const { singleStatement, loadingSingle } = useSelector(
    (state) => state.statementReducer
  );
  const { user } = useSelector((state) => state.profileReducer);

  // console.log("singleStatement", singleStatement);

  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);

  const transactionMessages = {
    cardFunding: "Deposited from USD",
    fundCard: "Deposited from USD",
    createCard: "Deposited from USD",
    cardCreation: "Card creation",
    DeletedCard: "Card deletion",
    cardWithdraw: "Withdrawal to USD",
    withdrawCard: "Withdrawal to USD",
    CardSubscription: "Card subscription",
    CardFXCharge: "Card FX charge",
    AutoConversion: "USD negative balance auto conversion from base wallet",
  };

  useEffect(() => {
    dispatch(fetchSingleStatementAction(name));
  }, [dispatch, name]);

  useEffect(() => {
    setLoadingState(loadingSingle);
  }, [loadingSingle]);

  useEffect(() => {
    if (singleStatement) {
      setState(true);
    }
  }, [singleStatement]);

  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.row}>
          <Image style={styles.image} src="/eversend_receipt_logo.png" />
          <Text style={styles.date}>
            {formatDateTransaction(singleStatement.createdAt)}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Hi {user?.account?.name},</Text>
          <Text style={styles.userName}>
            You have created a transaction on your Eversend wallet
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.title}>Transaction Details</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>ID</Text>
          <Text style={styles.rightText}>{singleStatement.transactionId}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Type</Text>
          <Text style={styles.rightText}>
            {transTypeNoIcon(singleStatement?.type)}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Method</Text>
          <Text style={styles.rightText}>
            {singleStatement?.type === "payout"
              ? singleStatement?.meta?.type
              : singleStatement?.type === "collection"
              ? singleStatement?.meta?.method === "eversendCollection"
                ? "Eversend Wallet Collection"
                : singleStatement?.meta?.method
              : singleStatement?.type === "exchange"
              ? "N/A"
              : "N/A"}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>
            {singleStatement.type === "collection" ? "Sender" : "Beneficiary"}
          </Text>
          <Text style={styles.rightText}>
            {singleStatement.type === "payout"
              ? singleStatement?.meta?.type === "momo"
                ? singleStatement?.meta?.beneficiary?.phoneNumber
                : singleStatement?.meta?.beneficiary?.bankName
              : singleStatement.type === "collection"
              ? singleStatement?.meta?.method === "crypto"
                ? singleStatement?.meta?.source
                : singleStatement?.meta?.method === "momo"
                ? singleStatement?.meta?.phone
                  ? singleStatement?.meta?.phone
                  : singleStatement?.meta?.source
                : singleStatement?.meta?.method === "eversendCollection"
                ? singleStatement?.meta?.body?.userEmail
                : singleStatement?.meta?.source
              : "N/A"}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Amount</Text>
          <Text style={styles.rightText}>
            {singleStatement.currency}&nbsp;
            {formatBalance(singleStatement.amount)}
          </Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Status</Text>
          <Text style={styles.rightText}>
            {singleStatement.status.charAt(0).toUpperCase() +
              singleStatement.status.slice(1)}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Thanks,</Text>
          <Text style={styles.userName}>The Eversend team</Text>
        </View>
        <View style={styles.rowTwo}>
          <Image style={styles.imageGray} src="/eversend_logo_gray.png" />
          <Text style={styles.userName}>&nbsp;</Text>
        </View>
      </Page>
    </Document>
  );

  const transType = (type) => {
    let IconComponent;

    switch (type) {
      case "exchange":
        IconComponent = ExchangeIcon;
        break;

      case "payout":
        IconComponent = DebitIcon;
        break;

      case "collection":
        IconComponent = CreditIcon;
        break;

      case "cardFunding":
        IconComponent = CreditIcon;
        break;

      case "fundCard":
        IconComponent = CreditIcon;
        break;

      case "DeletedCard":
        IconComponent = DebitIcon;
        break;

      case "createCard":
        IconComponent = DebitIcon;
        break;

      case "cardWithdraw":
        IconComponent = DebitIcon;
        break;

      case "withdrawCard":
        IconComponent = DebitIcon;
        break;

      case "CardSubscription":
        IconComponent = DebitIcon;
        break;

      case "cardCreation":
        IconComponent = DebitIcon;
        break;

      case "CardFXCharge":
        IconComponent = DebitIcon;
        break;

      case "AutoConversion":
        IconComponent = DebitIcon;
        break;

      default:
        IconComponent = CreditIcon;
    }

    return (
      <div className="d-flex align-items-center justify-content-center">
        <IconComponent className="me-2" />
        <span className="capitalize-text">
          {transactionMessages[type] || type}
        </span>
      </div>
    );
  };

  function transTypeNoIcon(type) {
    return (
      <div className="capitalize-text">
        {type === "fundCard" ||
        type === "cardFunding" ||
        type === "withdrawCard" ||
        type === "cardWithdraw" ||
        type === "DeletedCard" ||
        type === "createCard" ||
        type === "CardSubscription" ||
        type === "cardCreation" ||
        type === "CardFXCharge" ||
        type === "AutoConversion"
          ? transactionMessages[type]
          : type}
      </div>
    );
  }

  const renderBasedOnType = (type) => {
    switch (type) {
      case "exchange":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Amount Received</h1>
                <p className="trans-details">
                  {singleStatement?.meta?.destination?.currency}&nbsp;
                  {formatBalance(singleStatement?.meta?.destination?.amount)}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement.transactionId} */}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                <span className="capitalize-text">
                  {" "}
                  {transTypeNoIcon(singleStatement?.type)}
                </span>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
              </div>
            </div>
          </div>
        );

      case "payout":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-3">
                <h1 className="recepient-title">Recipient</h1>
                <p className="trans-details">
                  {singleStatement?.beneficiary?.firstName}&nbsp;
                  {singleStatement?.beneficiary?.lastName}
                </p>
                <br />

                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Payout type</h1>
                <p className="trans-details capitalize-text">
                  {singleStatement?.method}
                </p>
              </div>
              <div className="col-md-3">
                <h1 className="recepient-title">Fees</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.fees)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.method === "momo" ? (
                  <>
                    <h1 className="recepient-title">Recipient phone number</h1>
                    <p className="trans-details">
                      {singleStatement?.beneficiary?.phoneNumber}
                    </p>
                  </>
                ) : (
                  <>
                    <h1 className="recepient-title">Recipient bank name</h1>
                    <p className="trans-details">
                      {singleStatement?.beneficiary?.bankName}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-3">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {/* {singleStatement?.remitOneId} */}
                  <Paragraph
                    copyable={{
                      text: singleStatement?.remitOneId,
                    }}
                  >
                    {singleStatement?.remitOneId || "N/A"}
                  </Paragraph>
                  {/* {singleStatement?.remitOneId} */}
                </p>
                <br />
                {singleStatement?.meta?.type === "momo" ? null : (
                  <>
                    <h1 className="recepient-title">
                      Recipient bank account number
                    </h1>
                    <p className="trans-details">
                      {singleStatement?.beneficiary?.bankAccountNumber}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-3">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement?.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement?.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement?.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement?.transactionId,
                    }}
                  >
                    {singleStatement?.transactionId}
                  </Paragraph>
                  {/* {singleStatement.transactionId} */}
                  {/* {singleStatement.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "collection":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-3">
                <h1 className="recepient-title">Recipient</h1>
                <p className="trans-details">
                  {singleStatement?.user?.firstName}&nbsp;
                  {singleStatement?.user?.lastName}
                </p>
                <br />

                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Collection type</h1>
                <p className="trans-details">
                  {singleStatement?.meta?.method === "eversendCollection"
                    ? "Eversend Wallet Collection"
                    : singleStatement?.meta?.method}
                </p>
              </div>
              <div className="col-md-3">
                <h1 className="recepient-title">Fees</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.meta?.fees)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                <h1 className="recepient-title">Source</h1>
                <p className="trans-details">
                  {singleStatement?.meta?.method === "crypto"
                    ? singleStatement?.meta?.source
                    : singleStatement?.meta?.method === "momo"
                    ? singleStatement?.meta?.phone
                      ? singleStatement?.meta?.phone
                      : singleStatement?.meta?.source
                    : singleStatement?.meta?.method === "eversendCollection"
                    ? singleStatement?.meta?.body?.userEmail
                    : singleStatement?.meta?.source}
                </p>
              </div>
              <div className="col-md-3">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-3">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "cardFunding":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "fundCard":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "DeletedCard":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "createCard":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "cardCreation":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "cardWithdraw":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement?.transactionId,
                    }}
                  >
                    {singleStatement?.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "withdrawCard":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "CardSubscription":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "CardFXCharge":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardResponse?.data?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      case "AutoConversion":
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {(singleStatement?.meta?.cardId ||
                  singleStatement?.meta?.cardResponse?.data?.cardId) && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardId ||
                        singleStatement?.meta?.cardResponse?.data?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div style={{ padding: "0 20px" }}>
            <div className="row">
              <div className="col-md-4">
                <h1 className="recepient-title">Balance After</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceAfter)}
                </p>
                <br />
                <h1 className="recepient-title">Balance Before</h1>
                <p className="trans-details">
                  {formatBalance(singleStatement?.balanceBefore)}
                </p>
                <br />
                {singleStatement?.meta?.cardId && (
                  <>
                    <h1 className="recepient-title">Card ID</h1>
                    <p className="trans-details">
                      {singleStatement?.meta?.cardId}
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Transaction type</h1>
                {transTypeNoIcon(singleStatement?.type)}
                <br />
                <h1 className="recepient-title">Reference number</h1>
                <p className="trans-details">
                  {singleStatement?.remitOneId || "N/A"}
                </p>
              </div>
              <div className="col-md-4">
                <h1 className="recepient-title">Status</h1>
                <p className="trans-details">
                  {singleStatement.status === "successful" ? (
                    <Tag color="success">Success</Tag>
                  ) : singleStatement.status === "failed" ? (
                    <Tag color="red">Failed</Tag>
                  ) : (
                    <Tag color="geekblue">{singleStatement.status}</Tag>
                  )}
                </p>
                <br />
                <h1 className="recepient-title">Transaction ID</h1>
                <p className="trans-details">
                  <Paragraph
                    copyable={{
                      text: singleStatement.transactionId,
                    }}
                  >
                    {singleStatement.transactionId}
                  </Paragraph>
                  {/* {singleStatement?.transactionId} */}
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div>
        <h1 className="singleWallet-h1">
          <span
            onClick={() => {
              navigate("/transactions");
            }}
          >
            Transactions
          </span>
          &nbsp;
          <i className="fa fa-angle-right"></i>&nbsp; Transaction detail
        </h1>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {state && (
            <div>
              <div className="single-transaction-financial">
                <div className="internal-bg">
                  <div className="row">
                    <div className="col-md-4 align-self-center">
                      <h1 className="singleWallet-h1">
                        <span>Having troubles?</span>
                        &nbsp;
                        <span style={{ color: "#5A50FE" }}>
                          Contact Support
                        </span>
                      </h1>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4 text-right">
                      <PDFDownloadLink
                        document={<MyDocument />}
                        fileName="transaction.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading
                            ? "Loading document..."
                            : "Download this transaction"
                        }
                      </PDFDownloadLink>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12 text-center">
                    {transType(singleStatement.type)}
                    {/* <p className="trans-details">
                     amount
                    </p> */}
                    <h1 className="trans-details-h1">
                      {singleStatement.currency}&nbsp;
                      {formatBalance(singleStatement.amount)}
                    </h1>
                    <p className="trans-details">
                      {formatDateTransaction(singleStatement.createdAt)}
                    </p>
                  </div>
                </div>
                <br />
                {renderBasedOnType(singleStatement.type)}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
