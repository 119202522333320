import { useState, useEffect } from "react";
import { message, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  updateAccountAction,
  fetchIPAddressAction,
  fetchUsersAction,
} from "../../services/actions";
// import { AccessControl } from "accesscontrol";
// import { useParams, useNavigate } from "react-router-dom";

import AddUserModal from "../../components/modals/settings/AddUser";
import EditUserModal from "../../components/modals/settings/EditUser";
import DeleteUserModal from "../../components/modals/settings/DeleteUser";
import BusinessInfo from "./pages/bussinessInfo";
import TeamInfo from "./pages/teamInfo";
import APICredentialInfo from "./pages/apiCredentialInfo";
// import IPAddressInfo from "./pages/ipAddressInfo";
import { useIntercom } from "react-use-intercom";
import { fetchProfileAction } from "../../services/reducers/authReducers/profileReducer";

const { TabPane } = Tabs;

export default function SettingsContainer() {
  const { source } = useParams();
  const navigate = useNavigate();
  const { boot } = useIntercom();
  const { user, loading: loadingUser } = useSelector(
    (state) => state.profileReducer
  );
  const { users, loading: loadingUsers } = useSelector(
    (state) => state.usersReducer
  );
  const dispatch = useDispatch();

  const [secret, setSecret] = useState({
    visible: false,
    text: "****************************************************",
  });

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  const [usersFilter, setUsersFilter] = useState([]);
  const [editUser, setEditUser] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [searchInput, setSearchInput] = useState("");

  const [profile, setProfile] = useState({
    name: null,
    address: null,
    email: null,
    phone: null,
    country: null,
    type: null,
  });

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });

  useEffect(() => {
    // dispatch(fetchProfileAction());
    dispatch(fetchIPAddressAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsersAction());
  }, [dispatch]);

  useEffect(() => {
    if (user)
      setProfile(
        (({ name, address, email, phone, country, type }) => ({
          name,
          address,
          email,
          phone,
          country,
          type,
        }))(user.account)
      );
  }, [user, users]);

  useEffect(() => {
    if (searchInput === "") {
      setUsersFilter(users);
    } else {
      let filteredUser = [...users];

      setUsersFilter(
        filteredUser.filter(
          (data) =>
            data.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
            data.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
            data.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            data.role.toLowerCase().includes(searchInput.toLowerCase()) ||
            data.phone.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    }
  }, [users, searchInput]);

  const onChangeInputValue = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  const handleProfile = () => {
    dispatch(fetchProfileAction());
  };

  function handleCopy(type) {
    if (type === "web_hook_secret") {
      navigator.clipboard.writeText(user.account.webhookSecret).then((data) => {
        message.info("Webhook secret copied");
      });
    } else if (type === "webhook") {
      navigator.clipboard.writeText(user.account.webhook).then((data) => {
        message.info("Webhook url copied");
      });
    } else if (type === "id") {
      navigator.clipboard.writeText(user.account.clientId).then((data) => {
        message.info("Client ID Copied");
      });
    } else if (type === "secret") {
      navigator.clipboard.writeText(user.account.clientSecret).then((data) => {
        message.info("Client Secret Copied");
      });
    }
  }

  const onSubmitForm = () => {
    const { name, address, type } = profile;
    dispatch(updateAccountAction({ name, address, type }));
  };

  function handleChange(event) {
    setSearchInput(event.target.value);
  }

  function handleOpenModal() {
    setShowAddUserModal(true);
  }

  function handleCloseModal() {
    setShowAddUserModal(false);
  }

  function handleOpenEditModal(item) {
    setShowEditUserModal(true);
    setEditUser(item);
  }

  function handleCloseEditModal() {
    setShowEditUserModal(false);
  }

  function handleOpenDeleteModal(item) {
    setDeleteUserModal(true);
    setDeleteUser(item);
  }

  function handleCloseDeleteModal() {
    setDeleteUserModal(false);
  }

  const isLoading = loadingUser || loadingUsers;

  return (
    <div>
      <AddUserModal show={showAddUserModal} onClose={handleCloseModal} />
      {showEditUserModal && (
        <EditUserModal
          show={showEditUserModal}
          onClose={handleCloseEditModal}
          user={editUser}
        />
      )}
      {deleteUserModal && (
        <DeleteUserModal
          show={handleOpenDeleteModal}
          onClose={handleCloseDeleteModal}
          user={deleteUser}
        />
      )}

      <div className="row">
        {/* <div className="col-md-12">
          <h1 className="main-settings" onClick={onClick}>
            Settings &nbsp; <i className="fa fa-angle-right"></i> &nbsp; &nbsp;
            <span className="text-black-50">Business settings</span>
          </h1>
        </div> */}
      </div>
      <br />
      <div className="row">
        <div className="col-md-12 settings-tabs-settings">
          <Tabs
            activeKey={source || "business"}
            onChange={(key) => {
              navigate(`/settings/${key}`);
            }}
            tabBarGutter={0}
          >
            <TabPane tab="Business" key="business">
              <BusinessInfo
                user={user?.account}
                profile={profile}
                onChangeInputValue={onChangeInputValue}
                onSubmitForm={onSubmitForm}
                isLoading={isLoading}
              />
            </TabPane>

            <TabPane tab="Team" key="team">
              <TeamInfo
                user={user?.email}
                userRole={user?.role}
                users={usersFilter}
                handleChange={handleChange}
                handleOpenModal={handleOpenModal}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
              />
            </TabPane>
            <TabPane tab="Developers" key="developers">
              <APICredentialInfo
                handleCopy={handleCopy}
                onProfile={handleProfile}
                secret={secret}
                setSecret={setSecret}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
