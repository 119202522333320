import React, { useState } from "react";

import StyledModal from "../../styled-modal";
import First from "./First";
import {
  deleteFetchCryptoAction,
  deleteFetchCryptoChainAction,
} from "../../../../services/actions";
import { useDispatch } from "../../../../utilities/hooks";
import StepsWrapper from "../../../ui/StepsWrapper";

export default function CyptoMoneyModal({ show, onClose, defaultWallet }) {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);

  // console.log(currentWallet, 'jfjf')

  const steps = [
    {
      title: "Funding details",
      content: <First defaultCrypto={defaultWallet} closeModal={onClose} />,
    },
  ];

  // function next() {
  //   setCurrent(current + 1);
  // }

  // function prev() {
  //   setCurrent(current - 1);
  // }

  function onCloseModal(item) {
    setCurrent(item);
    onClose();
    dispatch(deleteFetchCryptoAction());
    dispatch(deleteFetchCryptoChainAction());
  }

  return (
    <div>
      <StyledModal show={show} onClose={() => onCloseModal(0)}>
        <StepsWrapper title="Fund Wallet" steps={steps} currentStep={current}>
          <div className="payout-modal-text">{steps[current].content}</div>
        </StepsWrapper>
      </StyledModal>
    </div>
  );
}
