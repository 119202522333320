import React from "react";
import { ReactComponent as TagIcon } from "../../../assets/images/tag.svg";
import styles from "./styles.module.scss";
import Button from "../Button";
import PropTypes from "prop-types";

const RequestTagCard = ({ onClick }) => {
  return (
    <div className={styles.card}>
      <TagIcon className={styles.icon} />
      <div className={styles.container}>
        <p>
          Simplify your business transactions from clients and partner: Create
          an Eversend Tag to receive payments effortlessly.
        </p>
        <Button className={styles.button} onClick={onClick}>
          Create tag
        </Button>
      </div>
    </div>
  );
};

RequestTagCard.propTypes = {
  onClick: PropTypes.func,
};
export default RequestTagCard;
