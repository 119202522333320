import React from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import config from "../../../configs";
import { ReactComponent as BackArrowIcon } from "../../../assets/images/back-arrow.svg";
import { useDispatch } from "../../../utilities/hooks";
import { addBulkBeneficiariesAction } from "../../../services/actions";

export const AUTH_TOKEN = "__API_TOKEN__";
export const getAuthToken = () => window.localStorage.getItem(AUTH_TOKEN);

const { Dragger } = Upload;

export default function UploadBulkBeneficiary({
  onChangeViewHide,
  onFileUploaded,
}) {
  const dispatch = useDispatch();
  const token = getAuthToken();
  const headers = {};
  if (token) headers.Authorization = `Bearer ${token}`;

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlxs,.xlx",
    headers,
    action: `${config.BACKEND_URL}/files/beneficiaries`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        let beneficiaries;

        beneficiaries = info.file.response.data.map((item, index) => {
          let counter = 4;
          if (item.isBank) counter = 7;
          if (!item.firstName) {
            item.firstName = "";
            counter--;
          }

          if (!item.lastName) {
            item.lastName = "";
            counter--;
          }

          if (!item.country) {
            item.country = "";
            counter--;
          }

          if (!item.phoneNumber) {
            item.country = "";
            counter--;
          }

          if (!item.bankName && item.isBank) {
            item.bankName = "";
            counter--;
          }

          if (!item.bankCode && item.isBank) {
            item.bankCode = "";
            counter--;
          }

          // if (!item.bankAccountName && item.isBank) {
          //   item.bankAccountName = "";
          //   counter--;
          // }

          if (!item.bankAccountNumber && item.isBank) {
            item.bankAccountNumber = "";
            counter--;
          }

          item.checked = false;
          item.counter = counter;
          item.index = index;

          return item;
        });

        dispatch(addBulkBeneficiariesAction(beneficiaries));
        onChangeViewHide();
        onFileUploaded();
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      message.error(`An Error has Occurred`);
    },
  };

  return (
    <div className="mt-5">
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={onChangeViewHide}
      >
        <BackArrowIcon />
        <span className="color-bold">Back</span>
      </div>
      <div className="upload__card upload__card--warning mt-4">
        <div className="d-flex justify-content-center flex-column align-items-center full-width mt-4">
          <div className="alert-icon">
            <InfoCircleFilled color="#FFD200" className="color-warning" />
          </div>
          <p className="color-dark-green text-bold text-smd mt-2">
            Upload requirements
          </p>
        </div>
        <ul className="upload-docs--requirements pl-4">
          <li>File must be XLSX</li>
          <li>
            Ensure to use the Eversend template you just downloaded for adding
            group beneficiaries.
          </li>
          <li>
            If you are not sure about the template please download &nbsp;
            <Link
              download
              to="/downloads/Test.xlsx"
              target="_blank"
              className="color-primary"
            >
              here
            </Link>
          </li>
        </ul>
      </div>
      <div className="mt-4 mb-4">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support file are single excel file.</p>
        </Dragger>
        ,
      </div>
    </div>
  );
}
