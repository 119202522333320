import React, { useState, useEffect } from "react";
import {
  Menu,
  Dropdown,
  Button,
  Table,
  Space,
  Radio,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector, useDispatch } from "../../utilities/hooks";
import accessIcon from "../../assets/images/noAccess.svg";
import { fetchCardTransactionsAction } from "../../services/actions";
import Loader from "../../components/loaders/loading";
import { formatBalance, formatDate } from "../../utilities/helperFunctions";
import gears from "../../assets/images/filter-gears.svg";
import { ReactComponent as DebitIcon } from "../../assets/images/debit-icon.svg";
import { ReactComponent as CreditIcon } from "../../assets/images/credit-icon.svg";
import { ReactComponent as DownloadCardIcon } from "../../assets/images/download-card.svg";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import back from "../../assets/images/back-button.svg";
import makeAPICall from "../../utilities/apiUtils";
import CardDetails from "../../components/modals/CardDetails";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "40px",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "20%",
  },
  rowTwo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "50px",
  },
  imageSocial: {
    width: "15px",
    height: "15px",
  },
  imageGray: {
    width: "80px",
    height: "20px",
  },
  date: {
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#444052",
  },
  mainText: {
    marginTop: 30,
  },
  userName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    color: "#344054",
    marginTop: 10,
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 13,
    color: "#040052",
    padding: "10px 0",
    marginBottom: 10,
    borderBottom: "1px solid #5A50FE",
  },
  newSection: {
    flexDirection: "row",
    marginTop: 15,
  },
  leftText: {
    color: "#444052",
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "normal",
    marginRight: "20px",
    marginBottom: "10px",
    width: "25%",
  },
  rightText: {
    color: "#040052",
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: "10px",
  },
});

export default function CardTransactions({ show }) {
  const { cardTransactions, loadingCard } = useSelector(
    (state) => state.transactionsReducer
  );
  const { user } = useSelector((state) => state.profileReducer);

  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);
  const [dateValueFilter, setDateValueFilter] = useState("thirtydays");
  const [fromDateValueMomentFilter, setFromDateValueMomentFilter] = useState(
    moment().subtract(30, "days")
  );
  const [toDateValueMomentFilter, setToDateValueMomentFilter] = useState(
    moment()
  );

  const [isFilledFilter, setIsFilledFilter] = useState(false);
  const [isLoadingStateFilter, setIsLoadingStateFilter] = useState(false);
  const [transactionType, setTransactionType] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState([]);
  const [hasKyc, setHasKyc] = useState(true);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [showNow, setShowNow] = useState(false);
  const [cardDetailsModal, setCardDetailsModal] = useState(false);
  const [cardDetails, setCardDetails] = useState();
  const [isLoadingSingle, setLoadingStateSingle] = useState(false);

  const transactionMessages = {
    Funding: "Deposited from USD",
    Withdrawal: "Withdrawal to USD",
  };

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      setHasKyc(true);

      dispatch(fetchCardTransactionsAction({}));
    }
  }, [user, dispatch]);

  function onChangeFilterType(value) {
    setTransactionType(value);
  }

  useEffect(() => {
    if (
      fromDateValueMomentFilter === "" ||
      fromDateValueMomentFilter === "" ||
      transactionType === "" ||
      transactionStatus === ""
    ) {
      setIsFilledFilter(false);
    } else {
      setIsFilledFilter(true);
    }
    setIsLoadingStateFilter(loadingCard);
  }, [
    fromDateValueMomentFilter,
    transactionStatus,
    transactionType,
    toDateValueMomentFilter,
    loadingCard,
  ]);

  // useEffect(() => {
  //   dispatch(fetchWalletsAction());
  // }, [dispatch]);

  useEffect(() => {
    setLoadingState(loadingCard);
  }, [loadingCard]);

  // useEffect(() => {
  //   dispatch(fetchCryptoTransactionsAction({}));
  // }, [dispatch]);

  function capitalized(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const transactionColumns = [
    {
      title: "Transaction type",
      dataIndex: "id",
      key: "id",
      render: (id, row) => {
        return (
          <div onClick={() => showTransactions(row)}>
            <p className="v-card-table">
              <span className="me-3" style={{ verticalAlign: "middle" }}>
                {row?.type === "debit" ? <DebitIcon /> : <CreditIcon />}
              </span>
              {row?.merchant?.name === "Funding" ||
              row?.merchant?.name === "Withdrawal"
                ? transactionMessages[row?.merchant?.name]
                : capitalized(row?.merchant?.name)}
            </p>
            {/* <p className="v-card-time">
              <span className="me-2">
                {row?.type === "debit" ? <DebitIcon /> : <CreditIcon />}
              </span>
            </p> */}
          </div>
        );
      },
    },
    // {
    //   title: "Card ID",
    //   dataIndex: "cardId",
    //   key: "cardId",
    //   render: (cardId, row) => {
    //     return <span onClick={() => showTransactions(row)}>{cardId}</span>;
    //   },
    // },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, row) => (
        <div style={{ fontSize: "15px" }} onClick={() => showTransactions(row)}>
          {row?.type === "debit" ? (
            <span style={{ color: "#F25E6C" }}>
              -{row?.currency}&nbsp; {formatBalance(amount)}
            </span>
          ) : (
            <span style={{ color: "#0F730E" }}>
              +{row?.currency}&nbsp; {formatBalance(amount)}
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Balance Before",
      dataIndex: "balanceBefore",
      key: "balanceBefore",
      render(balanceBefore, row) {
        return `${row.currency} ${formatBalance(balanceBefore)}`;
      },
    },
    {
      title: "Balance After",
      dataIndex: "balanceAfter",
      key: "balanceAfter",
      render(balanceAfter, row) {
        return `${row.currency} ${formatBalance(balanceAfter)}`;
      },
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return formatDate(date);
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => {
        return (
          <button
            type="button"
            className="general-btn"
            onClick={() => onOpenModal(row)}
          >
            View card details
          </button>
        );
      },
    },
  ];

  function onOpenModal(row) {
    setLoadingStateSingle(true);
    return makeAPICall({
      path: `cards/${row.cardId}`,
      method: "GET",
    })
      .then((res) => {
        setLoadingStateSingle(false);
        setCardDetails(res.data);
        setCardDetailsModal(true);
      })
      .catch((err) => {
        setLoadingStateSingle(false);
        console.log(err);
      });
  }

  function onCloseModal() {
    setCardDetailsModal(false);
  }

  function showTransactions(row) {
    setTransactionDetails(row);
    setShowNow(true);
  }

  const plainOptions = [
    {
      label: "Success",
      value: "successful",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Failed",
      value: "failed",
    },
  ];
  const { Option } = Select;

  const onChangeStatus = (checkedValues) => {
    setTransactionStatus(checkedValues);
  };

  const changeDateValueFilter = (e) => {
    setDateValueFilter(e.target.value);
    if (e.target.value === "thirtydays") {
      setFromDateValueMomentFilter(moment().subtract(30, "days"));
      setToDateValueMomentFilter(moment());
    } else if (e.target.value === "today") {
      setFromDateValueMomentFilter(moment().startOf("day"));
      setToDateValueMomentFilter(moment().endOf("day"));
    } else if (e.target.value === "sevendays") {
      setFromDateValueMomentFilter(moment().subtract(7, "days"));
      setToDateValueMomentFilter(moment());
    } else {
      setFromDateValueMomentFilter(moment());
      setToDateValueMomentFilter(moment());
    }
  };

  const filterTransactions = () => {
    dispatch(
      fetchCardTransactionsAction({
        params: {
          from: fromDateValueMomentFilter.format("YYYY-MM-DD"),
          to: toDateValueMomentFilter.format("YYYY-MM-DD"),
          status: transactionStatus,
          type: transactionType,
        },
      })
    );
  };

  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.row}>
          <Image style={styles.image} src="/eversend_receipt_logo.png" />
          <Text style={styles.date}>
            {moment(transactionDetails?.createdAt).format("MMM DD YYYY")}&nbsp;
            {moment(transactionDetails?.createdAt).format("hh:mm A")}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Hi {user?.account?.name},</Text>
          <Text style={styles.userName}>
            You have created a transaction on your virtual card
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.title}>Transaction Details</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Transaction type</Text>
          <Text style={styles.rightText}>{transactionDetails?.type}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Transaction status</Text>
          <Text style={styles.rightText}>{transactionDetails?.status}</Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Payment amount</Text>
          <Text style={styles.rightText}>{transactionDetails?.amount}</Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Currency</Text>
          <Text style={styles.rightText}>{transactionDetails?.currency}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Balance before</Text>
          <Text style={styles.rightText}>
            {transactionDetails?.balanceBefore}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Balance after</Text>
          <Text style={styles.rightText}>
            {transactionDetails?.balanceAfter}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Thanks,</Text>
          <Text style={styles.userName}>The Eversend team</Text>
        </View>
        <View style={styles.rowTwo}>
          <Image style={styles.imageGray} src="/eversend_logo_gray.png" />
          <Text style={styles.userName}>&nbsp;</Text>
          {/* <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/twitter.png" />
          </Link> */}
          {/* <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/facebook.png" />
          </Link>
          <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/instagram.png" />
          </Link> */}
        </View>
      </Page>
    </Document>
  );

  const menu = (
    <Menu>
      <div className="playground-filter">
        <div className="pt-3 pb-1 px-3">
          <h1>Date</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Radio.Group value={dateValueFilter} onChange={changeDateValueFilter}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
            <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Range</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Space size={12}>
            <DatePicker
              style={{ width: "180px" }}
              onChange={() => {}}
              value={fromDateValueMomentFilter}
            />
            <DatePicker
              style={{ width: "180px" }}
              onChange={() => {}}
              value={toDateValueMomentFilter}
            />
          </Space>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Status</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Checkbox.Group
            options={plainOptions}
            value={transactionStatus}
            onChange={onChangeStatus}
          />
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Transaction type</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Transactions"
            onChange={onChangeFilterType}
          >
            <Option value="debit">Debit</Option>
            <Option value="credit">Credit</Option>
          </Select>
        </div>
        <div className="pt-3 pb-2 px-3 text-right">
          <Button
            type="primary btn-primary"
            size="large"
            onClick={filterTransactions}
            disabled={isFilledFilter === false ? true : false}
            loading={isLoadingStateFilter}
          >
            Filter
          </Button>
        </div>
      </div>
    </Menu>
  );

  function getData(item) {
    dispatch(
      fetchCardTransactionsAction({
        params: { limit: item.pageSize, page: item.current },
      })
    );
  }

  const prev = () => {
    setShowNow(false);
  };

  return (
    <div>
      {hasKyc ? (
        <div>
          {isLoading || isLoadingSingle ? (
            <Loader />
          ) : (
            <div>
              {showNow ? (
                <div
                  className="p-3"
                  style={{ maxWidth: "500px", margin: "auto" }}
                >
                  <div className="card-transaction-details-styling">
                    <div
                      className="d-flex align-items-center"
                      onClick={prev}
                      style={{ cursor: "pointer", marginBottom: "10px" }}
                    >
                      <img src={back} alt="back arrow" />
                      <p className="ms-2 money-request-p">Transaction</p>
                    </div>
                    <div className="text-center">
                      <h1>
                        {transactionDetails?.merchant?.name === "Funding" ||
                        transactionDetails?.merchant?.name === "Withdrawal"
                          ? transactionMessages[
                              transactionDetails?.merchant?.name
                            ]
                          : transactionDetails?.merchant?.name}
                      </h1>
                      <p className="mb-4">
                        <span className="me-2">
                          {moment(transactionDetails?.createdAt).format(
                            "MMM DD YYYY"
                          )}
                        </span>
                        <span>
                          {moment(transactionDetails?.createdAt).format(
                            "hh:mm A"
                          )}
                        </span>
                      </p>

                      <div className="d-flex justify-content-between align-items-center">
                        <h2>Transaction type</h2>
                        <h3>{transactionDetails?.type}</h3>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>Transaction status</h2>
                        <h3>{transactionDetails?.status}</h3>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>Payment amount</h2>
                        <h3>{transactionDetails?.amount}</h3>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>Currency</h2>
                        <h3>{transactionDetails?.currency}</h3>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>Balance before</h2>
                        <h3>{transactionDetails?.balanceBefore}</h3>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>Balance after</h2>
                        <h3>{transactionDetails?.balanceAfter}</h3>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>Receipt</h2>
                        <div className="d-flex justify-content-between align-items-center">
                          <DownloadCardIcon className="me-2" />
                          <PDFDownloadLink
                            document={<MyDocument />}
                            fileName="transaction.pdf"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download"
                            }
                          </PDFDownloadLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {!cardTransactions ||
                  cardTransactions.transactions?.length === 0 ? (
                    <div className="completed-kyc-state">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <img src={accessIcon} alt="" />
                          <h1>No transactions</h1>
                          <p>You have not performed any transactions yet</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="bg-white p-2 this-table"
                      style={{ position: "relative" }}
                    >
                      <div className="transaction-filters">
                        <Space wrap>
                          <Dropdown overlay={menu} placement="bottomRight">
                            <Button>
                              <span>
                                <img src={gears} alt="" />
                              </span>
                              &nbsp; Filters <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>
                      </div>
                      <Table
                        columns={transactionColumns}
                        dataSource={cardTransactions.transactions}
                        pagination={{
                          pageSize: cardTransactions.limit,
                          pageSizeOptions: [10, 20, 30],
                          showSizeChanger: true,
                          total: cardTransactions.total,
                          position: ["topLeft"],
                          current: cardTransactions.page,
                          showTitle: true,
                          showTotal: (total) => `Total result: ${total} `,
                        }}
                        rowKey="transactionId"
                        scroll={{ x: "max-content" }}
                        onChange={(pagination) => {
                          getData(pagination);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
      {cardDetailsModal && (
        <CardDetails
          cardDetailsAll={cardDetails}
          tabIndexNow={0}
          onClose={onCloseModal}
          show={cardDetailsModal}
        />
      )}
    </div>
  );
}
