import ACTIONS from "./constants";

// export * from './auth';
export * from "./search";

export const register = (userDetail) => ({
  type: ACTIONS.REGISTER_USER,
  payload: userDetail,
});

export const registerError = (message) => ({
  type: ACTIONS.REGISTER_USER_ERROR,
  payload: message,
});

export const registerSuccess = (userDetail) => ({
  type: ACTIONS.REGISTER_USER_SUCCESS,
  payload: userDetail,
});

export const registerVerify = () => ({
  type: ACTIONS.VERIFICATION_EMAIL,
});

export const login = (userDetail) => ({
  type: ACTIONS.LOGIN_USER,
  payload: userDetail,
});

export const loginError = (message) => ({
  type: ACTIONS.LOGIN_USER_ERROR,
  payload: message,
});

export const loginSuccess = (userDetail) => ({
  type: ACTIONS.LOGIN_USER_SUCCESS,
  payload: userDetail,
});

export const twoFactor = (userDetail) => ({
  type: ACTIONS.TWO_FACTOR_USER,
  payload: userDetail,
});

export const twoFactorError = (message) => ({
  type: ACTIONS.TWO_FACTOR_USER_ERROR,
  payload: message,
});

export const twoFactorSuccess = (userDetail) => ({
  type: ACTIONS.TWO_FACTOR_USER_SUCCESS,
  payload: userDetail,
});

export const adminProfile = (userDetail) => ({
  type: ACTIONS.ADMIN_PROFILE,
  payload: userDetail,
});

export const adminProfileError = (message) => ({
  type: ACTIONS.ADMIN_PROFILE_ERROR,
  payload: message,
});

export const adminProfileSuccess = (userDetail) => ({
  type: ACTIONS.ADMIN_PROFILE_SUCCESS,
  payload: userDetail,
});

export const businessInfo = (userDetail) => ({
  type: ACTIONS.BUSINESS_INFO,
  payload: userDetail,
});

export const businessInfoError = (message) => ({
  type: ACTIONS.BUSINESS_INFO_ERROR,
  payload: message,
});

export const businessInfoSuccess = (userDetail) => ({
  type: ACTIONS.BUSINESS_INFO_SUCCESS,
  payload: userDetail,
});

export const businessInfoReset = () => ({
  type: ACTIONS.BUSINESS_INFO_RESET,
});

export const businessGetInfo = (userDetail) => ({
  type: ACTIONS.BUSINESS_INFO_GET,
  payload: userDetail,
});

export const businessGetInfoError = (message) => ({
  type: ACTIONS.BUSINESS_INFO_GET_ERROR,
  payload: message,
});

export const businessGetInfoSuccess = (userDetail) => ({
  type: ACTIONS.BUSINESS_INFO_GET_SUCCESS,
  payload: userDetail,
});

export const businessKyb = (userDetail) => ({
  type: ACTIONS.BUSINESS_KYB,
  payload: userDetail,
});

export const businessKybError = (message) => ({
  type: ACTIONS.BUSINESS_KYB_ERROR,
  payload: message,
});

export const businessKybSuccess = (userDetail) => ({
  type: ACTIONS.BUSINESS_KYB_SUCCESS,
  payload: userDetail,
});

export const sendPhoneCode = (userDetail) => ({
  type: ACTIONS.SEND_PHONE_CODE,
  payload: userDetail,
});
export const resetSendPhoneCode = () => ({
  type: ACTIONS.RESET_SEND_PHONE_CODE,
});
export const sendPhoneCodeError = (message) => ({
  type: ACTIONS.SEND_PHONE_CODE_ERROR,
  payload: message,
});

export const sendPhoneCodeSuccess = (userDetail) => ({
  type: ACTIONS.SEND_PHONE_CODE_SUCCESS,
  payload: userDetail,
});

export const verifyPhoneCode = (userDetail) => ({
  type: ACTIONS.VERIFY_PHONE_CODE,
  payload: userDetail,
});

export const resetVerifyPhoneCode = () => ({
  type: ACTIONS.RESET_VERIFY_PHONE_CODE,
});

export const verifyPhoneCodeError = (message) => ({
  type: ACTIONS.VERIFY_PHONE_CODE_ERROR,
  payload: message,
});

export const verifyPhoneCodeSuccess = (userDetail) => ({
  type: ACTIONS.VERIFY_PHONE_CODE_SUCCESS,
  payload: userDetail,
});

export const verifyAccount = (userDetail) => ({
  type: ACTIONS.VERIFY_USER,
  payload: userDetail,
});

export const verifyAccountError = (message) => ({
  type: ACTIONS.VERIFY_USER_ERROR,
  payload: message,
});

export const verifyAccountSuccess = (userDetail) => ({
  type: ACTIONS.VERIFY_USER_SUCCESS,
  payload: userDetail,
});

export const fetchProfile = (userDetail) => ({
  type: ACTIONS.FETCH_USER,
  payload: userDetail,
});

export const fetchProfileError = (message) => ({
  type: ACTIONS.FETCH_USER_ERROR,
  payload: message,
});

export const fetchProfileSuccess = (userDetail) => ({
  type: ACTIONS.FETCH_USER_SUCCESS,
  payload: userDetail,
});

export const fetchWallets = () => ({
  type: ACTIONS.FETCH_WALLETS,
});

export const fetchWalletsError = (message) => ({
  type: ACTIONS.FETCH_WALLETS_ERROR,
  payload: message,
});

export const fetchWalletsSuccess = (wallets) => ({
  type: ACTIONS.FETCH_WALLETS_SUCCESS,
  payload: wallets,
});

export const fetchCards = () => ({
  type: ACTIONS.FETCH_CARDS,
});

export const fetchCardsError = (message) => ({
  type: ACTIONS.FETCH_CARDS_ERROR,
  payload: message,
});

export const fetchCardsSuccess = (wallets) => ({
  type: ACTIONS.FETCH_CARDS_SUCCESS,
  payload: wallets,
});

export const fetchCardsReset = (wallets) => ({
  type: ACTIONS.FETCH_CARDS_DEFAULT,
  payload: wallets,
});

export const fetchCardAnalytics = () => ({
  type: ACTIONS.FETCH_CARD_ANALYTICS,
});

export const fetchCardAnalyticError = (message) => ({
  type: ACTIONS.FETCH_CARD_ANALYTICS_ERROR,
  payload: message,
});

export const fetchCardAnalyticSuccess = (wallets) => ({
  type: ACTIONS.FETCH_CARD_ANALYTICS_SUCCESS,
  payload: wallets,
});

export const fetchTransactions = () => ({
  type: ACTIONS.FETCH_TRANSACTIONS,
});

export const fetchTransactionsError = (message) => ({
  type: ACTIONS.FETCH_TRANSACTIONS_ERROR,
  payload: message,
});

export const fetchTransactionsSuccess = (transactions) => ({
  type: ACTIONS.FETCH_TRANSACTIONS_SUCCESS,
  payload: transactions,
});

export const fetchCryptoTransactions = () => ({
  type: ACTIONS.FETCH_CRYPTO_TRANSACTIONS,
});

export const fetchCryptoTransactionsError = (message) => ({
  type: ACTIONS.FETCH_CRYPTO_TRANSACTIONS_ERROR,
  payload: message,
});

export const fetchCryptoTransactionsSuccess = (transactions) => ({
  type: ACTIONS.FETCH_CRYPTO_TRANSACTIONS_SUCCESS,
  payload: transactions,
});

export const fetchCardTransactions = () => ({
  type: ACTIONS.FETCH_CARD_TRANSACTIONS,
});

export const fetchCardTransactionsError = (message) => ({
  type: ACTIONS.FETCH_CARD_TRANSACTIONS_ERROR,
  payload: message,
});

export const fetchCardTransactionsSuccess = (transactions) => ({
  type: ACTIONS.FETCH_CARD_TRANSACTIONS_SUCCESS,
  payload: transactions,
});

export const fetchCryptoAddresses = () => ({
  type: ACTIONS.FETCH_CRYPTO_ADDRESSES,
});

export const fetchCryptoAddressesError = (message) => ({
  type: ACTIONS.FETCH_CRYPTO_ADDRESSES_ERROR,
  payload: message,
});

export const fetchCryptoAddressesSuccess = (transactions) => ({
  type: ACTIONS.FETCH_CRYPTO_ADDRESSES_SUCCESS,
  payload: transactions,
});

export const fetchBeneficiaries = () => ({
  type: ACTIONS.FETCH_BENEFICIARIES,
});

export const fetchBeneficiariesError = (message) => ({
  type: ACTIONS.FETCH_BENEFICIARIES_ERROR,
  payload: message,
});

export const fetchBeneficiariesSuccess = (beneficiaries) => ({
  type: ACTIONS.FETCH_BENEFICIARIES_SUCCESS,
  payload: beneficiaries,
});

export const fetchSingleBeneficiary = () => ({
  type: ACTIONS.FETCH_SINGLE_BENEFICIARY,
});

export const fetchSingleBeneficiaryError = (message) => ({
  type: ACTIONS.FETCH_SINGLE_BENEFICIARY_ERROR,
  payload: message,
});

export const fetchSingleBeneficiarySuccess = (beneficiaries) => ({
  type: ACTIONS.FETCH_SINGLE_BENEFICIARY_SUCCESS,
  payload: beneficiaries,
});

export const addBeneficiaries = () => ({
  type: ACTIONS.ADD_BENEFICIARIES,
});

export const addBeneficiariesError = (message) => ({
  type: ACTIONS.ADD_BENEFICIARIES_ERROR,
  payload: message,
});

export const addBeneficiariesSuccess = (beneficiaries) => ({
  type: ACTIONS.ADD_BENEFICIARIES_SUCCESS,
  payload: beneficiaries,
});

export const addBulkBeneficiaries = (message) => ({
  type: ACTIONS.ADD_BULK_BENEFICIARIES,
  payload: message,
});

export const deleteSingleBeneficiaries = (message) => ({
  type: ACTIONS.DELETE_SINGLE_BENEFICIARIES,
  payload: message,
});

export const deleteSingleBeneficiariesByIndex = (id) => ({
  type: ACTIONS.DELETE_SINGLE_BENEFICIARIES_BY_INDEX,
  payload: id,
});

export const deleteAllBeneficiaries = (beneficiaries) => ({
  type: ACTIONS.DELETE_ALL_BENEFICIARIES,
});

export const checkAllBeneficiaries = (beneficiaries) => ({
  type: ACTIONS.CHECK_ALL_BENEFICIARIES,
});

export const checkSingleBeneficiaries = (index) => ({
  type: ACTIONS.CHECK_SINGLE_BENEFICIARIES,
  payload: index,
});

export const unCheckAllBeneficiaries = (beneficiaries) => ({
  type: ACTIONS.UNCHECK_ALL_BENEFICIARIES,
});

export const unCheckSingleBeneficiaries = (index) => ({
  type: ACTIONS.UNCHECK_SINGLE_BENEFICIARIES,
  payload: index,
});

export const editSingleBeneficiary = (index) => ({
  type: ACTIONS.EDIT_SINGLE_BENEFICIARY,
  payload: index,
});

export const updateProfile = (userDetail) => ({
  type: ACTIONS.UPDATE_PROFILE,
  payload: userDetail,
});

export const updateProfileError = (message) => ({
  type: ACTIONS.UPDATE_PROFILE_ERROR,
  payload: message,
});

export const updateProfileSuccess = (userDetail) => ({
  type: ACTIONS.UPDATE_PROFILE_SUCCESS,
  payload: userDetail,
});

export const fetchUsers = () => ({
  type: ACTIONS.FETCH_USERS,
});

export const fetchUsersError = (message) => ({
  type: ACTIONS.FETCH_USERS_ERROR,
  payload: message,
});

export const fetchUsersSuccess = (users) => ({
  type: ACTIONS.FETCH_USERS_SUCCESS,
  payload: users,
});

export const addUser = () => ({
  type: ACTIONS.ADD_USER,
});

export const addUserError = (message) => ({
  type: ACTIONS.ADD_USER_ERROR,
  payload: message,
});

export const addUserSuccess = (message) => ({
  type: ACTIONS.ADD_USER_SUCCESS,
  payload: message,
});

export const fetchIPAddress = () => ({
  type: ACTIONS.FETCH_IP_ADDRESS,
});

export const fetchIPAddressError = (message) => ({
  type: ACTIONS.FETCH_IP_ADDRESS_ERROR,
  payload: message,
});

export const fetchIPAddressSuccess = (users) => ({
  type: ACTIONS.FETCH_IP_ADDRESS_SUCCESS,
  payload: users,
});

export const addIPAddress = () => ({
  type: ACTIONS.ADD_IP_ADDRESS,
});

export const addIPAddressError = (message) => ({
  type: ACTIONS.ADD_IP_ADDRESS_ERROR,
  payload: message,
});

export const addIPAddressSuccess = (message) => ({
  type: ACTIONS.ADD_IP_ADDRESS_SUCCESS,
  payload: message,
});

export const deleteIPAddress = () => ({
  type: ACTIONS.DELETE_IP_ADDRESS,
});

export const deleteIPAddressError = (message) => ({
  type: ACTIONS.DELETE_IP_ADDRESS_ERROR,
  payload: message,
});

export const deleteIPAddressSuccess = (message) => ({
  type: ACTIONS.DELETE_IP_ADDRESS_SUCCESS,
  payload: message,
});

export const fetchAnalyticsBalance = () => ({
  type: ACTIONS.FETCH_ANALYTICS_BALANCE,
});

export const fetchAnalyticsBalanceError = (message) => ({
  type: ACTIONS.FETCH_ANALYTICS_BALANCE_ERROR,
  payload: message,
});

export const fetchAnalyticsBalanceSuccess = (accountBalance) => ({
  type: ACTIONS.FETCH_ANALYTICS_BALANCE_SUCCESS,
  payload: accountBalance,
});

export const fetchAnalyticsTransactions = () => ({
  type: ACTIONS.FETCH_ANALYTICS_TRANSACTIONS,
});

export const fetchAnalyticsTransactionsError = (message) => ({
  type: ACTIONS.FETCH_ANALYTICS_TRANSACTIONS_ERROR,
  payload: message,
});

export const fetchAnalyticsTransactionsSuccess = (transactions, change) => ({
  type: ACTIONS.FETCH_ANALYTICS_TRANSACTIONS_SUCCESS,
  payload: { transactions, change },
});
export const createExchangeQuotation = () => ({
  type: ACTIONS.CREATE_EXCHANGE_QUOTATION,
});

export const createExchangeQuotationError = (message) => ({
  type: ACTIONS.CREATE_EXCHANGE_QUOTATION_ERROR,
  payload: message,
});

export const createExchangeQuotationSuccess = (message) => ({
  type: ACTIONS.CREATE_EXCHANGE_QUOTATION_SUCCESS,
  payload: message,
});

export const deletePayout = (reminder) => ({
  type: ACTIONS.DELETE_PAYOUT,
  payload: reminder,
});

export const deleteStatament = (reminder) => ({
  type: ACTIONS.DELETE_STATEMENT,
  payload: reminder,
});

export const deletePayoutQuotation = (reminder) => ({
  type: ACTIONS.DELETE_PAYOUT_QUOTATION,
  payload: reminder,
});

export const createPayout = () => ({
  type: ACTIONS.CREATE_PAYOUT,
});

export const createPayoutError = (message) => ({
  type: ACTIONS.CREATE_PAYOUT_ERROR,
  payload: message,
});

export const createPayoutSuccess = (message) => ({
  type: ACTIONS.CREATE_PAYOUT_SUCCESS,
  payload: message,
});

export const createPayoutQuotation = () => ({
  type: ACTIONS.CREATE_PAYOUT_QUOTATION,
});

export const createPayoutQuotationError = (message) => ({
  type: ACTIONS.CREATE_PAYOUT_QUOTATION_ERROR,
  payload: message,
});

export const createPayoutQuotationSuccess = (message) => ({
  type: ACTIONS.CREATE_PAYOUT_QUOTATION_SUCCESS,
  payload: message,
});

export const submitExchangeQuotation = () => ({
  type: ACTIONS.SUBMITTED_QUOTATION,
});

export const submitExchangeQuotationError = (message) => ({
  type: ACTIONS.SUBMITTED_QUOTATION_ERROR,
  payload: message,
});

export const submitExchangeQuotationSuccess = (message) => ({
  type: ACTIONS.SUBMITTED_QUOTATION_SUCCESS,
  payload: message,
});

export const fetchBanks = () => ({
  type: ACTIONS.FETCH_BANKS,
});

export const fetchBanksSuccess = (banks) => ({
  type: ACTIONS.FETCH_BANKS_SUCCESS,
  payload: banks,
});

export const fetchBanksError = (message) => ({
  type: ACTIONS.FETCH_BANKS_ERROR,
  payload: message,
});

export const fetchSourceCountries = () => ({
  type: ACTIONS.FETCH_SOURCE_COUNTRY,
});

export const fetchSourceCountriesSuccess = (banks) => ({
  type: ACTIONS.FETCH_SOURCE_COUNTRY_SUCCESS,
  payload: banks,
});

export const fetchSourceCountriesError = (message) => ({
  type: ACTIONS.FETCH_SOURCE_COUNTRY_ERROR,
  payload: message,
});

export const fetchDestinationCountries = () => ({
  type: ACTIONS.FETCH_DESTINATION_COUNTRY,
});

export const fetchDestinationCountriesSuccess = (banks) => ({
  type: ACTIONS.FETCH_DESTINATION_COUNTRY_SUCCESS,
  payload: banks,
});

export const fetchDestinationCountriesError = (message) => ({
  type: ACTIONS.FETCH_DESTINATION_COUNTRY_ERROR,
  payload: message,
});

export const fetchCountries = () => ({
  type: ACTIONS.FETCH_COUNTRY,
});

export const fetchCountriesSuccess = (banks) => ({
  type: ACTIONS.FETCH_COUNTRY_SUCCESS,
  payload: banks,
});

export const fetchCountriesError = (message) => ({
  type: ACTIONS.FETCH_COUNTRY_ERROR,
  payload: message,
});

export const fetchLogs = () => ({
  type: ACTIONS.FETCH_LOGS,
});

export const fetchLogsSuccess = (banks) => ({
  type: ACTIONS.FETCH_LOGS_SUCCESS,
  payload: banks,
});

export const fetchLogsError = (message) => ({
  type: ACTIONS.FETCH_LOGS_ERROR,
  payload: message,
});

export const resetQuotation = () => ({
  type: ACTIONS.RESET_QUOTATION_STATE,
});

export const fetchActivateWallet = () => ({
  type: ACTIONS.FETCH_ACTIVATE,
});

export const fetchActivateWalletSuccess = (activeWallet) => ({
  type: ACTIONS.FETCH_ACTIVATE_SUCCESS,
  payload: activeWallet,
});

export const fetchActivateWalletError = (message) => ({
  type: ACTIONS.FETCH_ACTIVATE_ERROR,
  payload: message,
});

export const fetchDeactivateWallet = (activateWallet) => ({
  type: ACTIONS.FETCH_DEACTIVATE,
  payload: activateWallet,
});

export const fetchDeactivateWalletSuccess = (deactiveWallet) => ({
  type: ACTIONS.FETCH_DEACTIVATE_SUCCESS,
  payload: deactiveWallet,
});

export const fetchDeactivateWalletError = (message) => ({
  type: ACTIONS.FETCH_DEACTIVATE_ERROR,
  payload: message,
});

export const fetchReOrderWallet = (reorderWallet) => ({
  type: ACTIONS.FETCH_REORDER,
  payload: reorderWallet,
});

export const fetchReOrderWalletSuccess = (reorderWallet) => ({
  type: ACTIONS.FETCH_REORDER_SUCCESS,
  payload: reorderWallet,
});

export const fetchReOrderWalletError = (message) => ({
  type: ACTIONS.FETCH_REORDER_ERROR,
  payload: message,
});

export const fetchStatement = () => ({
  type: ACTIONS.FETCH_STATEMENT,
});

export const fetchStatementError = (message) => ({
  type: ACTIONS.FETCH_STATEMENT_ERROR,
  payload: message,
});

export const fetchStatementSuccess = (transactions) => ({
  type: ACTIONS.FETCH_STATEMENT_SUCCESS,
  payload: transactions,
});

export const fetchSingleStatement = () => ({
  type: ACTIONS.FETCH_SINGLE_STATEMENT,
});

export const fetchSingleStatementError = (message) => ({
  type: ACTIONS.FETCH_SINGLE_STATEMENT_ERROR,
  payload: message,
});

export const fetchSingleStatementSuccess = (transactions) => ({
  type: ACTIONS.FETCH_SINGLE_STATEMENT_SUCCESS,
  payload: transactions,
});
export const hideUploadBeneficiary = () => ({
  type: ACTIONS.HIDE_UPLOAD_BENEFICIARY,
});

export const showUploadBeneficiary = () => ({
  type: ACTIONS.SHOW_UPLOAD_BENEFICIARY,
});

export const pauseResumeBulkUploadBeneficiaries = () => ({
  type: ACTIONS.PAUSE_RESUME_BULK_UPLOAD_BENEFICIARIES,
});

export const markBulkBeneficiaryEntryUploadStatus = (status, beneficiary) => ({
  type: ACTIONS.MARK_BULK_BENEFICIARY_ENTRY_UPLOAD_STATUS,
  payload: { status, beneficiary },
});

export const currentUploadBeneficiary = (current) => ({
  type: ACTIONS.CURRENT_UPLOAD_BENEFICIARY,
  payload: current,
});

export const isUploadBeneficiaryCompleted = (status) => ({
  type: ACTIONS.COMPLETED_STATUS_UPLOAD_BENEFICIARY,
  payload: status,
});

export const percentageOfUploadBeneficiary = (percent) => ({
  type: ACTIONS.PERCENTAGE_UPLOAD_BENEFICIARY,
  payload: percent,
});

export const resetUploadBeneficiary = () => ({
  type: ACTIONS.RESET_UPLOAD_BENEFICIARY,
});

export const toggleExchangeModal = (name) => ({
  type: ACTIONS.TOGGLE_EXCHANGE_MODAL,
  payload: name,
});

export const toggleAddMoneyModal = (name) => ({
  type: ACTIONS.TOGGLE_ADDMONEY_MODAL,
  payload: name,
});

export const toggleSendMoneyModal = () => ({
  type: ACTIONS.TOGGLE_SENDMONEY_MODAL,
});

export const toggleCreateCardModal = () => ({
  type: ACTIONS.TOGGLE_CREATECARD_MODAL,
});

export const toggleSendMoneyBeneficiaryModal = (name) => ({
  type: ACTIONS.TOGGLE_SENDMONEY_BENEFICIARY_MODAL,
  payload: name,
});

export const changeSecret = (payload) => ({
  type: ACTIONS.CHANGE_API_SECRET,
  payload,
});

export const forgotPassword = () => ({
  type: ACTIONS.FORGOT_PASSWORD,
});

export const forgotPasswordError = (message) => ({
  type: ACTIONS.FORGOT_PASSWORD_ERROR,
  payload: message,
});

export const forgotPasswordSuccess = (payload) => ({
  type: ACTIONS.FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordReset = (payload) => ({
  type: ACTIONS.PASSWORD_RESET,
  payload,
});

export const changePassword = () => ({
  type: ACTIONS.CHANGE_PASSWORD,
});

export const changePasswordError = (message) => ({
  type: ACTIONS.CHANGE_PASSWORD_ERROR,
  payload: message,
});

export const changePasswordSuccess = (payload) => ({
  type: ACTIONS.CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const resetChangePasswordState = () => ({
  type: ACTIONS.RESET_CHANGE_PASSWORD,
});

export const verifyPasswordReset = () => ({
  type: ACTIONS.VERIFY_RESET_PASSWORD,
});

export const verifyPasswordResetError = (message) => ({
  type: ACTIONS.VERIFY_RESET_PASSWORD_ERROR,
  payload: message,
});

export const verifyPasswordResetSuccess = (payload) => ({
  type: ACTIONS.VERIFY_RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPassword = () => ({
  type: ACTIONS.RESET_PASSWORD,
});

export const resetPasswordError = (message) => ({
  type: ACTIONS.RESET_PASSWORD_ERROR,
  payload: message,
});

export const resetPasswordSuccess = () => ({
  type: ACTIONS.RESET_PASSWORD_SUCCESS,
});

export const resetForgotPasswordState = () => ({
  type: ACTIONS.RESET_FORGOT_PASSWORD,
});

export const addMoney = () => ({
  type: ACTIONS.ADD_MONEY,
});

export const addMoneyError = (message) => ({
  type: ACTIONS.ADD_MONEY_ERROR,
  payload: message,
});

export const addMoneySuccess = () => ({
  type: ACTIONS.ADD_MONEY_SUCCESS,
});

export const fetchUserBanks = () => ({
  type: ACTIONS.FETCH_USER_BANKS,
});

export const fetchUserBanksSuccess = (banks) => ({
  type: ACTIONS.FETCH_USER_BANKS_SUCCESS,
  payload: banks,
});

export const fetchUserBanksError = (message) => ({
  type: ACTIONS.FETCH_USER_BANKS_ERROR,
  payload: message,
});

export const fetchUserCrypto = () => ({
  type: ACTIONS.FETCH_USER_CRYPTO,
});

export const fetchUserCryptoSuccess = (banks) => ({
  type: ACTIONS.FETCH_USER_CRYPTO_SUCCESS,
  payload: banks,
});

export const fetchUserCryptoError = (message) => ({
  type: ACTIONS.FETCH_USER_CRYPTO_ERROR,
  payload: message,
});

export const deleteFetchCrypto = () => ({
  type: ACTIONS.RESET_FETCH_USER_CRYPTO,
});

export const fetchCryptoChain = () => ({
  type: ACTIONS.FETCH_CHAIN_CRYPTO,
});

export const fetchCryptoChainSuccess = (banks) => ({
  type: ACTIONS.FETCH_CHAIN_CRYPTO_SUCCESS,
  payload: banks,
});

export const fetchCryptoChainError = (message) => ({
  type: ACTIONS.FETCH_CHAIN_CRYPTO_ERROR,
  payload: message,
});

export const deleteFetchCryptoChain = () => ({
  type: ACTIONS.RESET_FETCH_CHAIN_CRYPTO,
});

export const logoutUser = () => ({
  type: ACTIONS.LOGOUT_USER,
});

export const fetchAllForms = (userDetail) => ({
  type: ACTIONS.SET_NEW_FORM_FIELDS,
  payload: userDetail,
});

export const fetchResetAllForms = () => ({
  type: ACTIONS.RESET_NEW_FORM_FIELDS,
});

export const fetchTags = (tags) => ({
  type: ACTIONS.FETCH_TAGS,
  payload: tags,
});
