import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import {
  changePasswordAction,
  resetChangePasswordAction,
} from "../../../services/actions";
import Loader from "../../../components/Loader";
import { message, Radio, Space } from "antd";
import { useNavigate } from "react-router-dom";
import PinModal from "../../../components/modals/2FAPin";
import makeAPICall from "../../../utilities/apiUtils";

export default function SettingsSecurity({ user, onClick }) {
  const navigate = useNavigate();
  const { password, loading } = useSelector((state) => state.changePassword);
  const dispatch = useDispatch();
  const [method, setMethod] = useState(
    (user.enabledText2fa && "text") ||
      (user.enabledEmail2fa && "email") ||
      (user.enabledApp2fa && "apps") ||
      ""
  );
  const [otpInput, setOtpInput] = useState();
  const [modals, setModals] = useState({
    setPinModal: false,
  });
  const [details, setDetails] = useState();
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const [profile, setProfile] = useState({
    oldPassword: null,
    newPassword: null,
    confirmNewPassword: null,
  });

  const onChangeInputValue = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitForm = () => {
    const { oldPassword, newPassword, confirmNewPassword } = profile;
    if (newPassword !== confirmNewPassword) {
      message.error("passwords doesn't match", 5);
    } else {
      dispatch(changePasswordAction({ oldPassword, newPassword }));
    }
  };

  useEffect(() => {
    if (password) {
      message.success("password changed successfully", 5);
      dispatch(resetChangePasswordAction());
      setProfile({
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      });
    }
  }, [password, dispatch]);

  function onChangeBank(e) {
    setMethod(e.target.value);
  }

  function closeModal() {
    setModals({ ...modals, setPinModal: false });
  }

  function handleVerifyModalShow() {
    setModals({ ...modals, setPinModal: true });
  }

  function handleInputPinChange(otpInput) {
    setOtpInput(otpInput);
  }

  function sendCode() {
    const data = {
      method: method,
    };
    setLoadingTwo(true);
    return makeAPICall({
      path: "profile/activate2faInitiation",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setDetails(res);

        setLoadingTwo(false);
        message.success("Code sent, please check your email or phone", 5);
        handleVerifyModalShow();
      })
      .catch((err) => {
        message.error(err.message, 5);
        setLoadingTwo(false);
      });
  }

  function makeCall() {
    const data = {
      method: method,
      pinId: details?.data?.pinId,
      code: otpInput,
    };
    setLoadingState(true);
    return makeAPICall({
      path: "profile/activate2faVerification",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setLoadingState(false);
        closeModal();
        message.success("2FA Activated", 5);
      })
      .catch((err) => {
        message.error(err.message, 5);
        setLoadingState(false);
      });
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h1 className="main-settings" onClick={() => navigate("/settings")}>
            Settings &nbsp; <i className="fa fa-angle-right"></i> &nbsp; &nbsp;
            <span className="text-black-50">Security settings</span>
          </h1>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-7">
          <div className="profile-tab-settings">
            <div className="row">
              <div className="col-md-12 settings-tabs-settings">
                <h2>Passwords</h2>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <label className="label-auth">Current password</label>
                    <input
                      name="oldPassword"
                      value={profile.oldPassword}
                      placeholder="Current password"
                      onChange={onChangeInputValue}
                      type="password"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <label className="label-auth">New password</label>
                    <input
                      name="newPassword"
                      value={profile.newPassword}
                      placeholder="Enter new password"
                      onChange={onChangeInputValue}
                      type="password"
                    />
                    <br />
                    <br />
                    <label className="label-auth">Confirm new password</label>
                    <input
                      name="confirmNewPassword"
                      value={profile.confirmNewPassword}
                      placeholder="Confirm new password"
                      onChange={onChangeInputValue}
                      type="password"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <button
                      onClick={onSubmitForm}
                      disabled={
                        !(profile.oldPassword,
                        profile.newPassword,
                        profile.confirmNewPassword)
                      }
                    >
                      {loading ? (
                        <Loader dark={false} />
                      ) : (
                        "Create new password"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="profile-tab-settings">
            <div className="row">
              <div className="col-md-12 settings-tabs-settings">
                <h2>Please select your 2FA method to activate</h2>
              </div>
            </div>
            <hr />

            <br />
            <div className="row">
              <div className="col-md-6">
                <Radio.Group onChange={onChangeBank} value={method}>
                  <Space>
                    <Radio value="text">SMS</Radio>
                    <Radio value="email">Email</Radio>
                    <Radio value="apps">Apps </Radio>
                  </Space>
                </Radio.Group>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <button onClick={sendCode} disabled={!method}>
                  {loadingTwo ? <Loader dark={false} /> : "Activate"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PinModal
        show={modals.setPinModal}
        onClose={closeModal}
        handleInputPinChange={handleInputPinChange}
        user={user}
        isDisabled={!otpInput}
        onClick={makeCall}
        loading={loadingState}
        method={method}
        details={details}
      />
    </div>
  );
}
