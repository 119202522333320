import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 40,
    position: "relative",
  },
  header: {
    position: "absolute",
    top: 20,
    left: 20,
    width: 100,
    height: "auto",
  },
  section: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 20,
    marginTop: 60,
  },
  qrCode: {
    marginTop: 4,
    width: 250,
    height: 250,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
  },
  subHeading: {
    fontSize: 12,
    marginBottom: 4,
  },

  text: {
    fontSize: 10,
  },
  businessName: {
    fontWeight: "bold",
  },
  logo: {
    width: 50,
    position: "absolute",
    top: 24,
    left: 24,
  },

  brand: {
    color: "#5a50fe",
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    textAlign: "center",
    fontSize: 10,
    color: "#333",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    color: "#5a50fe",
    textDecoration: "underline",
    textAlign: "center",
    marginTop: 5,
  },
});

const TagPdf = ({ tag, businessName }) => {
  const [qrCodeData, setQrCodeData] = useState("");
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const generateQrCode = async () => {
      try {
        const qrCode = await QRCode.toDataURL(`https://eversend.me/${tag}`, {
          width: 300,
        });
        setQrCodeData(qrCode);
      } catch (error) {
        console.error("Error generating QR code", error);
      }
    };

    generateQrCode();
  }, [tag]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src="/eversend_receipt_logo.png" />

        <View style={styles.section}>
          <Text style={styles.title}>{`Pay ${businessName}`}</Text>
          <Text style={styles.subHeading}>
            Quickly and securely using{" "}
            <Text style={styles.brand}>Eversend</Text>
          </Text>

          <Text style={styles.text}>
            Scan the QR code below to proceed with your payment.
          </Text>
          <Image style={{ width: 250, height: 250 }} src={qrCodeData} />
        </View>
        <View style={styles.footer}>
          <Text>
            For assistance, contact{" "}
            <Link src="mailto:support@eversend.co" style={styles.link}>
              support@eversend.co
            </Link>
          </Text>

          <Text style={styles.link}>&copy; {currentYear} Eversend</Text>
        </View>
      </Page>
    </Document>
  );
};

export default TagPdf;
