import React from "react";
import { ReactComponent as Close } from "../../../../assets/images/Close.svg";

import styles from "./styles.module.scss";

const CloseIcon = () => {
  return <Close className={styles.icon} />;
};

export default CloseIcon;
