import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import StyledModal from "../modals/styled-modal";
import RequestTagForm from "./RequestTagForm";
import ViewTag from "./ViewTag";

import {
  createTag,
  selectProfileTagError,
  selectProfileTag,
  selectProfileIsCreatingTag,
  selectProfileBusinessName,
  fetchProfileTags,
} from "../../services/reducers/authReducers/profileReducer";

import styles from "./requestTagsModal.module.scss";

const RequestTagModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const businessTag = useSelector(selectProfileTag);
  const [viewTag, setViewTag] = useState(!!businessTag);
  const [tag, setTag] = useState(businessTag);

  const tagError = useSelector(selectProfileTagError);
  const isLoading = useSelector(selectProfileIsCreatingTag);
  const businessName = useSelector(selectProfileBusinessName);

  const handleCreateTag = () => {
    dispatch(createTag(tag))
      .unwrap()
      .then(() => {
        setViewTag(true);
        dispatch(fetchProfileTags());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (value) => setTag(value);

  useEffect(() => {
    setViewTag(!!businessTag);
  }, [businessTag]);

  return (
    <StyledModal show={isOpen} onClose={onClose}>
      <div className={styles.body}>
        <div className={styles.container}>
          {viewTag ? (
            <ViewTag tag={businessTag} businessName={businessName} />
          ) : (
            <RequestTagForm
              tag={tag}
              onChange={handleChange}
              onSubmit={handleCreateTag}
              error={tagError}
              isLoading={isLoading}
              isEditing={!!businessTag}
            />
          )}
        </div>
      </div>
    </StyledModal>
  );
};

RequestTagModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default RequestTagModal;
