import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import makeAPICall from "../../../utilities/apiUtils";
import { message } from "antd";
import { closeModal } from "../modal";

const initialState = {
  user: {},
  errorMessage: null,
  loading: false,
  creatingTag: false,
  tag: "",
  tagError: "",
};

export const fetchProfileAction = createAsyncThunk(
  "profile/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeAPICall({
        path: "profile",
        method: "GET",
      });
      return response.data.user;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchProfileTags = createAsyncThunk(
  "profile/fetchProfileTags",
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeAPICall({
        path: "profile/tag",
        method: "GET",
      });
      return response.data.tag;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const createTag = createAsyncThunk(
  "profile/createTag",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await makeAPICall({
        path: "profile/tag",
        method: "POST",
        payload: { tag: payload },
      });
      message.success("Business tag created successfully");
      dispatch(closeModal("tagModal"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchUser(state) {
      state.loading = true;
      state.errorMessage = null;
    },
    fetchUserSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
    },
    fetchUserError(state, action) {
      state.errorMessage = action.payload;
      state.loading = false;
    },
    updateProfile(state) {
      state.loading = true;
      state.errorMessage = null;
    },
    updateProfileSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
    },
    updateProfileError(state, action) {
      state.errorMessage = action.payload;
      state.loading = false;
    },
    changeApiSecret(state, action) {
      if (state.user.account) {
        state.user.account.clientSecret = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileAction.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(fetchProfileAction.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchProfileAction.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.loading = false;
      });
    builder
      .addCase(createTag.pending, (state) => {
        state.creatingTag = true;
        state.tagError = "";
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.tag = action.payload;
        state.creatingTag = false;
      })
      .addCase(createTag.rejected, (state, action) => {
        state.creatingTag = false;
        state.tagError = action.payload;
      })
      .addCase(fetchProfileTags.pending, (state,) => {
        state.loading = true; 
      })
      .addCase(fetchProfileTags.fulfilled, (state, action) => {
        state.tag = action.payload;
        state.tagError = "";
        state.loading = false;
      });
  },
});

export const {
  fetchUser,
  fetchUserSuccess,
  fetchUserError,
  updateProfile,
  updateProfileSuccess,
  updateProfileError,
  changeApiSecret,
} = profileSlice.actions;

export const selectProfileTagError = (state) => state?.profileReducer?.tagError;
export const selectProfileTag = (state) => state?.profileReducer?.tag;
export const selectProfileIsCreatingTag = (state) =>
  state?.profileReducer?.creatingTag;
export const selectProfileBusinessName = (state) =>
  state.twoFactorReducer?.user?.account?.name;
export const selectProfileIsLoading = (state) => state?.profileReducer?.loading;

export default profileSlice.reducer;
