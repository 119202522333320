import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import moment from "moment";

import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  fetchTransactionsAction,
  fetchCountryAction,
  toggleSendMoneyModalAction,
  toggleSendMoneyBeneficiaryModalAction,
} from "../../services/actions";
import EmptyState from "../../components/emptyState";
import EmptyStateIcon from "../../assets/images/emptystatepayout.svg";
import PayoutButton from "../../components/Buttons/PayoutButton";
import UserIcon from "../../assets/images/user.svg";
import GroupIcon from "../../assets/images/users.svg";

import gears from "../../assets/images/filter-gears.svg";
import Loader from "../../components/loaders/loading";
import accessIcon from "../../assets/images/noAccess.svg";
import TagManager from "react-gtm-module";
import { AccessControl } from "accesscontrol";
import {
  Table,
  Tag,
  Menu,
  Dropdown,
  Button,
  Space,
  Checkbox,
  Radio,
  DatePicker,
  Select,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

// function handleMenuClick(e) {}

// const menu = (
//   <Menu onClick={handleMenuClick}>
//     <Menu.Item key="1">Monthly Report</Menu.Item>
//     <Menu.Item key="2">Yearly Report</Menu.Item>
//   </Menu>
// );

const tagManagerArgsSend = {
  dataLayer: {
    event: "b2b_send_money_btn",
  },
};

export default function PayoutsContainer() {
  const [filters, setFilters] = useState({
    type: "payout",
  });
  const [dateValue, setDateValue] = useState("thirtydays");
  const [fromDateValueMoment, setFromDateValueMoment] = useState(
    moment().subtract(30, "days")
  );
  const [toDateValueMoment, setToDateValueMoment] = useState(moment());
  const navigate = useNavigate();
  //     const { roles } = useSelector((state) => state.RoleReducer);
  // console.log(roles?.permissions, "tetets");

  const location = useLocation();
  const { show, boot } = useIntercom();
  const { transactions, loading } = useSelector(
    (state) => state.transactionsReducer
  );
  const { user } = useSelector((state) => state.profileReducer);
  const { roles } = useSelector((state) => state.RoleReducer);
  const { wallets } = useSelector((state) => state.walletsReducer);
  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });
  const dispatch = useDispatch();
  const [hasKyc, setHasKyc] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isFirstTime, setIsFirstTime] = React.useState(true);

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  useEffect(() => {
    dispatch(
      fetchTransactionsAction({
        params: {
          type: "payout",
          limit: 10,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.id && isFirstTime) {
      TagManager.dataLayer(tagManagerArgsSend);
      dispatch(toggleSendMoneyBeneficiaryModalAction(location?.state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isFirstTime]);

  useEffect(() => {
    // dispatch(fetchProfileAction());
    // dispatch(fetchBanksAction({}));
    dispatch(fetchCountryAction({}));
  }, [dispatch]);

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      setHasKyc(true);
    }
  }, [user]);

  function handleOpenPayoutModal() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyModalAction());
  }

  function handleOpenPayoutModalBeneficiary() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyBeneficiaryModalAction());
  }

  function handleDatePickerChangeFrom(date) {
    setFromDateValueMoment(date);
    setFilters({
      ...filters,
      from: date,
    });
  }

  function handleDatePickerChangeTo(date) {
    setToDateValueMoment(date);
    setFilters({
      ...filters,
      to: date,
    });
  }

  function handleSelectStatus(selectedItem) {
    setFilters({
      ...filters,
      status: selectedItem,
    });
  }

  function handleSelectCurrency(selectedItem) {
    setFilters({
      ...filters,
      currency: selectedItem,
    });
  }

  function getData() {
    dispatch(
      fetchTransactionsAction({
        params: {
          ...filters,
          ...{
            limit: 10,
            page: 1,
          },
        },
      })
    );
  }

  function clearFilters() {
    setFilters({
      type: "payout",
    });
  }

  const changeDateValue = (e) => {
    setDateValue(e.target.value);
    if (e.target.value === "thirtydays") {
      setFromDateValueMoment(moment().subtract(30, "days"));
      setToDateValueMoment(moment());
    } else if (e.target.value === "today") {
      setFromDateValueMoment(moment().startOf("day"));
      setToDateValueMoment(moment().endOf("day"));
    } else if (e.target.value === "sevendays") {
      setFromDateValueMoment(moment().subtract(7, "days"));
      setToDateValueMoment(moment());
    } else {
      setFromDateValueMoment(moment());
      setToDateValueMoment(moment());
    }
  };

  const transactionColumns = [
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   width: "100px",
    //   render: (type) => {
    //     if (type === "bank") {
    //       return (
    //         <div>
    //           <img src={bankIcon} alt="bank" style={{ width: "13px" }} />
    //           &nbsp; {type}
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div>
    //           <img src={momo} alt="momo" style={{ width: "13px" }} />
    //           &nbsp; {type}
    //         </div>
    //       );
    //     }
    //   },
    // },

    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Receipent",
      dataIndex: ["beneficiary"],
      render: (beneficiary) =>
        `${beneficiary?.firstName} ${beneficiary?.lastName}`,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, row) => `${row.currency} ${Math.abs(amount)}`,
    },
    {
      title: "Date Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("MMM D, YYYY - h:mm A"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (status) => {
        if (status === "successful") {
          return <Tag color="green">{status}</Tag>;
        } else {
          return <Tag color="volcano">{status}</Tag>;
        }
      },
    },
  ];

  const plainOptions = [
    { label: "Successful", value: "successful" },
    { label: "Pending", value: "pending" },
    { label: "Failed", value: "failed" },
  ];
  const { Option } = Select;

  const filterMenu = (
    <Menu>
      <div className="playground-filter">
        <div className="pt-3 pb-1 px-3">
          <h1>Date</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Radio.Group value={dateValue} onChange={changeDateValue}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
            <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Range</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Space size={12}>
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChangeFrom}
              value={fromDateValueMoment}
            />
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChangeTo}
              value={toDateValueMoment}
            />
          </Space>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Status</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Checkbox.Group
            options={plainOptions}
            onChange={handleSelectStatus}
            value={filters.status}
          />
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Currency</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            style={{ width: "100%" }}
            placeholder="All Currency"
            onChange={handleSelectCurrency}
            value={filters.currency}
          >
            {wallets.map((option, index) => (
              <Option value={option.currency} key={index}>
                {option.currency}
              </Option>
            ))}
          </Select>
        </div>
        <div className="pt-3 pb-2 px-3 text-right">
          <Button className="me-2" type="default" onClick={clearFilters}>
            Clear
          </Button>
          <Button className="btn-primary" type="primary" onClick={getData}>
            Filter
          </Button>
        </div>
      </div>
    </Menu>
  );

  const getRow = (item) =>
    navigate(`/transaction/details/${item.transactionId}`);

  function handlePagination(item) {
    dispatch(
      fetchTransactionsAction({
        params: { ...filters, limit: item.pageSize, page: item.current },
      })
    );
  }

  return (
    <div>
      <h1 className="text-md color-bold text-bold mt-2">Payouts</h1>
      {hasKyc ? (
        <>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="col-33 pl-2">
                  {checkAccess("payouts").granted ? (
                    <div className="bg-light-grey p-4">
                      <h3 className="text-md color-bold text-bold">
                        Create a new payout
                      </h3>
                      <p className="mb-3">
                        Select from your saved beneficiaries or add a new
                        recipient.
                      </p>
                      <PayoutButton
                        title="Beneficiaries"
                        icon={GroupIcon}
                        handleClick={handleOpenPayoutModalBeneficiary}
                      />
                      <PayoutButton
                        title="Someone else"
                        icon={UserIcon}
                        handleClick={handleOpenPayoutModal}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="bg-white col-66">
                  <div className="col-md-12" style={{ position: "relative" }}>
                    {!transactions ||
                      (transactions.transactions?.length === 0 && (
                        <EmptyState
                          img={EmptyStateIcon}
                          title="Initiate a new payout"
                          description="You have not perfomed any transactions."
                          btnLabel="Single payout"
                          onClick={handleOpenPayoutModal}
                          btnProps={{ maxWidth: "236px" }}
                          // btnLabelTwo="Bulk payout"
                        />
                      ))}

                    <div className="transaction-filters">
                      <Space wrap>
                        <Dropdown
                          overlay={filterMenu}
                          placement="bottomRight"
                          trigger="click"
                        >
                          <Button>
                            <span>
                              <img src={gears} alt="" />
                            </span>
                            &nbsp; Filters <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Space>
                    </div>
                    {!!transactions.transactions?.length && (
                      <Table
                        // rowSelection={rowSelection}
                        showHeader={false}
                        title={() => <h2 className="h5">Payouts history</h2>}
                        columns={transactionColumns}
                        dataSource={transactions.transactions}
                        rowKey="transactionId"
                        pagination={{
                          pageSize: transactions.limit,
                          pageSizeOptions: [10, 20, 30],
                          showSizeChanger: true,
                          total: transactions.total,
                          position: ["topLeft"],
                          current: transactions.page,
                          showTitle: true,
                        }}
                        scroll={{ y: 500 }}
                        onChange={(pagination) => {
                          handlePagination(pagination);
                        }}
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              getRow(record);
                            },
                          };
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
