import React from "react";
import Banner from "../ui/Banner";
import styles from "./tagBanner.module.scss";
import PropTypes from "prop-types";

const TagBanner = ({ onClose, onClick }) => {
  return (
    <Banner
      handleClose={onClose}
      ctaText="Request Tag"
      showDismiss
      icon={<i className="bi bi-link" />}
      ctaAction={onClick}
    >
      <p className={styles.text}>
        <span className={styles.bold}>Get paid faster than ever before</span>
        —your personalized tag is just a request away.
        <a
          className={styles.link}
          href="https://help.eversend.co/en/articles/4349351-what-are-eversend-tags-payment-links-and-how-do-i-create-one"
        >
          Learn more about Tags.
        </a>
      </p>
    </Banner>
  );
};

TagBanner.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default TagBanner;
