import React from "react";
import styles from "./input.module.scss";
import CloseIcon from "../Icons/CloseIcon";
import PropTypes from "prop-types";
import { ReactComponent as Alert } from "../../../assets/images/alert.svg";
import Button from "../Button";

const Input = ({ label, name, error, value, className, onChange }) => {
  const hasError = !!error;
  const handleClear = () => {
    onChange("");
  };
  return (
    <div className={`${styles.container} ${className}`}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <div className={styles.inputcontainer}>
        <input
          className={`${styles.input} ${hasError ? styles.error : ""}`}
          type="text"
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        {value && (
          <div className={styles.icon}>
            <Button type="transparent" onClick={handleClear}>
              <CloseIcon />
            </Button>
          </div>
        )}
      </div>

      <span className={styles.errorMessage}>
        {error && <Alert />} {error}
      </span>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
export default Input;
