import React, { useState } from "react";
import StyledModal from "../styled-modal";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Nigeria from "./ngnFunding";
import NigeriaSecond from "./ngnAccount";
import ThirdMomo from "./thirdMomo";
// import Fourth from "./third";
import { useDispatch } from "../../../utilities/hooks";
import { toggleAddMoneyModalAction } from "../../../services/actions";
import CryptoMoneyModal from "./crypto/index";
import RequestMoneyModal from "./RequestMoney/index";
import TagManager from "react-gtm-module";
import StepsWrapper from "../../ui/StepsWrapper";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_completed_transaction",
    transaction_type: "add_money_bank",
  },
};

// const tagManagerArgsCrypto = {
//   dataLayer: {
//     event: "b2b_completed_transaction",
//     transaction_type: "add_money_crypto",
//   },
// };

export default function AddMoneyModal({
  show,
  onClose,
  currentWalletDefault = [],
  type,
}) {
  // console.log(type);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [amount, setAmount] = useState("");
  const [fundType, setFundType] = useState("");
  const [bankDetails, setBankDetails] = useState();
  const [currency, setCurrency] = useState("");
  const [reference, setReference] = useState("");
  const [momoCountry, setMomoCountry] = useState("");
  const [momoPhoneNumber, setMomoPhoneNumber] = useState();
  const [momoAmount, setMomoAmount] = useState();
  const [momoCurrency, setMomoCurrency] = useState("");

  const steps = [
    {
      title: "Funding details",
      content: (
        <First
          next={nextFirst}
          currentWalletDefault={currentWalletDefault}
          type={type}
        />
      ),
    },

    currency === "NGN"
      ? {
          title: "Choose bank",
          content: <Nigeria next={nextNigeria} prev={() => prev()} />,
        }
      : {
          title: "Make transfer",
          content: (
            <Second
              fundType={fundType}
              amount={amount}
              currency={currency}
              nextOthers={nextOthers}
              nextMomo={nextMomo}
              prev={() => prev()}
            />
          ),
        },
    currency === "NGN"
      ? {
          title: "Bank details",
          content: (
            <NigeriaSecond
              prev={() => prev()}
              bankDetails={bankDetails}
              closeModal={onClose}
            />
          ),
        }
      : fundType === "momo"
      ? {
          title: "Confirm Transaction",
          content: (
            <ThirdMomo
              amount={momoAmount}
              currency={momoCurrency}
              country={momoCountry}
              phoneNumber={momoPhoneNumber}
              closeModal={() => onCloseModal(0)}
              gotoHome={createNew}
              prev={() => prev()}
            />
          ),
        }
      : {
          title: "Notify Eversend",
          content: <Third closeModal={onClose} reference={reference} />,
        },
  ];

  // function next() {
  //   setCurrent(current + 1);
  // }

  function nextFirst(defaultWallet, inputValue, paymentMethod) {
    setCurrency(defaultWallet);
    setFundType(paymentMethod);
    setAmount(inputValue);
    setCurrent(current + 1);
  }

  function nextNigeria(banks) {
    // console.log(banks)
    setBankDetails(banks);
    setCurrent(current + 1);
  }

  function nextOthers(reference) {
    TagManager.dataLayer(tagManagerArgs);
    // console.log(reference)
    setReference(reference);
    setCurrent(current + 1);
  }

  function nextMomo(value, country, amount, currency) {
    //  console.log(value, country, amount, currency, 'yes')
    setMomoPhoneNumber(value);
    setMomoCountry(country);
    setMomoAmount(amount);
    setMomoCurrency(currency);
    setCurrent(current + 1);
  }

  function prev() {
    setCurrent(current - 1);
  }

  function onCloseModal(item) {
    setCurrent(item);
    onClose();
  }

  function createNew() {
    onClose();
    dispatch(toggleAddMoneyModalAction(currentWalletDefault));
    // history.go("/overview")
  }

  return (
    <div>
      {type === "eversend" ? (
        <RequestMoneyModal onClose={onClose} show={show} />
      ) : type === "crypto" ? (
        <CryptoMoneyModal
          onClose={onClose}
          show={show}
          defaultWallet={currentWalletDefault}
        />
      ) : (
        <StyledModal show={show} onClose={() => onCloseModal(0)}>
          <StepsWrapper
            currentStep={current}
            steps={steps}
            title="Fund Wallet"
            onGoBack={prev}
          >
            {steps[current].content}
          </StepsWrapper>
        </StyledModal>
      )}
    </div>
  );
}
