import React from "react";
import PropTypes from "prop-types";
import styles from "./button.module.scss";
import { clsx } from "clsx";
import Loader from "../Loader";

const Button = ({
  children,
  type = "primary",
  size = "xsmall",
  className = "",
  name,
  isLoading,
  onClick,
  disabled,
}) => {
  return (
    <button
      className={clsx(styles.button, styles[size], styles[type], className)}
      name={name}
      onClick={onClick}
      disabled={disabled}
    >
      <>
        {isLoading && <Loader size={size} />}
        {children}
      </>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.element,
  type: PropTypes.oneOf(["primary", "transparent", "link"]),
  size: PropTypes.oneOf(["xsmall", "small", "medium"]),
  className: PropTypes.string,
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
