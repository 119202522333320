import React, { useEffect } from "react";
import { Layout, Menu } from "antd";
import { ReactComponent as OverviewIcon } from "../../assets/images/sidebar-icons/overview.svg";
import { ReactComponent as PayoutsIcon } from "../../assets/images/sidebar-icons/payouts.svg";
import { ReactComponent as WalletsIcon } from "../../assets/images/sidebar-icons/wallets.svg";
import { ReactComponent as CardsIcon } from "../../assets/images/sidebar-icons/virtual_cards.svg";
import { ReactComponent as CryptoIcon } from "../../assets/images/cryptoBit.svg";
import { ReactComponent as TransactionIcon } from "../../assets/images/sidebar-icons/transactions.svg";
import { ReactComponent as BeneficiariesIcon } from "../../assets/images/sidebar-icons/beneficiaries.svg";
// import { ReactComponent as ReferralIcon } from "../../assets/images/referral-icon.svg";
import { ReactComponent as ApiIcon } from "../../assets/images/sidebar-icons/api.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/sidebar-icons/settings.svg";
// import { ReactComponent as DotIcon } from "../../assets/images/sidebar-icons/dot.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "../../utilities/hooks";
import _ from "lodash";

const { Sider } = Layout;

const { SubMenu } = Menu;

export default function BackBone({ accountChildren }) {
  const { wallets } = useSelector((state) => state.walletsReducer);
  const { user } = useSelector((state) => state.profileReducer);
  const enabledWallets = _.remove(
    _.concat([], wallets),
    ({ enabled }) => enabled
  );

  const isActive = enabledWallets?.some((item) => item?.isCrypto === true);

  const userRoleOwner = user?.role === "owner";
  // const [ openKeys, setOpenKeys ] = useState([]);

  const [selected, setSelected] = React.useState({
    isToggled: true,
    title: "Overview",
    bottom: false,
    selectedIndexTitle: "",
  });

  const location = useLocation();
  const { title, isToggled } = selected;
  let urlName = location.pathname.substring(1);

  const handleClick = (t, index) => {
    if (t === title) {
      return setSelected({
        ...selected,
        isToggled: !isToggled,
        selectedUrl: index,
      });
    }

    return setSelected({ ...selected, isToggled: true, title: t });
  };

  function capitalizeFLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    if (urlName) {
      setSelected({
        ...selected,
        selectedIndexTitle: capitalizeFLetter(urlName),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlName, title]);

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      className="sider bg-white side-nav"
    >
      {accountChildren}
      <Menu
        theme="light"
        mode="inline"
        className="menu m-top-10"
        selectedKeys={[`${selected.selectedIndexTitle}`]}
      >
        <Menu.Item
          key="Overview"
          icon={<OverviewIcon />}
          onClick={() => {
            handleClick("Overview", 0);
          }}
          className="e-menu-item"
        >
          <NavLink to="/overview">Overview</NavLink>
        </Menu.Item>

        <Menu.Item
          key="Payouts"
          icon={<PayoutsIcon />}
          onClick={() => {
            handleClick("Payouts", 1);
          }}
          className="e-menu-item"
        >
          <NavLink to="/payouts">Payouts</NavLink>
        </Menu.Item>
     
        <Menu.Item
          key="Wallets"
          icon={<WalletsIcon />}
          onClick={() => {
            handleClick("Wallets", 2);
          }}
          className="e-menu-item"
        >
          <NavLink to="/wallets">Wallets</NavLink>
        </Menu.Item>
        <Menu.Item
          key="Cards"
          icon={<CardsIcon />}
          onClick={() => {
            handleClick("Cards", 3);
          }}
          className="e-menu-item"
        >
          <NavLink to="/cards">Cards</NavLink>
        </Menu.Item>
        {isActive ? (
          <Menu.Item
            key="Crypto"
            icon={<CryptoIcon />}
            onClick={() => {
              handleClick("Crypto", 4);
            }}
            className="e-menu-item"
          >
            <NavLink to="/crypto">Cryptocurrency</NavLink>
          </Menu.Item>
        ) : (
          ""
        )}

        <Menu.Item
          key="Transactions"
          icon={<TransactionIcon />}
          onClick={() => {
            handleClick("Transactions", 5);
          }}
          className="e-menu-item"
        >
          <NavLink to="/transactions">Transactions</NavLink>
        </Menu.Item>
        <Menu.Item
          key="Beneficiaries"
          icon={<BeneficiariesIcon />}
          onClick={() => {
            handleClick("Beneficiaries", 6);
          }}
          className="e-menu-item"
        >
          <NavLink to="/beneficiaries">Beneficiaries</NavLink>
        </Menu.Item>
        <Menu.Item
          key="API doc"
          icon={<ApiIcon />}
          onClick={() => {
            handleClick("API doc", 7);
          }}
          className="e-menu-item e-submenu-item mt-10"
        >
          <a
            href="https://eversend.readme.io/"
            rel="noreferrer"
            target="_blank"
          >
            API doc
          </a>
        </Menu.Item>
        <SubMenu
          key="Settings"
          icon={<SettingsIcon />}
          title={<NavLink to="/settings">Settings</NavLink>}
        >
          <Menu.Item
            key="Business Settings"
            className="e-menu-item"
            onClick={() => {
              handleClick("Business Settings", 8);
            }}
          >
            <NavLink to="/settings">Business</NavLink>
          </Menu.Item>
          <Menu.Item
            key="General Settings"
            className="e-menu-item"
            onClick={() => {
              handleClick("General Settings", 9);
            }}
          >
            <NavLink to="/settings-general">Personal</NavLink>
          </Menu.Item>
        </SubMenu>
        {userRoleOwner && (
          <Menu.Item
            key="Logs"
            icon={<BeneficiariesIcon />}
            onClick={() => {
              handleClick("Logs", 10);
            }}
            className="e-menu-item"
          >
            <NavLink to="/logs">Activity logs</NavLink>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
}
