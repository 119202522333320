import makeAPICall from "../../../utilities/apiUtils";
import {
  login,
  loginError,
  loginSuccess,
  fetchProfile,
  fetchProfileError,
  fetchProfileSuccess,
} from "../../selectors";
import {
  AUTH_TOKEN,
  REDIRECT_URL,
  SESSION_HASH,
  USER_DATA,
  VERIFICATION_EMAIL,
  PASSWORD,
} from "../../../utilities/constants";
import { message } from "antd";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_account_logged_in",
  },
};

const loginAction =
  (data) =>
  (dispatch, _, { navigate }) => {
    dispatch(login());
    const { email, password } = data;
    return makeAPICall({
      path: "auth/login",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        if (res.data["2faEnabled"] === true) {
          const user = {
            sentTo: true,
          };
          dispatch(loginSuccess(user));
          const { method, sessionId } = res.data;
          window.sessionStorage.setItem(PASSWORD, password);
          window.sessionStorage.setItem(VERIFICATION_EMAIL, email);
          window.sessionStorage.setItem("METHOD", method);
          window.localStorage.setItem("SESSION_ID", sessionId);
          navigate("/auth");
        } else {
          const { user, token, session_hash } = res.data;
          dispatch(loginSuccess(user));
          window.localStorage.setItem(AUTH_TOKEN, token);
          window.localStorage.setItem(SESSION_HASH, session_hash);
          window.localStorage.setItem(USER_DATA, JSON.stringify(user));
          TagManager.dataLayer(tagManagerArgs);
          dispatch(fetchProfile());
          return makeAPICall({
            path: "profile",
            method: "GET",
          })
            .then((res) => {
              const { user } = res.data;
              dispatch(fetchProfileSuccess(user));
              const redirectUrl =
                window.sessionStorage.getItem(REDIRECT_URL) ?? "/";
              navigate(redirectUrl);
            })
            .catch((err) => {
              dispatch(fetchProfileError(err.message));
            });
        }
      })
      .catch((err) => {
        if (err.message === "Please Reset your password") {
          navigate("/forgot-password");
        } else {
          message.error(err.message, 5);
        }
        dispatch(loginError(err.message));
      });
  };

export { loginAction };
