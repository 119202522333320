import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, InputNumber, Row, Col, Spin } from "antd";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import {
  createExchangeQuotations,
  resetQuotationParams,
  fetchWalletsAction,
} from "../../../services/actions";
import { ReactComponent as FadingElipses } from "../../../assets/images/fading-elipse.svg";
import exchangeLogo from "../../../assets/images/exch.svg";
import SummaryPage from "./summary";
import SuccessPage from "./success";
import {
  formatMoney,
  formatBalanceCrypto,
} from "../../../utilities/helperFunctions";
import StyledModal from "../styled-modal";
import Countdown from "react-countdown";
import { resetQuotation } from "../../../services/selectors";

export default function ExchangeModal({ show, onClose, exchangeCurrency }) {
  const { wallets } = useSelector((state) => state.walletsReducer);

  const {
    quotation,
    loading: loadingQuotation,
    submitQuotations,
  } = useSelector((state) => state.quotationsReducer);

  const dispatch = useDispatch();
  const [allWallets, setAllWallets] = useState(wallets);
  const [allReceivingWallets, setAllReceivingWallets] = useState(wallets);

  const [isShown, setIsShown] = useState(false);
  const [usedNumber, setUsedNumber] = useState(30000);
  const [numberChange, setNumberChange] = useState({
    startTime: 0,
    endTime: 0,
  });
  const [payLoad, setPayLoad] = useState({});

  const [exchangeFields, setExchangeFields] = useState({
    from: exchangeCurrency,
    amount: 100,
  });

  const [exchangeError, setExchangeError] = useState("");

  const [toAmount, setToAmount] = useState(
    wallets[1]?.currency ? wallets[1].currency : "USD"
  );
  const [confirmPage, setConfirmPage] = useState(false);

  const { Option } = Select;

  const walletCopy = JSON.parse(JSON.stringify(wallets));

  const checkForCrypto = (value) => {
    if (value === null || value === undefined) {
      return { isCrypto: false };
    }
    return walletCopy.find((wallet) => wallet.currency === value);
  };

  function onValueChange(value, name) {
    setExchangeFields({
      ...exchangeFields,
      [name]: value,
    });
    if (name === "from") {
      handleChangeInput(value);
      const newWallet = JSON.parse(JSON.stringify(wallets));
      const filtered = newWallet.find((wallet) => wallet.currency === value);
      //filter wallet to check for currency of the same type and removed same currency so receiving currency would not be part of sending currency
      const filteredWallet = newWallet.filter((item) => {
        if (filtered.isCrypto === true) {
          return item.currency !== value && !item.isCrypto;
        } else {
          return item.enabled && item.currency !== value;
        }
      });
      setAllReceivingWallets(filteredWallet);
      if (value === toAmount) {
        setToAmount(filteredWallet[1]?.currency);
      }
    }
  }

  const handleChange = (e) => {
    setExchangeError("");
    setToAmount(e);
    const newWallet = JSON.parse(JSON.stringify(wallets));
    const filtered = newWallet.find((wallet) => wallet.currency === e);
    const filteredWallet = newWallet.filter((item) => {
      if (filtered.isCrypto) {
        return item.currency !== e && !item.isCrypto;
      } else {
        return item.enabled && item.currency !== e;
      }
    });
    setAllWallets(filteredWallet);
    if (e === exchangeFields.from) {
      setExchangeFields({ from: filteredWallet[1]?.currency });
    }
    const { from, amount } = exchangeFields;

    if (checkForCrypto(from).isCrypto) {
      setPayLoad({ from, amount, to: e });
      dispatch(createExchangeQuotations({ from, amount, to: e }));
    } else {
      if (amount < 1) return setExchangeError("Minimum amount is 1");
      // if (amount % 1 !== 0)
      //   return setExchangeError("Please decimal number is not allowed");
      setPayLoad({ from, amount, to: e });
      dispatch(createExchangeQuotations({ from, amount, to: e }));
    }
  };

  const handleChangeInput = (e) => {
    setExchangeError("");
    const from = e;
    const { amount } = exchangeFields;
    if (checkForCrypto(from).isCrypto) {
      setPayLoad({ from, amount, to: toAmount });
      dispatch(createExchangeQuotations({ from, amount, to: toAmount }));
    } else {
      if (amount < 1) return setExchangeError("Minimum amount is 1");
      // if (amount % 1 !== 0)
      //   return setExchangeError("Please decimal number is not allowed");
      setPayLoad({ from, amount, to: toAmount });
      dispatch(createExchangeQuotations({ from, amount, to: toAmount }));
    }
  };

  const handleChanges = () => {
    setExchangeError("");
    const { from, amount } = exchangeFields;
    if (checkForCrypto(from).isCrypto) {
      setPayLoad({ from, amount, to: toAmount });
      dispatch(createExchangeQuotations({ from, amount, to: toAmount }));
    } else {
      if (amount < 1) return setExchangeError("Minimum amount is 1");
      // if (amount % 1 !== 0)
      //   return setExchangeError("Please decimal number is not allowed");
      setPayLoad({ from, amount, to: toAmount });
      dispatch(createExchangeQuotations({ from, amount, to: toAmount }));
    }
  };

  const next = () => {
    if (checkForCrypto(exchangeFields.from).isCrypto) {
      setConfirmPage(true);
    } else {
      if (exchangeFields.amount < 1) {
        // dispatch(resetQuotationParams());
        return setExchangeError("Minimum amount is 1");
      }

      setConfirmPage(true);
    }
    // if (exchangeFields.amount < 1) {
    //   dispatch(resetQuotationParams());
    //   return setExchangeError("Minimum amount is 1");
    // }
    // setExchangeError("");
    // setConfirmPage(true);
  };

  const back = () => {
    setConfirmPage(false);
    dispatch(resetQuotation());
    setUsedNumber(numberChange.endTime - Date.now());
  };

  const handleClose = () => {
    setConfirmPage(false);
    dispatch(resetQuotationParams());
    onClose();
    // setTimeout(() => {
    //   dispatch(resetQuotationParams());
    // }, 1000);
  };

  useEffect(() => {
    // dispatch(fetchWalletsAction());
    // handleChange(wallets[1]?.currency ? wallets[1].currency : "USD");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, wallets]);

  useEffect(() => {
    setUsedNumber(30000);
    setNumberChange({
      startTime: Date.now(),
      endTime: Date.now() + 30000,
    });
    setIsShown(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotation?.token]);

  useEffect(() => {
    if (submitQuotations) {
      dispatch(fetchWalletsAction());
    }
  }, [dispatch, submitQuotations]);

  const renderer = ({ seconds }) => <span>Exchange ({seconds})</span>;

  const handleComplete = (e) => {
    setIsShown(false);
    dispatch(createExchangeQuotations(payLoad));
  };

  return (
    <StyledModal show={show} onClose={handleClose}>
      <div>
        <div className="container">
          <div className="col-sm-5 offset-0 offset-md-3 p-3 p-sm-0">
            {submitQuotations == null ? (
              <>
                {!confirmPage ? (
                  <div className="exchange-select">
                    <div className="mt-4 mb-1">
                      <h3 className="text-bold color-bold text-md">Exchange</h3>
                    </div>
                    <Form>
                      <div className="pt-4">
                        <p className="">You are exchanging</p>
                        {exchangeError !== "" ? (
                          <div className="alert-danger p-2 mb-4 alert-dismissible">
                            <p className="text-center">{exchangeError}</p>
                          </div>
                        ) : null}
                      </div>
                      {/* <CustomWalletSelect
                        currency={exchangeFields.from}
                        wallets={allWallets}
                        onChange={(value) => onValueChange(value, "amount")}
                        handleChange={(value) => onValueChange(value, "from")}
                        amount={exchangeFields.amount}
                        checkForCrypto={checkForCrypto}
                        quotation={quotation}
                        handleChanges={handleChanges}
                      /> */}
                      <Input.Group compact style={{ width: "100%" }}>
                        <Select
                          style={{ width: "30%" }}
                          name="from"
                          onChange={(value) => onValueChange(value, "from")}
                          // disabled={loadingWallets}
                          value={exchangeFields.from}
                        >
                          {allWallets
                            .filter((data) => data.enabled)
                            .map((wallet) => {
                              return (
                                <Option
                                  key={wallet.currency}
                                  value={wallet.currency}
                                >
                                  <img src={wallet.icon} alt="flag" />
                                  {wallet.currency}
                                </Option>
                              );
                            })}
                        </Select>
                        <InputNumber
                          style={{ width: "70%" }}
                          defaultValue="100.00"
                          min="0"
                          precision="8"
                          name="amount"
                          onChange={(value) => onValueChange(value, "amount")}
                          onBlur={handleChanges}
                        />
                      </Input.Group>
                      <br />
                      {quotation?.baseCurrency ? (
                        <p className=" text-black-50">
                          {exchangeFields.from} balance:&nbsp;
                          {checkForCrypto(exchangeFields.from).isCrypto
                            ? formatBalanceCrypto(quotation.baseWalletBefore)
                            : formatMoney(
                                quotation.baseWalletBefore.toFixed(2)
                              )}
                          {/* {formatMoney(quotation.baseWalletBefore.toFixed(2))} */}
                        </p>
                      ) : null}

                      <hr />

                      <Row type="flex" align="middle" className="mt-4">
                        <Col style={{ marginRight: "40px" }}>
                          {/* <RotateIcon width="48px" /> */}
                          <img src={exchangeLogo} alt="" />
                        </Col>
                        <Col style={{ marginRight: "20px" }}>
                          <FadingElipses />
                        </Col>
                        <Col>
                          <div>
                            <span>Exchange Rate </span>
                          </div>
                          <div className="mt-2">
                            {quotation?.baseCurrency ? (
                              <h6>
                                <b>
                                  {quotation.baseCurrency}&nbsp;1 &nbsp;&nbsp;
                                  =&nbsp;&nbsp; {quotation.rate}&nbsp;
                                  {quotation.destCurrency}
                                </b>
                              </h6>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div className="pt-4 flex justify-content-between">
                        <p className="">You will get</p>
                      </div>

                      {loadingQuotation ? (
                        <Spin tip="Loading...">
                          <Input.Group compact style={{ width: "100%" }}>
                            <Select
                              style={{ width: "30%" }}
                              name="toAmount"
                              value={toAmount}
                              onChange={handleChange}
                            >
                              {allReceivingWallets
                                .filter(
                                  (data) =>
                                    data.enabled &&
                                    data.currency !== exchangeFields.from
                                )
                                .map((wallet) => {
                                  return (
                                    <Option
                                      key={wallet.currency}
                                      value={wallet.currency}
                                    >
                                      <img src={wallet.icon} alt="flag" />
                                      {wallet.currency}
                                    </Option>
                                  );
                                })}
                            </Select>

                            <InputNumber
                              style={{ width: "70%" }}
                              disabled
                              min="1"
                              value={
                                quotation === null ? 0 : quotation.destAmount
                              }
                            />
                          </Input.Group>

                          <br />
                          {quotation?.destCurrency ? (
                            <p className=" text-black-50">
                              {quotation.destCurrency} balance:&nbsp;
                              {formatMoney(
                                quotation.destWalletBefore.toFixed(2)
                              )}
                            </p>
                          ) : null}
                        </Spin>
                      ) : (
                        <>
                          <Input.Group compact>
                            <Select
                              style={{ width: "30%" }}
                              name="toAmount"
                              value={toAmount}
                              onChange={handleChange}
                            >
                              {allReceivingWallets
                                .filter(
                                  (data) =>
                                    data.enabled &&
                                    data.currency !== exchangeFields.from
                                )
                                .map((wallet) => {
                                  return (
                                    <Option
                                      key={wallet.currency}
                                      value={wallet.currency}
                                    >
                                      <img src={wallet.icon} alt="flag" />
                                      {wallet.currency}
                                    </Option>
                                  );
                                })}
                            </Select>

                            <InputNumber
                              value={
                                quotation === null ? 0 : quotation.destAmount
                              }
                              style={{ width: "70%" }}
                              disabled
                            />
                          </Input.Group>
                          <br />
                          {quotation?.destCurrency ? (
                            <p className=" text-black-50">
                              {quotation.destCurrency} balance: &nbsp;
                              {checkForCrypto(quotation.destCurrency).isCrypto
                                ? formatBalanceCrypto(
                                    quotation.destWalletBefore
                                  )
                                : formatMoney(
                                    quotation.destWalletBefore.toFixed(2)
                                  )}
                            </p>
                          ) : null}
                        </>
                      )}

                      <Form.Item className="mt-2">
                        <Button
                          type="default"
                          size="large"
                          className="pay-blue-btn text-bold w-100"
                          disabled={
                            !quotation || loadingQuotation || !quotation.token
                          }
                          onClick={next}
                        >
                          {!quotation ||
                          loadingQuotation ||
                          !quotation.token ||
                          !isShown ? (
                            "Exchange"
                          ) : (
                            <Countdown
                              date={Date.now() + usedNumber}
                              renderer={renderer}
                              onComplete={handleComplete}
                            />
                          )}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                ) : (
                  <SummaryPage
                    cryptoCheck={checkForCrypto}
                    onBackPress={back}
                    difference={numberChange}
                    isShown={isShown}
                    onIsShown={setIsShown}
                    onComplete={handleComplete}
                  />
                )}
              </>
            ) : (
              <SuccessPage
                onClose={handleClose}
                cryptoCheck={checkForCrypto}
                onBackPress={back}
              />
            )}
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
