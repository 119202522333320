import React, { useState } from "react";
import { message, Button, Card, List, Space, Table, Tag } from "antd";
import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import makeAPICall from "../../../utilities/apiUtils";
import AddIPAddress from "../../../components/modals/settings/AddIPAddress";
import DeleteIPAddressModal from "../../../components/modals/settings/DeleteIpAddress";
import IPAddressModal from "../../../components/modals/settings/IPAddressModal";
import { deleteIPAddressAction } from "../../../services/actions";
import imageIcon from "../../../assets/images/ip_icon.svg";
import warningIcon from "../../../assets/images/warning-alert.svg";

import { changeSecretAction } from "../../../services/actions";
import WebHookModal from "../../../components/modals/settings/Webhook";
import { fetchProfileAction } from "../../../services/reducers/authReducers/profileReducer";

export default function APICredentialInfo({
  handleCopy,
  onProfile,
  secret,
  setSecret,
}) {
  const dispatch = useDispatch();
  const { user: accountUser } = useSelector((state) => state.profileReducer);
  const { ip_address: addresses, loading } = useSelector(
    (state) => state.ipAddressReducer
  );
  const [item, setItem] = useState({});
  const [addIPAddressModal, setAddIPAddressModal] = useState(false);
  const [changeIPAddressModal, setChangeIPAddressModal] = useState(false);
  const [showDeleteModal, setShowAddIPAddressModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [inputValues, setInputValues] = useState("");
  const [showSecret, setShowSecret] = useState({ auth: false, webhook: false });
  const mask = "****************************************************";

  const key = "changeSecret";

  const checkAccess = () => {
    return accountUser.account.allowAllAccess;
  };

  const handleOpenWebHookModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleCallProfile = () => {
    onProfile();
  };

  function handleGenerateSecret() {
    message.loading({ content: "Loading...", key });
    return makeAPICall({
      path: "account/rotateAPISec",
      method: "POST",
    })
      .then((res) => {
        message.success({
          content: "New Api Secret Generated",
          key,
          duration: 4,
        });

        dispatch(changeSecretAction(res.data.clientSecret));
      })
      .catch((err) => {
        message.error({ content: err.message, key, duration: 4 });
      });
  }

  function handleOpenModal() {
    setAddIPAddressModal(true);
  }

  function handleChangeModal() {
    setChangeIPAddressModal(true);
  }

  function handleCloseModalTwo() {
    setAddIPAddressModal(false);
  }

  function handleChangeModalClose() {
    setChangeIPAddressModal(false);
  }

  function handleDeleteOpenModal() {
    setShowAddIPAddressModal(true);
  }

  function handleDeleteCloseModal() {
    setShowAddIPAddressModal(false);
  }

  function onHandleDelete(item) {
    setItem(item);
    handleDeleteOpenModal();
  }

  function onhandleOk() {
    dispatch(deleteIPAddressAction(item.id));
  }

  //table header details

  const columns = [
    {
      title: "Location",
      dataIndex: "label",
      cell: (row) => {
        if (row.label) {
          return <div>{row.label}</div>;
        }
      },
    },
    {
      title: "IP Address",
      dataIndex: "address",
      cell: (row) => {
        if (row.address) {
          return <div>{row.address}</div>;
        }
      },
    },

    {
      title: "",
      dataIndex: "action",
      align: "right",
      render: (action, row) => {
        return (
          <Button
            onClick={() => {
              onHandleDelete(row);
            }}
            type="ghost"
          >
            <i className="fa fa-trash" />
          </Button>
        );
      },
    },
  ];

  const handleChange = (e) => {
    setInputValues(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValues === "all") {
      message.error("This isn't currently available", 4);
    } else {
      message.loading({ content: "Loading...", key });
      return makeAPICall({
        path: "account/deactivateAllAccess",
        method: "PATCH",
      })
        .then((res) => {
          dispatch(fetchProfileAction());
          setAddIPAddressModal(true);
        })
        .catch((err) => {
          message.error({ content: err.message, key, duration: 4 });
        });
    }
  };

  return (
    <>
      <br />
      <div className="row">
        <div className="col-md-8">
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Card
              title="API Credentials"
              extra={
                <Button type="ghost" onClick={handleGenerateSecret}>
                  <i className="fa fa-refresh"></i>&nbsp; Change Secret
                </Button>
              }
            >
              <List>
                <List.Item>
                  <List.Item.Meta
                    title="Client ID"
                    description={accountUser?.account?.clientId}
                  />
                  <div>
                    <CopyOutlined onClick={() => handleCopy("id")} />
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title="Client Secret"
                    description={
                      showSecret.auth
                        ? accountUser?.account?.clientSecret
                        : mask
                    }
                  />
                  <div>
                    <Space>
                      {showSecret.auth ? (
                        <EyeOutlined
                          onClick={() =>
                            setShowSecret({ ...showSecret, auth: false })
                          }
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() =>
                            setShowSecret({ ...showSecret, auth: true })
                          }
                        />
                      )}
                      <CopyOutlined onClick={() => handleCopy("secret")} />
                    </Space>
                  </div>
                </List.Item>
              </List>
            </Card>
            <Card
              title="Webhook"
              extra={
                <Button type="ghost" onClick={handleOpenWebHookModal}>
                  <i className="fa fa-refresh"></i>&nbsp; Change URL
                </Button>
              }
            >
              <List>
                <List.Item>
                  <List.Item.Meta
                    title="Endpoint"
                    description={accountUser.account.webhook}
                  />
                  <div>
                    <CopyOutlined onClick={() => handleCopy("webhook")} />
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title="Secret"
                    description={
                      showSecret.webhook
                        ? accountUser?.account?.webhookSecret
                        : mask
                    }
                  />
                  <div>
                    <Space>
                      {showSecret.webhook ? (
                        <EyeOutlined
                          onClick={() =>
                            setShowSecret({ ...showSecret, webhook: false })
                          }
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() =>
                            setShowSecret({ ...showSecret, webhook: true })
                          }
                        />
                      )}
                      <CopyOutlined
                        onClick={() => handleCopy("web_hook_secret")}
                      />
                    </Space>
                  </div>
                </List.Item>
              </List>
            </Card>
            <Card
              title="IP Whitelist"
              extra={
                checkAccess() ? null : addresses.length === 0 ? null : (
                  <span className="d-flex">
                    <Button
                      type="ghost"
                      onClick={handleChangeModal}
                      className="me-2"
                    >
                      Change access
                    </Button>
                    <button
                      onClick={handleOpenModal}
                      className="ev-primary-btn"
                    >
                      Add Address
                    </button>
                  </span>
                )
              }
            >
              {checkAccess() ? (
                <>
                  <div className="add-ip-address">
                    <div className="text-center mt-3 mb-4">
                      <div className="d-flex justify-content-center align-items-center tag-style">
                        <h1 className="me-2">Allow access from anywhere</h1>
                        <Tag color="green">Active</Tag>
                      </div>
                    </div>
                    <div className="d-flex align-items-start warning-alert">
                      <img
                        src={warningIcon}
                        alt="warning icon"
                        className="warning-icon me-2"
                      />
                      <h2>
                        This means all apps and websites on the internet can
                        access your Eversend data. <br />
                        Here's your current IP address: <span>0.000.00.00</span>
                      </h2>
                    </div>
                    <br />
                    <div className="text-center">
                      <button
                        onClick={handleChangeModal}
                        className="ev-primary-btn"
                      >
                        Restrict access
                      </button>
                    </div>
                  </div>
                </>
              ) : addresses.length === 0 ? (
                <div className="add-ip-address">
                  <div className="text-center">
                    <img src={imageIcon} alt="notification icon" />
                    <h1 className="mt-3">Connect Your Apps Securely</h1>
                    <p className="mt-3 mb-4">
                      Want to connect Eversend with other apps / websites you
                      use? API whitelisting helps you do this securely.
                    </p>
                  </div>
                  <div className="custom-radio-group-general">
                    {/* <label className="custom-radio-general mb-3">
                      <input
                        type="radio"
                        id="radio-input-1"
                        name="radio-group"
                        value="all"
                        onChange={handleChange}
                        checked={inputValues === "all"}
                      />
                      <span className="checkmark"></span>
                      <span className="biz-title">
                        Allow access from anywhere
                      </span>
                      <br />
                      <span className="biz-desc">
                        This is a high risk option that grants access to all
                        apps and websites on the internet. Use only if you
                        understand the security risks
                      </span>
                    </label> */}
                    <label className="custom-radio-general mb-1">
                      <input
                        type="radio"
                        id="radio-input-2"
                        name="radio-group"
                        value="single"
                        onChange={handleChange}
                        checked={inputValues === "single"}
                      />
                      <span className="checkmark"></span>
                      <span className="biz-title">
                        Allow access from specific IP addresses
                      </span>
                      <br />
                      <span className="biz-desc">
                        This is a low risk option that grants access to only
                        apps and websites you know to access your Eversend data.
                      </span>
                    </label>
                    <Tag color="green">Recommended</Tag>
                  </div>
                  <div className="text-right">
                    <button
                      className="ev-primary-btn"
                      onClick={handleSubmit}
                      disabled={!inputValues || loading}
                    >
                      Allow access
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <Table
                    columns={columns}
                    dataSource={addresses}
                    showHeader={false}
                    pagination={false}
                  />
                </>
              )}

              {addIPAddressModal && (
                <AddIPAddress
                  show={addIPAddressModal}
                  onClose={handleCloseModalTwo}
                />
              )}
              {changeIPAddressModal && (
                <IPAddressModal
                  show={changeIPAddressModal}
                  onClose={handleChangeModalClose}
                  checkAccess={checkAccess}
                  handleOpenModal={handleOpenModal}
                />
              )}

              {modal && (
                <WebHookModal
                  show={modal}
                  onClose={handleCloseModal}
                  onOk={handleCloseModal}
                  onCallProfile={handleCallProfile}
                />
              )}
              {showDeleteModal && (
                <DeleteIPAddressModal
                  show={showDeleteModal}
                  onClose={handleDeleteCloseModal}
                  item={item}
                  onOk={onhandleOk}
                />
              )}
            </Card>
          </Space>
        </div>
      </div>
    </>
  );
}
