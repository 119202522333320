import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const Pill = ({ text }) => {
  return <div className={styles.pill}>{text}</div>;
};

Pill.propTypes = {
  text: PropTypes.string.isRequired,
};
export default Pill;
