import React, { useState } from "react";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import { verifyPasswordResetAction } from "../../../services/actions";
import { useNavigate } from "react-router-dom";
import back from "../../../assets/images/back-button.svg";
import PinInput from "react-pin-input";
import Loader from "../../../components/Loader";
//import { AuthError } from "../../../components/Errors/AuthError";

export default function VerifyCodeView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, email } = useSelector(
    (state) => state.forgotPasswordReducer
  );
  const [code, setCode] = useState("");

  function handleInputPinChange(otpInput) {
    setCode(otpInput);
  }

  function handleSubmit() {
    dispatch(
      verifyPasswordResetAction({
        email,
        code,
      })
    );
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <div className="form-group">
        <label htmlFor="email" className="label-form">
          Email Pin
        </label>
        <PinInput
          secret
          focus
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
          inputStyle={{
            border: "1px solid #CBCAE5",
            borderRadius: "8px",
            color: "#949494",
          }}
          length={6}
          type="numeric"
          onComplete={handleInputPinChange}
        />
      </div>
      <br />
      <button
        type="submit"
        disabled={!code}
        // disabled={email === "frank@eversend.co" ? false : true}
        onClick={handleSubmit}
        className="submit-btn"
      >
        {loading ? (
          <span className="d-flex align-items-center justify-content-between">
            <Loader dark={false} />
          </span>
        ) : (
          "Verify"
        )}
      </button>
      <br />
      <br />
      <div className="text-center">
        <button className="external-link" onClick={() => navigate("/login")}>
          <img src={back} alt="back to login" style={{ marginRight: "10px" }} />
          Back to sign in
        </button>
      </div>
    </div>
  );
}
