import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../../assets/images/Eversend conf.4 for white bcgrnd.svg";
import { ReactComponent as Envelope } from "../../../assets/images/email-verified.svg";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

export default function VerificationSuccess() {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState(false);

  const handleReload = useCallback(() => {
    setLoadingState(true);
    setTimeout(() => {
      setLoadingState(false);
      navigate("/login");
    }, 3000);
  }, [navigate]);

  useEffect(() => {
    handleReload();
  }, [handleReload]);

  return (
    <>
      <div className="bg-light-grey signup-preview">
        {loadingState && (
          <div className="bg__overlay--verify">
            <Spin size="large" />
          </div>
        )}
        <div className="auth-login-wrap signup-wrap">
          <div className="full-width">
            <div className="logo mb-5">
              <Logo />
            </div>
            <div className="auth__card--wrap">
              <div className="mb-4">
                <div className="section__icon--md mb-3">
                  <Envelope />
                </div>
                <p className="section__text--md-2 text-bold text-center">
                  Email verified
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
