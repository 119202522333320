import React, { Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isLogin } from "../utilities/authUtils";

const PrivateRouteCheck = ({ component: Component, title, ...rest }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Eversend | ${title || "Dashboard"}`;
  }, [title]);

  const loggedIn = isLogin();

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [loggedIn, navigate]);

  return (
    <Suspense fallback="">
      <Component {...rest} />
    </Suspense>
  );
};

export default PrivateRouteCheck;
