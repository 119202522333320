import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import DataTable from "react-data-table-component";

import add from "../../assets/images/add-btn.svg";
import send from "../../assets/images/send.svg";
import received from "../../assets/images/received.svg";
import payout from "../../assets/images/payout.svg";
import exchangeTable from "../../assets/images/exchange-table.svg";
import exchange from "../../assets/images/exchange.svg";
import copy from "../../assets/images/file-copy.svg";
import bankIcon from "../../assets/images/radio-buttons/bankDropdown.svg";
import mobileMoneyIcon from "../../assets/images/radio-buttons/mobileMoneyDropdown.svg";
import requestIcon from "../../assets/images/radio-buttons/requestMoney.svg";
import {
  formatBalance,
  excludedCurrencies,
  addMoneyCountries,
} from "../../utilities/helperFunctions";

import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  fetchTransactionsAction,
  toggleExchangeModalAction,
  toggleAddMoneyModalAction,
  toggleSendMoneyModalAction,
} from "../../services/actions";
import Loader from "../../components/loaders/loading";
import makeAPICall from "../../utilities/apiUtils";
import { message, Dropdown, Menu, Skeleton } from "antd";
import TagManager from "react-gtm-module";
import { AccessControl } from "accesscontrol";
import * as ctzc from "iso-country-currency";

const tagManagerArgsAdd = {
  dataLayer: {
    event: "b2b_add_money_btn",
  },
};

const tagManagerArgsSend = {
  dataLayer: {
    event: "b2b_send_money_btn",
  },
};

const tagManagerArgsExchange = {
  dataLayer: {
    event: "b2b_exchange_btn",
  },
};

export default function SingleWallet() {
  const { name } = useParams();
  const navigate = useNavigate();
  const { transactions, loading } = useSelector(
    (state) => state.transactionsReducer
  );
  const { wallets } = useSelector((state) => state.walletsReducer);
  const { user: userInfo } = useSelector((state) => state.profileReducer);
  const [currentWallet, setCurrentWallet] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);
  const { roles } = useSelector((state) => state.RoleReducer);
  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(userInfo?.role).createAny(value);
    return permission;
  }

  function handleOpenExchangeModal() {
    // console.log(name)
    TagManager.dataLayer(tagManagerArgsExchange);
    dispatch(toggleExchangeModalAction(name));
  }

  function handleOpenAddMoneyModal(item) {
    // TagManager.dataLayer(tagManagerArgsAdd);
    // dispatch(toggleAddMoneyModalAction(currentWallet));
    if (disableButtons) {
      return false;
    } else {
      TagManager.dataLayer(tagManagerArgsAdd);
      dispatch(toggleAddMoneyModalAction(currentWallet, item));
    }
  }

  const disableButtons =
    !currentWallet || currentWallet.length === 0 || isLoading;

  function handleOpenPayoutModal() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyModalAction());
  }

  useEffect(() => {
    setLoadingState(true);
    Promise.all([
      dispatch(fetchTransactionsAction({ params: { currency: name } })),
      makeAPICall({
        path: `wallets/${name}`,
        method: "GET",
      }).then((res) => {
        setLoadingState(false);
        setCurrentWallet(res.data);
      }),
    ])
    .catch((err) => {
      setLoadingState(false);
      console.log(err);
    });
  }, [dispatch, name]);

  const columns = [
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: false,
      cell: (row) => {
        if (row.type === "payout") {
          return (
            <div>
              <img src={payout} alt="received" /> {row.type}
            </div>
          );
        } else if (row.type === "exchange") {
          return (
            <div>
              <img src={exchangeTable} alt="received" /> {row.type}
            </div>
          );
        } else {
          return (
            <div>
              <img src={received} alt="received" /> {row.type}
            </div>
          );
        }
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: false,
      cell: (row) => {
        if (row.status === "successful") {
          return (
            <div
              style={{
                background: "#DBEFDC",
                borderRadius: "4px",
                border: "1px solid #4CAF50",
                padding: "2px 8px",
                color: "#193A1B",
              }}
            >
              {row.status}
            </div>
          );
        } else {
          return (
            <div
              style={{
                background: "#CDCDF9",
                borderRadius: "4px",
                border: "1px solid #0000BB",
                padding: "2px 8px",
                color: "#0000E0",
              }}
            >
              {row.status}
            </div>
          );
        }
      },
    },
    {
      name: "Transaction id",
      selector: (row) => row.transactionId,
      sortable: false,
      cell: (row) => {
        if (row.transactionId) {
          return (
            <div>
              &nbsp;{row.transactionId}&nbsp; <img src={copy} alt="" />
            </div>
          );
        }
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: false,
      cell: (row) => {
        if (row.amount) {
          return (
            <div>
              {row.currency} {formatBalance(row.amount)}
            </div>
          );
        }
      },
    },
    {
      name: "Date time",
      selector: (row) => row.createdAt,
      sortable: false,
      cell: (row) => {
        if (row.createdAt) {
          return (
            <div>{moment(row.createdAt).format("MMM D, YYYY - h:mm A")}</div>
          );
        }
      },
    },
  ];
  const noRecords = "No transaction records";

  const handleCrypto = () => {
    handleOpenAddMoneyModal("crypto");
  };

  const userMenu = (item) => handleFiat(item);

  function handleFiat(currency) {
    // const countryCode = ctzc
    //   .getAllISOByCurrencyOrSymbol("currency", currency)
    //   .toString();
    // const country = addMoneyCountries.find((c) => c.country === countryCode);

    let country;
    if (currency === "XAF") {
      country = addMoneyCountries.find((c) => c?.country === "CM");
    } else if (currency === "XOF") {
      country = addMoneyCountries.find((c) => c?.country === "CI");
    } else {
      const countryCode = ctzc
        .getAllISOByCurrencyOrSymbol("currency", currency)
        .toString();
      country = addMoneyCountries.find((c) => c?.country === countryCode);
    }

    if (country) {
      return (
        <Menu className="header-dropdown-user-container-action-buttons">
          {country.addMoney.map((option, index) => (
            <Menu.Item
              key={index}
              className="d-flex align-items-center mb-3"
              onClick={() => handleOpenAddMoneyModal(option)}
            >
              <img
                src={
                  option === "bank"
                    ? bankIcon
                    : option === "momo"
                    ? mobileMoneyIcon
                    : requestIcon
                }
                alt="bank"
              />
              <p className="ms-3">
                {option === "bank"
                  ? "Bank"
                  : option === "momo"
                  ? "Mobile money"
                  : "Eversend"}
              </p>
            </Menu.Item>
          ))}
        </Menu>
      );
    }
    return null; // Country not found in the array
  }

  const walletItem = wallets.find((wallet) => wallet.currency === name);

  return (
    <>
      <div>
        <h1 className="singleWallet-h1">
          <span
            onClick={() => {
              navigate("/wallets");
            }}
          >
            Wallets
          </span>
          &nbsp;
          <i className="fa fa-angle-right"></i>&nbsp; {name} wallet
        </h1>
      </div>
      <div className="single-wallet-buttons">
        <div className="row">
          <div className="col-md-12">
            {checkAccess("collections").granted ? (
              excludedCurrencies.includes(currentWallet.currency) ? (
                <button
                  type="button"
                  onClick={() =>
                    message.error("You can not add money to this wallet")
                  }
                  className="btn-one"
                  disabled={disableButtons}
                >
                  <img src={add} alt="" /> Fund wallets
                </button>
              ) : walletItem.isCrypto ? (
                <button
                  type="button"
                  className="btn-one"
                  onClick={handleCrypto}
                >
                  <img src={add} alt="" /> Fund wallets
                </button>
              ) : (
                <Dropdown
                  overlay={userMenu(walletItem.currency)}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <button
                    type="button"
                    className="btn-one"
                    disabled={disableButtons}
                  >
                    <img src={add} alt="" /> Fund wallets
                  </button>
                </Dropdown>
              )
            ) : null}

            {checkAccess("payouts").granted && !walletItem.isCrypto ? (
              <button
                type="button"
                onClick={handleOpenPayoutModal}
                className="btn-two"
                disabled={disableButtons}
              >
                <img src={send} alt="" /> Send Money
              </button>
            ) : null}
            {checkAccess("exchanges").granted ? (
              <button
                type="button"
                onClick={handleOpenExchangeModal}
                className="btn-two"
                disabled={disableButtons}
              >
                <img src={exchange} alt="" /> Exchange
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="single-wallet-financial">
            <div className="row">
              <div className="col-sm-12 col-md-10 offset-md-1">
                <div className="row text-center">
                  <div className="col-md-4">
                    <h1>Total {name} balance</h1>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {name}{" "}
                      {isLoading ? (
                        <Skeleton
                          active
                          paragraph={{ rows: 1 }}
                          shape="square"
                          title={false}
                          className="wallet-skeleton"
                        />
                      ) : (
                        currentWallet.availableAmount || 0.0
                      )}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h1>Total {name} collected </h1>
                    <p>
                      {name} {transactions.total_collections || 0.0}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h1>Total {name} payout</h1>
                    <p>
                      {name}
                      {transactions.total_payouts
                        ? Math.abs(transactions.total_payouts)
                        : 0.0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h1 className="wallet-single-h1">Transaction History</h1>
          <br />
          <div className="row">
            <div className="col-md-12">
              <DataTable
                noHeader
                noDataComponent={noRecords}
                columns={columns}
                data={transactions.transactions}
                pointerOnHover
                striped
                pagination
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
