import React from "react";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";
import { PDFDownloadLink } from "@react-pdf/renderer";

import CopyToClipboard from "../ui/CopyToClipboard";
import TagPdf from "./TagPdf";
import { ReactComponent as DownloadIcon } from "../../assets/images/download-card.svg";

import styles from "./requestTagsModal.module.scss";

const ViewTag = ({ tag, businessName }) => {
  return (
    <>
      <div className={styles.qRCodeContainer}>
        <QRCode
          className={styles.qRCode}
          value={`https://eversend.me/${tag}`}
          viewBox="0 0 175 175"
        />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.description}>
          <p>Share your tag or QR code, get paid instantly</p>
          <p>
            With your tag you can recieve payments from anywhere in the world
            directly into your wallet .
          </p>
        </div>

        <div className={styles.tag}>
          <CopyToClipboard text={`https://eversend.me/${tag}`} />
          {/* <EditButton onClick={onEdit} /> */}
        </div>
        <PDFDownloadLink
          document={<TagPdf tag={tag} businessName={businessName} />}
          fileName="tag"
          className={styles.download}
        >
          {({ loading }) => {
            return (
              <>
                {loading ? (
                  "..."
                ) : (
                  <>
                    Download QR code
                    <div className={styles.downloadIcon}>
                      <DownloadIcon />
                    </div>
                  </>
                )}
              </>
            );
          }}
        </PDFDownloadLink>
      </div>
    </>
  );
};

ViewTag.propTypes = {
  tag: PropTypes.string,
  businessName: PropTypes.string,
};
export default ViewTag;
