import React from "react";
import { ReactComponent as LoadingIcon } from "../../../assets/images/loader.svg";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import clsx from "clsx";

const Loader = ({ size = "small" }) => {
  return <LoadingIcon className={clsx(styles.loader, styles[size])} />;
};

Loader.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
};
export default Loader;
