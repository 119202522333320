import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../../utilities/hooks.js";
import { useNavigate } from "react-router-dom";
import {
  twoFactorAction,
  loginAction,
} from "../../../services/actions/index.js";
import SharedAuthLayout from "../../../components/layouts/Auth.layout.jsx";
import back from "../../../assets/images/back-button.svg";
import PinInput from "react-pin-input";
import Loader from "../../../components/Loader.jsx";
import { VERIFICATION_EMAIL, PASSWORD } from "../../../utilities/constants.js";

export default function TwoFactorEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.twoFactorReducer);
  const { loading: loginLoading, user } = useSelector(
    (state) => state.loginReducer
  );
  const session = window.localStorage.getItem("SESSION_ID");
  const email = window.sessionStorage.getItem(VERIFICATION_EMAIL);
  const password = window.sessionStorage.getItem(PASSWORD);
  const method = window.sessionStorage.getItem("METHOD");
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(120);
  function handleInputPinChange(otpInput) {
    setCode(otpInput);
  }

  function handleSubmit() {
    const data = {
      method: method,
      code: code,
      sessionId: session,
    };
    dispatch(twoFactorAction(data));
  }

  function handleResend() {
    dispatch(
      loginAction({
        email,
        password,
        method: method === "email" ? "text" : "email",
      })
    );
  }

  const methodText = method === "text" ? "phone" : method;

  useEffect(() => {
    // Timer function to decrement the seconds every second
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clear the timer when seconds reach zero
    if (seconds === 0) {
      clearInterval(timer);
    }

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, [seconds]); // Run the effect whenever 'seconds' changes

  useEffect(() => {
    if (user?.sentTo) {
      setSeconds(120);
    }
  }, [user]);

  return (
    <SharedAuthLayout
      title="Security authentication"
      paragraph={"Please enter the 6 digit code sent to your " + methodText}
    >
      <div style={{ marginTop: "10px" }}>
        <div className="text-center">
          <button className="external-link" onClick={() => navigate("/login")}>
            <img
              src={back}
              alt="back to login"
              style={{ marginRight: "10px" }}
            />
            Back to sign in
          </button>
        </div>
        <br />
        <div className="form-group">
          {methodText && (
            <label htmlFor="email" className="label-form">
              {methodText.charAt(0).toUpperCase() + methodText.slice(1)} Pin
            </label>
          )}

          <PinInput
            secret
            focus
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
            inputStyle={{
              border: "1px solid #CBCAE5",
              borderRadius: "8px",
              color: "#949494",
            }}
            length={6}
            type="numeric"
            onComplete={handleInputPinChange}
          />
        </div>
        <br />
        <button
          type="submit"
          disabled={!(code && code.length === 6)}
          onClick={handleSubmit}
          className="submit-btn"
        >
          {loading ? (
            <span className="d-flex align-items-center justify-content-between">
              <Loader dark={false} />
            </span>
          ) : (
            "Login"
          )}
        </button>

        <div>
          {seconds > 0 ? (
            <h1 className="no-otp" style={{ marginTop: "10px" }}>
              Didn't receive code? Send to{" "}
              {method === "email" ? "phone number" : "email"} in {seconds}
            </h1>
          ) : (
            <div>
              <br />
              <div className="text-center">
                <button
                  className="external-link"
                  onClick={handleResend}
                  disabled={loginLoading}
                >
                  {loginLoading ? (
                    <Loader dark={true} />
                  ) : (
                    <>
                      {method === "email"
                        ? "Send code to phone number"
                        : "Send code to email"}
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </SharedAuthLayout>
  );
}
