import makeAPICall from "../../../utilities/apiUtils";
import {
  twoFactor,
  twoFactorError,
  twoFactorSuccess,
  fetchProfile,
  // fetchProfileError,
  // fetchProfileSuccess,
} from "../../selectors";
import {
  AUTH_TOKEN,
  REDIRECT_URL,
  SESSION_HASH,
  USER_DATA,
} from "../../../utilities/constants";

import { message } from "antd";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_account_logged_in",
  },
};

const twoFactorAction =
  (data) =>
  (dispatch, _, { navigate }) => {
    dispatch(twoFactor());
    return makeAPICall({
      path: "auth/verifyLogin",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        const { user, token, session_hash } = res.data;
        dispatch(twoFactorSuccess(user));
        window.localStorage.setItem(AUTH_TOKEN, token);
        window.sessionStorage.removeItem("METHOD");
        window.localStorage.removeItem("SESSION_ID");
        window.localStorage.setItem(SESSION_HASH, session_hash);
        window.localStorage.setItem(USER_DATA, JSON.stringify(user));
        TagManager.dataLayer(tagManagerArgs);
        dispatch(fetchProfile());

        const redirectUrl = window.sessionStorage.getItem(REDIRECT_URL) ?? "/";
        navigate(redirectUrl);
      })
      .catch((err) => {
        message.error(err.message, 5);
        dispatch(twoFactorError(err.message));
      });
  };

export default twoFactorAction;
