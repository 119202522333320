import React from "react";
import PropTypes from "prop-types";

import Input from "../ui/Input";
import Button from "../ui/Button";

import styles from "./requestTagsModal.module.scss";

const RequestTagForm = ({
  tag,
  onChange,
  error,
  isEditing,
  onSubmit,
  isLoading,
}) => (
  <>
    <h2 className={styles.title}>{`${isEditing ? "Edit" : "Request"} Tag`}</h2>
    <Input
      label="Personalise your Tag"
      error={error}
      onChange={onChange}
      value={tag}
      className={styles.input}
    />
    <Button
      className={styles.button}
      size="medium"
      onClick={onSubmit}
      disabled={!tag}
      isLoading={isLoading}
    >
      {`${isEditing ? "Update" : "Create"} Tag`}
    </Button>
  </>
);

RequestTagForm.propTypes = {
  tag: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
export default RequestTagForm;
