import React, { useEffect, useState } from "react";
import {
  QuestionCircleFilled,
  WarningFilled,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ReactCountryFlag from "react-country-flag";
import { Button, Checkbox, Input, Select } from "antd";
import * as ctzc from "country-tz-currency";

import { ReactComponent as CloseBtnIcon } from "../../assets/images/close-btn.svg";
import { useDispatch } from "../../utilities/hooks";
import {
  deleteSingleBeneficiariesAction,
  checkAllBeneficiariesAction,
  checkSingleBeneficiariesAction,
  unCheckAllBeneficiariesAction,
  unCheckSingleBeneficiariesAction,
  showUploadBeneficiaryAction,
} from "../../services/actions";

import DeleteBeneficiaryModal from "../../components/modals/beneficiaries/DeleteBeneficiary";
import EditBeneficiaryModal from "../../components/modals/beneficiaries/EditBeneficiary";

import filterImage from "../../assets/images/filter.svg";

const { Option } = Select;

export default function AddBeneficiariesPreview({
  beneficiaries,
  clearBulkPreview,
}) {
  const [itemDetails, setItemDetails] = useState({});
  const [itemPosition, setItemPosition] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [benClone, setBenClone] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [filterValue, setFilterValue] = useState("Filter By");
  const [filtered, setFiltered] = useState([]);

  const [errorCounter, setErrorCounter] = useState(0);
  const [showEditBeneficiaryModal, setShowEditBeneficiaryModal] =
    useState(false);
  const [showDeleteBeneficiaryModal, setShowDeleteBeneficiaryModal] =
    useState(false);
  const [showBulkDeleteBeneficiaryModal, setShowBulkDeleteBeneficiaryModal] =
    useState(false);

  const dispatch = useDispatch();

  function handleOk() {
    setShowDeleteBeneficiaryModal(false);
    dispatch(deleteSingleBeneficiariesAction([itemPosition]));
    handleReselect();
  }

  function handleOkBulk() {
    setShowBulkDeleteBeneficiaryModal(false);
    let arr = beneficiaries.map((data, index) => (data.checked ? index : null));
    dispatch(deleteSingleBeneficiariesAction(arr));
    handleReselect();
  }

  function handleCloseModal() {
    setShowDeleteBeneficiaryModal(false);
    setShowBulkDeleteBeneficiaryModal(false);
  }

  function openModal(item, position, bulk = false) {
    setItemDetails(item);
    setItemPosition(position);
    if (bulk === true) {
      setShowBulkDeleteBeneficiaryModal(true);
    } else {
      setShowDeleteBeneficiaryModal(true);
    }
  }

  function openEditModal(item, position) {
    setItemDetails(item);
    setItemPosition(position);
    setShowEditBeneficiaryModal(true);
  }

  function onChange() {
    if (!isChecked) {
      dispatch(checkAllBeneficiariesAction());
    } else {
      dispatch(unCheckAllBeneficiariesAction());
    }
    setIsChecked(!isChecked);
  }

  function onSingleClicked(e, item, position) {
    if (!e.target.checked) {
      dispatch(unCheckSingleBeneficiariesAction(beneficiaries.indexOf(item)));
    } else {
      dispatch(checkSingleBeneficiariesAction(beneficiaries.indexOf(item)));
    }

    let arr = beneficiaries.filter((data) => {
      if (data.isBank) {
        return (
          data.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.lastName.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.bankAccountName
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          data.bankAccountNumber
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          data.bankName.toLowerCase().includes(inputValue.toLowerCase())
        );
      } else {
        return (
          data.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.lastName.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.phoneNumber.toLowerCase().includes(inputValue.toLowerCase())
        );
      }
    });

    setBenClone(arr);
  }

  function handleReselect() {
    setItemDetails(beneficiaries[0]);
    setItemPosition(0);
  }

  async function handleSubmit() {
    dispatch(showUploadBeneficiaryAction());
  }

  function handleInput(e) {
    setInputValue(e.target.value);
    let arr = beneficiaries.filter((data) => {
      if (data.isBank) {
        return (
          data.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.bankAccountName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.bankAccountNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.bankName.toLowerCase().includes(e.target.value.toLowerCase())
        );
      } else {
        return (
          data.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.phoneNumber.toLowerCase().includes(e.target.value.toLowerCase())
        );
      }
    });
    setBenClone(arr);
  }

  function handleFilterSelect(e) {
    setFilterValue(e);

    let arr = [];
    switch (e) {
      case "incomplete":
        arr = beneficiaries.filter((data) => {
          if (data.isBank) {
            return data.counter !== 7;
          } else {
            return data.counter !== 4;
          }
        });
        setBenClone(arr);
        break;
      case "complete":
        arr = beneficiaries.filter((data) => {
          if (data.isBank) {
            return data.counter === 7;
          } else {
            return data.counter === 4;
          }
        });
        setBenClone(arr);
        break;
      case "all":
        setBenClone(beneficiaries);
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    setItemDetails(beneficiaries[itemPosition]);
  }, [beneficiaries, itemPosition]);

  useEffect(() => {
    let counter = beneficiaries.filter((data) => {
      if (data.isBank) {
        return data.counter !== 7;
      } else {
        return data.counter !== 4;
      }
    });
    setErrorCounter(counter.length);
  }, [beneficiaries]);

  useEffect(() => {
    let arr = beneficiaries.filter((data) => {
      if (data.isBank) {
        return (
          data.firstName?.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.lastName?.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.bankAccountName
            ?.toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          data.bankAccountNumber
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          data.bankName?.toLowerCase().includes(inputValue.toLowerCase())
        );
      } else {
        return (
          data.firstName?.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.lastName?.toLowerCase().includes(inputValue.toLowerCase()) ||
          data.phoneNumber?.toLowerCase().includes(inputValue.toLowerCase())
        );
      }
    });

    setBenClone(arr);
  }, [beneficiaries, inputValue]);

  useEffect(() => {
    setFiltered(beneficiaries.filter((data) => data.checked));
  }, [beneficiaries]);

  return (
    <div className="bg-white full-width">
      <div className="beneficiary__header--preview d-flex justify-content-between align-items-center">
        <div className="beneficiary__header--title">
          <span className="text-bold color-bold text-smd">
            Review imported beneficiaries data
          </span>
        </div>
        <div className="beneficiary__header--closeBtn">
          <CloseBtnIcon onClick={clearBulkPreview} />
        </div>
      </div>
      <div className="beneficiary__body--preview flex">
        <div style={{ width: "100%" }}>
          <p className="color-bold text-smd pl-4 mt-4 mb-4">
            {beneficiaries.length} Imported beneficiaries
          </p>
          {errorCounter !== 0 ? (
            <>
              <div className="bg-pink pl-4 pr-4 flex justify-content-start pt-3 pb-3 mb-4">
                <WarningFilled
                  style={{ fontSize: "32px", color: "#B11030" }}
                  className="me-4"
                />
                <div>
                  <h6 className="text-error h6">
                    <b> Error notifications </b>
                  </h6>
                  {/* <span className="text-error text-bold">{errorCounter}</span> */}
                  <p>
                    {errorCounter} beneficiaries need to be reviewed because the
                    information is incomplete.
                  </p>
                </div>
              </div>
            </>
          ) : null}

          <div className=" flex justify-content-around pl-4 pr-4 ">
            <div className="d-flex align-items-center w-100">
              <div className="w-25">
                <span>
                  <Checkbox
                    defaultChecked={false}
                    checked={isChecked}
                    onChange={onChange}
                  >
                    &nbsp;&nbsp; Select All
                  </Checkbox>
                </span>
              </div>

              <div className="d-flex justify-content-end w-75">
                <span className="m-2">
                  <Input
                    placeholder="  🔍   Search"
                    size="small"
                    value={inputValue}
                    allowClear={true}
                    onChange={handleInput}
                    style={{ padding: "0 10px", margin: 0, height: "32px" }}
                  />
                </span>
                <span className="me-2 ms-2 mt-2">
                  <img height="20" width="20" src={filterImage} alt="" />
                  <Select
                    style={{ width: 120 }}
                    bordered={false}
                    onChange={handleFilterSelect}
                    value={filterValue}
                  >
                    <Option value="Filter By" disabled>
                      Filter By
                    </Option>
                    <Option value="incomplete">Incomplete</Option>
                    <Option value="complete">Complete</Option>
                    <Option value="all">All</Option>
                  </Select>
                </span>
                {filtered.length > 1 ? (
                  <span className="m-2">
                    <Button
                      onClick={() => openModal("", "", true)}
                      type="primary"
                      className="text-bold "
                      size="large"
                    >
                      Delete Selected
                    </Button>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <hr className="mb-0" />
          <div className="beneficiaries__list" style={{ maxHeight: "400px" }}>
            {benClone.map((item, index) => (
              <div
                className="pl-4 pr-4 flex justify-content-between pt-3 pb-3 beneficiary__item--details"
                key={index}
              >
                <div className="row w-100">
                  {item.isBank && item.counter !== 7 ? (
                    <>
                      <div className="w-100 flex justify-content-end">
                        <WarningFilled
                          style={{ fontSize: "26px", color: "#FFD200" }}
                          className="m-2"
                        />
                      </div>
                    </>
                  ) : !item.isBank && item.counter !== 4 ? (
                    <>
                      <div className="w-100 flex justify-content-end">
                        <WarningFilled
                          style={{ fontSize: "26px", color: "#FFD200" }}
                          className="m-2"
                        />
                      </div>
                    </>
                  ) : null}
                  <div className="col-sm-4">
                    <div className="d-flex align-items-center">
                      <span className="me-5 flex align-content-center justify-content-center">
                        <Checkbox
                          className=""
                          defaultChecked={item.checked}
                          checked={item.checked}
                          onChange={($event) =>
                            onSingleClicked($event, item, index)
                          }
                        ></Checkbox>
                      </span>
                      {/* avatar */}
                      <span className="beneficiary__avatar">
                        {/* country flag */}
                        {item.firstName[0]}
                        {item.lastName[0]}
                      </span>
                      <span className="ms-3">
                        <p className="color-bold text-bold text-smd mb-0">
                          {item.firstName} {item.lastName}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div>
                      <span className="ms-3">
                        <div className="beneficiary--preview-right-content p-0">
                          <div className="beneficiary-details-wrap pr-2 pl-1 w-100">
                            <div className="row">
                              <div className="col-sm-5">
                                <p className="text-smd ">Phone number</p>
                              </div>
                              <div className="col-sm-7">
                                <p className="text-smd text-end text-bold">
                                  (
                                  {
                                    ctzc.getCountryByCode(item.country)
                                      ?.areaCode
                                  }
                                  ){item?.phoneNumber}
                                </p>
                              </div>
                            </div>
                            {item.isBank ? (
                              <>
                                <div className="row">
                                  <div className="col-sm-5">
                                    <p className="text-smd ">Bank name</p>
                                  </div>
                                  <div className="col-sm-7">
                                    <p className="text-smd text-end text-bold">
                                      {item?.bankName}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {item.isBank ? (
                              <>
                                <div className="row">
                                  <div className="col-sm-5">
                                    <p className="text-smd ">Bank Code</p>
                                  </div>
                                  <div className="col-sm-7">
                                    <p className="text-smd text-end text-bold">
                                      {item?.bankCode}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {item.isBank && item.bankAccountName ? (
                              <>
                                <div className="row">
                                  <div className="col-sm-5">
                                    <p className="text-smd ">Account name</p>
                                  </div>
                                  <div className="col-sm-7">
                                    <p className="text-smd text-end text-bold">
                                      {item?.bankAccountName}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {item.isBank && item.bankAccountNumber ? (
                              <>
                                <div className="row">
                                  <div className="col-sm-5">
                                    <p className="text-smd ">Account Number</p>
                                  </div>
                                  <div className="col-sm-7">
                                    <p className="text-smd text-end text-bold">
                                      {item?.bankAccountNumber}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className="row">
                              <div className="col-sm-5">
                                <p className="text-smd">Country</p>
                              </div>
                              <div className="col-sm-7">
                                <p className="text-smd text-end text-bold">
                                  <ReactCountryFlag
                                    countryCode={item.country}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    title="US"
                                    style={{
                                      fontSize: "1.2em",
                                      lineHeight: "2em",
                                      borderRadius: "50px",
                                    }}
                                  />
                                  &nbsp; &nbsp;
                                  {
                                    ctzc.getCountryByCode(item.country)
                                      ?.countryName
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Button
                        type="link"
                        className="text-bold me-2 mt-4"
                        icon={<EditOutlined />}
                        style={{ color: "black" }}
                        onClick={() => openEditModal(item, index)}
                      >
                        Edit
                      </Button>
                      <Button
                        type="link"
                        className="text-bold me-2 mt-4"
                        icon={<DeleteOutlined />}
                        style={{ color: "black" }}
                        onClick={() => openModal(item, index)}
                      >
                        Delete Info
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="beneficiary__body--preview-footer">
        <div className="d-flex align-items-center">
          <div className="me-3">
            <span className="me-2">
              <QuestionCircleFilled />
            </span>
            <span className="color-bold">Not the correct CSV? </span>
          </div>
          <div className="color-primary" onClick={clearBulkPreview}>
            Click here
          </div>
        </div>
        <div>
          <Button
            type="primary"
            className="text-bold btn-primary"
            disabled={errorCounter !== 0}
            onClick={() => handleSubmit()}
          >
            Finish upload
          </Button>
        </div>
      </div>

      {showEditBeneficiaryModal ? (
        <EditBeneficiaryModal
          show={showEditBeneficiaryModal}
          onClose={() => {
            setShowEditBeneficiaryModal(false);
          }}
          item={itemDetails}
          beneficiaries={beneficiaries}
          itemPosition={itemPosition}
        />
      ) : null}

      {showDeleteBeneficiaryModal ? (
        <DeleteBeneficiaryModal
          show={showDeleteBeneficiaryModal}
          onClose={handleCloseModal}
          onOk={handleOk}
          item={itemDetails}
        />
      ) : null}

      {showBulkDeleteBeneficiaryModal ? (
        <DeleteBeneficiaryModal
          show={showBulkDeleteBeneficiaryModal}
          onClose={handleCloseModal}
          onOk={handleOk}
          item={itemDetails}
          onOkBulk={handleOkBulk}
          filter={filterValue}
        />
      ) : null}
    </div>
  );
}
