import ACTIONS from "../../constants";
import { createSelector } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  errorMessage: null,
  loading: false,
};

const twoFactorReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.TWO_FACTOR_USER:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.TWO_FACTOR_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ACTIONS.TWO_FACTOR_USER_ERROR:
      return {
        ...state,
        errorMessage: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const selectTagWhiteList = (state) =>
  state.twoFactorReducer?.user?.account?.whiteLists || [];

export const selectIsTagWhiteListed = createSelector(
  selectTagWhiteList,
  (list) => !!list.find(({ type }) => type === "tag")
);
export default twoFactorReducer;
