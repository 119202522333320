import React, { useState } from "react";

import { Button, message, Avatar } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { useDispatch } from "../../../utilities/hooks";
import StyledModal from "../styled-modal";
import { fetchUsersAction } from "../../../services/actions";
import makeAPICall from "../../../utilities/apiUtils";

export default function DeleteUserModal({ show, onClose, user }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  function onOk() {
    setIsLoading(true);
    return makeAPICall({
      path: `users/${user?.id}`,
      method: "DELETE",
    })
      .then((res) => {
        message.success({
          content: "User edited successfully",
          duration: 4,
        });
        setIsLoading(false);
        onClose();
        dispatch(fetchUsersAction());
      })
      .catch((err) => {
        setIsLoading(false);
        message.error({ content: err.message, duration: 4 });
      });
  }

  return (
    <StyledModal centered show={show} onClose={onClose} footer={null}>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 pt-5"></div>

          <div className="col-sm-4 pt-5">
            <div className=" pt-5">
              <h5 className="mt-5 text-bold text-center">
                Remove team member?
              </h5>
              <p className="mb-5">
                Are you sure you want to delete team member this action cannot
                be reversed
              </p>

              <div className="d-flex">
                <Avatar
                  style={{
                    backgroundColor: "#f56a00",
                    verticalAlign: "middle",
                    marginRight: "10px",
                  }}
                  size="large"
                  gap="4"
                >
                  {user.firstName.charAt()}
                  {user.lastName.charAt()}
                </Avatar>
                <div>
                  <p className="text-bold">
                    {user.firstName}&nbsp; {user.lastName}
                  </p>
                  <p style={{ marginTop: "-12px" }}>{user.email}</p>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <Button
                  style={{ width: "47%" }}
                  type="default"
                  size={"large"}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  style={{ width: "47%" }}
                  type="danger"
                  icon={<DeleteFilled />}
                  size={"large"}
                  onClick={onOk}
                  loading={isLoading}
                >
                  Yes, remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
