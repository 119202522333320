import React, { useState, useEffect } from "react";

import { ReactComponent as Copy } from "../../../assets/images/file-copy.svg";

import styles from ".//styles.module.scss";
import PropTypes from "prop-types";
import Button from "../Button";
import Pill from "../Pill";

const CopyToClipboard = ({ children, className, text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await window.navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (error) {
      console.error("Unable to copy to clipboard.", error);
    }
  };

  useEffect(() => {
    if (copied) {
      // reset copied after 5 seconds
      const timeOutId = setTimeout(() => setCopied(false), 5000);
      return () => clearTimeout(timeOutId);
    }
  }, [copied]);

  return (
    <div
      className={`${
        children ? styles.container : styles.textContainer
      } ${className}`}
    >
      {children || text}
      <div className={styles.copy}>
        {copied ? (
          <Pill text="Copied!" />
        ) : (
          <Button type="transparent" onClick={handleCopy}>
            <Copy className={styles.copyIcon} />
          </Button>
        )}
      </div>
    </div>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
};
export default CopyToClipboard;
