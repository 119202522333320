import React, { useState } from "react";
import verBadge from "../../../assets/images/Verified-badge.png";
// import unVerBadge from "../../../assets/images/unverified-badge.png";
import limitBadge from "../../../assets/images/limit-icon.png";
import * as ctzc from "country-tz-currency";
import Loader from "../../../components/Loader";
import deleteIcon from "../../../assets/images/deleteIcon.png";
import { message } from "antd";
import makeAPICall from "../../../utilities/apiUtilsFetch";
import { useDispatch } from "../../../utilities/hooks";
import {
  fetchProfileAction,
  selectProfileTag,
} from "../../../services/reducers/authReducers/profileReducer";
import { selectIsTagWhiteListed } from "../../../services/reducers/authReducers/twoFactorReducer";
import RequestTagCard from "../../../components/ui/RequestTagCard";
import { openModal } from "../../../services/reducers/modal";
import { useSelector } from "react-redux";
import CopyToClipboard from "../../../components/ui/CopyToClipboard";
import Button from "../../../components/ui/Button";

export default function BusinessInfo({
  user,
  profile,
  onChangeInputValue,
  onSubmitForm,
  isLoading,
}) {
  const dispatch = useDispatch();
  const businessTag = useSelector(selectProfileTag);
  const isTagWhiteListed = useSelector(selectIsTagWhiteListed);

  const [image, setImage] = useState({ preview: "", raw: "" });
  if (!profile.address) return <></>;

  const handleChange = (e) => {
    const uploadFile = e.target.files[0];
    if (uploadFile) {
      const fileType = uploadFile["type"];
      const validImageTypes = ["image/gif", "image/png", "image/jpeg"];
      if (validImageTypes.includes(fileType)) {
        setImage({
          preview: URL.createObjectURL(uploadFile),
          raw: uploadFile,
        });
        const formData = new FormData();
        formData.append("file", uploadFile);
        // console.log(...formData, 'yrryr')
        return makeAPICall({
          path: "account/logo",
          payload: formData,
          method: "POST",
        })
          .then((res) => {
            // console.log(res);
            dispatch(fetchProfileAction());
            message.success("Logo uploaded successfully", 5);
          })
          .catch((err) => {
            message.error(err.message, 5);
          });
      } else {
        message.error("Please select an image to upload");
      }
    }
  };

  const handleDelete = () => {
    if (user?.logo) {
      makeAPICall({
        path: "account/logo",
        method: "DELETE",
      })
        .then((res) => {
          // console.log(res);
          dispatch(fetchProfileAction());
          message.success("Logo deleted successfully", 5);
        })
        .catch((err) => {
          message.error(err.message, 5);
        });
    } else {
      message.error("You have no logo to delete");
    }
  };

  const handleOpenTagModal = () => dispatch(openModal("tagModal"));

  return (
    <>
      <div className="business-settings-card">
        <div>
          <div className="card">
            <div className="card-header">
              <h3>
                <div className="ant-steps-item-icon bg-primary bg-opacity-25 border-0">
                  <span className="ant-steps-icon ">
                    {profile.name.charAt(0)}
                  </span>
                </div>
                {profile.name} &nbsp;
                {user?.isVerified === true ? (
                  <span
                    className="verified rounded-3"
                    style={{ padding: "0px 20px 5px 20px" }}
                  >
                    <img src={verBadge} alt="" />
                    &nbsp; <span style={{ fontSize: "15px" }}>Verified </span>
                  </span>
                ) : (
                  <span
                    className="verified bg-success rounded-3"
                    style={{ padding: "0px 20px 5px 20px" }}
                  >
                    {/* <img src={verBadge} alt="" /> */}
                    &nbsp;
                    <span style={{ fontSize: "15px" }}>Not Verified </span>
                  </span>
                )}
              </h3>
            </div>

            <div className="card-body">
              <h1>
                <img src={limitBadge} alt="" />
                &nbsp; Your current limits
              </h1>

              <div className="row">
                <div className="col-md-10">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Your company logo</p>
                      <p className="text-black-50">
                        Add or update your company logo
                      </p>
                    </div>
                    <div className="col-md-6 settings-picture">
                      <div className="d-flex">
                        {image.preview ? (
                          <div className="no-uploads">
                            <img
                              src={image.preview}
                              style={{ borderRadius: "50%" }}
                              alt={profile.name}
                              width="70"
                              height="70"
                            />
                          </div>
                        ) : (
                          <div className="profilepic-holder">
                            {user?.logo === null ? (
                              <div className="no-uploads">
                                <div className="no-uploads-child">
                                  {profile.name.charAt(0)}
                                </div>
                              </div>
                            ) : (
                              <div className="no-uploads">
                                <img
                                  src={user?.logo}
                                  style={{ borderRadius: "50%" }}
                                  alt={profile.name}
                                  width="70"
                                  height="70"
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="align-self-center">
                          {user?.logo === null ? (
                            <label
                              htmlFor="upload-button"
                              className="label-profile"
                            >
                              Upload icon
                            </label>
                          ) : (
                            <label
                              htmlFor="upload-button"
                              className="label-profile"
                            >
                              Replace image
                            </label>
                          )}

                          <input
                            type="file"
                            id="upload-button"
                            style={{ display: "none" }}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="align-self-center">
                          <img
                            src={deleteIcon}
                            alt=""
                            className="deleteIcon"
                            onClick={handleDelete}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-10">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Your Business name</p>
                      <p className="text-black-50">
                        Changing or adjusting your business name takes effect
                        after 2days.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        name="name"
                        value={profile.name}
                        onChange={onChangeInputValue}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isTagWhiteListed && (
                <div className="row">
                  <div className="col-md-10">
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <p>Business Tag</p>
                      </div>
                      <div className="col-md-6">
                        {businessTag ? (
                          <>
                            <CopyToClipboard
                              text={`https://eversend.me/${businessTag}`}
                              className="business-settings-card-clip-board"
                            >
                              <input
                                type="text"
                                name="type"
                                value={businessTag}
                                onChange={onChangeInputValue}
                                disabled
                              />
                            </CopyToClipboard>
                            <Button
                              className="business-settings-card-edit-btn"
                              onClick={handleOpenTagModal}
                              type="link"
                            >
                              View tag
                            </Button>
                          </>
                        ) : (
                          <RequestTagCard onClick={handleOpenTagModal} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className="row">
                <div className="col-md-10">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Business type</p>
                      <p className="text-black-50">
                        What does your business do?
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="type"
                        value={profile.type}
                        onChange={onChangeInputValue}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-10">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Phone number</p>
                      <p className="text-black-50">
                        You cannot make changes to your phone number.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="phone"
                        value={profile.phone}
                        onChange={onChangeInputValue}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-10">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Email</p>
                      <p className="text-black-50">
                        You cannot make changes to your business address,
                        contact customer support if you would like to update
                        your business address.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        name="email"
                        value={profile.email}
                        onChange={onChangeInputValue}
                        type="email"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-10">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Business Address</p>
                      <p className="text-black-50">
                        You cannot make changes to your business address,
                        contact customer support if you would like to update
                        your business address.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="address"
                        value={profile.address}
                        onChange={onChangeInputValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-10">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Country</p>
                      <p className="text-black-50">
                        You cannot make changes to your country,
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p
                        className="p-3 w-100 "
                        style={{
                          background: "#e5e5e5",
                          border: "2px solid #bdbdbd",
                          borderRadius: "4px",
                        }}
                      >
                        {ctzc.getCountryByCode(profile.country).countryName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-9"></div>
                    <div className="col-md-3 text-right">
                      <button onClick={onSubmitForm}>
                        {isLoading ? <Loader dark={false} /> : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
