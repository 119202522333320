import React, { useState, useEffect, useCallback } from "react";
import Countdown from "react-countdown";
import { debounce } from "lodash";

import { useSelector, useDispatch } from "../../../../utilities/hooks";
import { formatMoney } from "../../../../utilities/helperFunctions";
import {
  Button,
  Input,
  Form,
  Select,
  InputNumber,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import { createPayoutQuotationAction } from "../../../../services/actions";
import { ReactComponent as FadingElipses } from "../../../../assets/images/fading-elipse.svg";
import exchangeLogo from "../../../../assets/images/exch.svg";
import * as ctzc from "country-tz-currency";
import styles from "./beneficiary.module.scss";
import { useFormikContext } from "formik";

const generateAmountData = ({
  value,
  from,
  amountType,
  profile,
  toAmount,
  paymentType,
}) => ({
  sourceWallet: from,
  amount: value.toString(),
  type: paymentType,
  destinationCountry: profile?.country ?? "",
  destinationCurrency: toAmount,
  amountType,
});

export default function Second({
  onClick,
  paymentType,
  onNumberChange,
  onSetPayOutData,
  payOutData,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { wallets } = useSelector((state) => state.walletsReducer);
  const { payoutQuotation, loadingQuotation: loadingPayQuotation } =
    useSelector((state) => state.transactionsReducer);

  const dispatch = useDispatch();

  const limit = 700;

  const toAmount = ctzc.getCountryByCode(
    values.selectedBeneficiary.country
  ).currencyCode;

  const [isShown, setIsShown] = useState(false);
  const [usedNumber, setUsedNumber] = useState(30000);

  const { Option } = Select;

  useEffect(() => {
    setUsedNumber(30000);
    onNumberChange({
      startTime: Date.now(),
      endTime: Date.now() + 30000,
    });
    setIsShown(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutQuotation?.quotation]);

  function onValueChange(name, value) {
    setFieldValue(name, value);

    if (name === "currency") {
      handleChangeInput(value);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createPayout = useCallback(
    debounce((data) => {
      dispatch(createPayoutQuotationAction(data));
    }, limit),
    [dispatch, limit]
  );

  const handleChangeInput = (e) => {
    const from = e;
    const { amount, selectedBeneficiary } = values;

    onSetPayOutData({
      sourceWallet: from,
      amount: amount.toString(),
      type: paymentType,
      destinationCountry: selectedBeneficiary?.country,
      destinationCurrency: toAmount,
      amountType: "SOURCE",
    });

    const data = {
      sourceWallet: from,
      amount: amount.toString(),
      type: paymentType,
      destinationCountry: selectedBeneficiary?.country,
      destinationCurrency: toAmount,
      amountType: "SOURCE",
    };

    dispatch(createPayoutQuotationAction(data));
  };

  const updateQueryOne = (value) => {
    if (!value) return setFieldValue("amount", 0);

    if (value) {
      setFieldValue("amount", value);

      const { currency, selectedBeneficiary } = values;

      const amountData = generateAmountData({
        value,
        from: currency,
        amountType: "SOURCE",
        profile: selectedBeneficiary,
        toAmount,
        paymentType,
      });

      onSetPayOutData(amountData);

      createPayout(amountData);
    }
  };

  const updateQuery = (value) => {
    if (!value) {
      setFieldValue("to", 0);
      return;
    }

    const { currency, selectedBeneficiary } = values;
    const walletDetails = wallets.filter(
      (wallet) => wallet.currency === currency
    );

    setFieldValue("to", value);

    const amountData = generateAmountData({
      value,
      from: currency,
      amountType: "DESTINATION",
      profile: selectedBeneficiary,
      toAmount,
      paymentType,
    });
    onSetPayOutData(amountData);

    const walletBalance = walletDetails?.amount;
    if (value / payoutQuotation?.quotation?.exchangeRate > walletBalance) {
      return message.error("Please enter amount less than your wallet balance");
    }

    createPayout(amountData);
  };

  const walletAmount = wallets.filter((wallet) => {
    return wallet.currency === values.currency;
  });

  useEffect(() => {
    if (payoutQuotation?.quotation) {
      const { sourceAmount, destinationAmount } = payoutQuotation?.quotation;
      setFieldValue("amount", sourceAmount);
      setFieldValue("to", destinationAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutQuotation?.quotation]);

  const showBankForm = () => {
    if (walletAmount[0]?.amount < payoutQuotation?.quotation?.totalAmount) {
      return message.error("Insufficient Amount");
    } else {
      onClick(payoutQuotation);
    }
  };

  const renderer = ({ seconds }) => <b>Continue ({seconds})</b>;

  const handleComplete = (e) => {
    setIsShown(false);
    dispatch(createPayoutQuotationAction(payOutData));
  };

  return (
    <div className="paySomeone">
      <div className="exchange-select">
        <p className={styles.label}>How much are you sending</p>

        <Form>
          <Input.Group compact style={{ width: "100%" }}>
            <Select
              style={{ width: "30%" }}
              name="currency"
              onChange={(value) => onValueChange("currency", value)}
              // disabled={loadingWallets}
              value={values.currency}
            >
              {wallets
                .filter((data) => data.enabled && data.isCrypto === false)
                .map((wallet) => {
                  return (
                    <Option key={wallet.currency} value={wallet.currency}>
                      <img src={wallet.icon} alt="flag" />
                      {wallet.currency}
                    </Option>
                  );
                })}
            </Select>
            <InputNumber
              style={{ width: "70%" }}
              value={values.amount}
              min="0"
              name="amount"
              onChange={updateQueryOne}
              autoFocus
              placeholder="5000"
            />
          </Input.Group>
          <br />
          {payoutQuotation?.quotation?.sourceCurrency ? (
            <p className="text-black-50">
              {values.currency} balance:&nbsp;
              {formatMoney(walletAmount[0]?.amount.toFixed(2))}
            </p>
          ) : null}
          <hr />
          <Row type="flex" align="middle" className="mt-4">
            <Col style={{ marginRight: "40px" }}>
              {/* <RotateIcon width="48px" /> */}
              <img src={exchangeLogo} alt="" />
            </Col>
            <Col style={{ marginRight: "20px" }}>
              <FadingElipses />
            </Col>
            <Col>
              <div>
                <span>Exchange Rate </span>
              </div>
              <div className="mt-2">
                {payoutQuotation?.quotation?.sourceCurrency ? (
                  <h6>
                    <b>
                      {payoutQuotation?.quotation?.sourceCurrency}&nbsp;1
                      &nbsp;&nbsp; =&nbsp;&nbsp;
                      {payoutQuotation?.quotation?.exchangeRate}&nbsp;
                      {payoutQuotation?.quotation?.destinationCurrency}
                    </b>
                  </h6>
                ) : null}
              </div>
              <span>
                Fees:
                <>
                  {payoutQuotation?.quotation?.totalFees ? (
                    <>
                      {payoutQuotation.quotation.sourceCurrency}&nbsp;
                      {formatMoney(payoutQuotation.quotation.totalFees)}
                    </>
                  ) : null}
                </>
              </span>
            </Col>
          </Row>
          <div className="pt-4">
            <p className={styles.label}>Reciever would get</p>
          </div>
          {loadingPayQuotation ? (
            <Spin tip="Loading...">
              <Input.Group compact>
                <Select
                  style={{ width: "30%" }}
                  defaultValue={toAmount}
                  disabled
                >
                  {wallets
                    .filter((data) => data.enabled)
                    .map((wallet) => {
                      return (
                        <Option key={wallet.currency} value={wallet.currency}>
                          <img src={wallet.icon} alt="flag" />
                          {wallet.currency}
                        </Option>
                      );
                    })}
                </Select>

                <InputNumber
                  value={values.to}
                  style={{ width: "70%" }}
                  min="1"
                  name="to"
                  onChange={updateQuery}
                  placeholder="5000"
                />
              </Input.Group>
            </Spin>
          ) : (
            <>
              <Input.Group compact>
                <Select
                  style={{ width: "30%" }}
                  name="toAmount"
                  defaultValue={toAmount}
                  disabled
                >
                  {wallets
                    .filter((data) => data.enabled)
                    .map((wallet) => {
                      return (
                        <Option key={wallet.currency} value={wallet.currency}>
                          <img src={wallet.icon} alt="flag" />
                          {wallet.currency}
                        </Option>
                      );
                    })}
                </Select>

                <InputNumber
                  value={values.to}
                  name="to"
                  style={{ width: "70%" }}
                  min="1"
                  onChange={(value) => updateQuery(value)}
                  placeholder="5000"
                />
              </Input.Group>
            </>
          )}
          <br />

          <Form.Item className={styles.footer}>
            <Button
              size="large"
              type="default"
              className={`pay-blue-btn ${styles.button}`}
              onClick={showBankForm}
              disabled={
                loadingPayQuotation ||
                !payoutQuotation?.quotation ||
                walletAmount[0]?.amount <
                  payoutQuotation?.quotation?.totalAmount
              }
            >
              {loadingPayQuotation ||
              !payoutQuotation?.quotation ||
              walletAmount[0]?.amount <
                payoutQuotation?.quotation?.totalAmount ||
              !isShown ? (
                <b>Continue</b>
              ) : (
                <Countdown
                  date={Date.now() + usedNumber}
                  renderer={renderer}
                  onComplete={handleComplete}
                />
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
