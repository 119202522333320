import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isLogin } from "../utilities/authUtils";

const PublicRouteCheck = ({ component: Component, title, ...rest }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Eversend | ${title}`;
  }, [title]);
  const loggedIn = isLogin();

  useEffect(() => {
    if (loggedIn) {
      navigate("/overview");
    }
  }, [loggedIn, navigate]);

  return <Component {...rest} />;
};

export default PublicRouteCheck;
