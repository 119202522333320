import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Checkbox, message, Spin } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useSelector } from "../../../utilities/hooks";
import makeAPICall from "../../../utilities/apiUtils";

export default function AddSingleBeneficiary({ addBeneficiary, banks }) {
  const { Option } = Select;

  const { countries } = useSelector((state) => state.CountryReducer.countries);
  // console.log(countries, 'test')
  const [isCompleted, setIsCompleted] = useState(false);
  const [banksSelect, setBanksSelect] = useState([]);
  const [isBank, setIsBank] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [loading, setLoading] = useState(false);

  const [beneficiaryDetails, setBeneficiaryDetails] = useState({
    firstName: "",
    lastName: "",
    country: "NG",
    phoneNumber: "",
    bankName: "",
    bankCode: "",
    bankAccountName: "",
    bankAccountNumber: "",
  });

  const countryArray = countries.map((item) => item?.country.toLowerCase());

  function onSelectChange(value, name) {
    if (name === "country") {
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [name]: value,
        bankName: "",
      });

      setBanksSelect(banks[value] || []);
    } else if (name === "bankName") {
      let bankSelected = banks[beneficiaryDetails.country]?.find(
        (data) => data.name === value
      );

      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [name]: value,
        bankCode: bankSelected.code,
      });
    } else {
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [name]: value,
      });
    }
  }

  function checked(e) {
    setIsBank(e.target.checked);
  }

  function onInputValueChanged(event) {
    setBeneficiaryDetails({
      ...beneficiaryDetails,
      [event.target.name]: event.target.value,
    });
  }

  function handleOnChangePhoneInput(value) {
    setBeneficiaryDetails({ ...beneficiaryDetails, phoneNumber: value });
  }

  function allowNumbersOnly(event) {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      event.keyCode === 190 ||
      event.keyCode === 110
    ) {
      return event;
    } else {
      return event.preventDefault();
    }
  }

  function onSubmitForm() {
    if (!isBank) {
      addBeneficiary([
        {
          firstName: beneficiaryDetails.firstName,
          lastName: beneficiaryDetails.lastName,
          country: beneficiaryDetails.country,
          phoneNumber: beneficiaryDetails.phoneNumber,
          isBank,
          isMomo: beneficiaryDetails.country === "NG" ? false : true,
        },
      ]);
    } else {
      addBeneficiary([
        {
          firstName: beneficiaryDetails.firstName,
          lastName: beneficiaryDetails.lastName,
          country: beneficiaryDetails.country,
          bankName: beneficiaryDetails.bankName,
          bankCode: beneficiaryDetails.bankCode,
          bankAccountName: beneficiaryDetails.bankAccountName,
          bankAccountNumber: beneficiaryDetails.bankAccountNumber,
          phoneNumber: beneficiaryDetails.phoneNumber,
          isBank,
          isMomo: beneficiaryDetails.country === "NG" ? false : true,
        },
      ]);
    }
    setBeneficiaryDetails({
      firstName: "",
      lastName: "",
      country: beneficiaryDetails.country,
      phoneNumber: "",
      bankName: "",
      bankAccountName: "",
      bankAccountNumber: "",
    });
  }

  function handleFieldChange(value) {
    if (beneficiaryDetails.country === "NG") {
      let params = {
        countryCode: beneficiaryDetails.country,
        bankCode: bankCode,
        accountNumber: value.target.value,
      };

      setLoading(true);
      return makeAPICall({
        path: "settings/banks/accounts",
        params,
        method: "GET",
      })
        .then((res) => {
          setBeneficiaryDetails({
            ...beneficiaryDetails,
            bankAccountName: res?.data?.account_name,
          });
          message.success("Beneficiary Fetched Successfully", 10);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    let bankInfo = banksSelect.filter((data) => data.name === bankName);
    if (bankInfo.length > 0) setBankCode(bankInfo[0].code);
  }, [bankName, banksSelect]);

  useEffect(() => {
    setBanksSelect(banks[beneficiaryDetails.country] || []);
  }, [banks, beneficiaryDetails?.country]);

  useEffect(() => {
    if (isBank) {
      setIsCompleted(
        beneficiaryDetails.bankAccountName === "" ||
          beneficiaryDetails.bankAccountNumber === "" ||
          beneficiaryDetails.bankAccountName === "" ||
          beneficiaryDetails.firstName === "" ||
          beneficiaryDetails.phoneNumber === "" ||
          beneficiaryDetails.lastName === "" ||
          beneficiaryDetails.country === ""
      );
    } else {
      setIsCompleted(
        beneficiaryDetails.phoneNumber === "" ||
          beneficiaryDetails.firstName === "" ||
          beneficiaryDetails.lastName === "" ||
          beneficiaryDetails.country === ""
      );
    }
  }, [beneficiaryDetails, isBank]);

  const selectedCountry = countries.filter((country) => {
    return country.country === beneficiaryDetails.country;
  });

  let showBankForm = selectedCountry.map((country, index) =>
    country.paymentTypes.find((element) => element === "bank") ? (
      <Form.Item name="remember" valuePropName="checked" key={index}>
        <Checkbox onChange={checked}>Include Bank Details</Checkbox>
      </Form.Item>
    ) : null
  );
  // console.log(showBankForm, "test");

  function handleKeyPress(e) {
    const charCode = e.which || e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault(); // Prevent input of numeric characters
    }
  }

  return (
    <>
      <Form layout="vertical">
        <Form.Item>
          <label className="text-smd">Beneficiary country</label>
          <Select
            showSearch
            placeholder="Select beneficiary country"
            optionFilterProp="children"
            onChange={(value) => onSelectChange(value, "country")}
            className="form__select mt-1"
            value={beneficiaryDetails.country}
            name="countryType"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries.map((data) => (
              <Option key={data.country} value={data.country}>
                {data.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <label className="text-smd mb-1">Phone number</label>
          <ReactPhoneInput
            inputProps={{
              name: "phoneNumber",
              required: true,
              autoFocus: true,
              className: "formInput",
            }}
            countryCodeEditable={false}
            value={beneficiaryDetails.phoneNumber}
            onChange={handleOnChangePhoneInput}
            enableSearch={true}
            onlyCountries={countryArray}
            disableCountryCode={false}
            country={beneficiaryDetails.country.toLowerCase()}
            placeholder="0800 000 0000"
            autoFormat={true}
            enableClickOutside={true}
          />
          <span></span>
        </Form.Item>

        <Form.Item>
          <label className="text-smd">First name</label>
          <Input
            placeholder="John"
            value={beneficiaryDetails.firstName}
            onChange={onInputValueChanged}
            className="mt-2"
            name="firstName"
            onKeyPress={handleKeyPress}
          />
        </Form.Item>

        <Form.Item>
          <label className="text-smd">Last name</label>
          <Input
            placeholder="Doe"
            value={beneficiaryDetails.lastName}
            onChange={onInputValueChanged}
            className="mt-2"
            name="lastName"
            onKeyPress={handleKeyPress}
          />
        </Form.Item>
        {showBankForm}

        {isBank ? (
          <>
            <Form.Item>
              <label className="text-smd">Bank name</label>
              <Select
                showSearch
                placeholder="Select Bank"
                optionFilterProp="children"
                onChange={(value) => {
                  onSelectChange(value, "bankName");
                  setBankName(value);
                }}
                className="form__select mt-1"
                value={beneficiaryDetails.bankName}
                name="bankName"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {banksSelect.map((data) => (
                  <Option key={data.id} value={data.name}>
                    {data.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <label className="text-smd mb-1">Bank account number</label>
              <Input
                placeholder="101124354234"
                value={beneficiaryDetails.bankAccountNumber}
                onChange={(value) => {
                  onInputValueChanged(value);
                }}
                onBlur={(value) => {
                  onInputValueChanged(value);
                  handleFieldChange(value);
                }}
                className="mt-2"
                name="bankAccountNumber"
                maxLength={15}
                onKeyDown={allowNumbersOnly}
              />
            </Form.Item>
            <div className="d-flex justify-content-end">
              {loading ? <Spin /> : null}
            </div>
            <Form.Item>
              <label className="text-smd">Bank account name</label>
              <Input
                placeholder="John Doe"
                value={beneficiaryDetails.bankAccountName}
                onChange={onInputValueChanged}
                className="mt-2"
                name="bankAccountName"
                disabled={beneficiaryDetails.country === "NG"}
              />
            </Form.Item>
          </>
        ) : null}

        <Form.Item className="mt-5">
          <Button
            type="primary"
            className="resend-modal-btn text-bold btn-primary"
            disabled={isCompleted}
            onClick={onSubmitForm}
          >
            Save as beneficiary
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
