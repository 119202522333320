import makeAPICall from "../../utilities/apiUtils";

import {
  fetchStatement,
  fetchStatementSuccess,
  fetchStatementError,
  fetchSingleStatement,
  fetchSingleStatementError,
  fetchSingleStatementSuccess,
  deleteStatament,
} from "../selectors";

const fetchStatementAction =
  ({ params = null }) =>
  (dispatch) => {
    dispatch(fetchStatement());
    return makeAPICall({
      path: "statement",
      method: "GET",
      params,
    })
      .then((res) => {
        const { fileURL } = res.data;
        dispatch(fetchStatementSuccess(fileURL));
      })
      .catch((err) => {
        dispatch(fetchStatementError(err.message));
      });
  };

const fetchSingleStatementAction = (number) => (dispatch) => {
  dispatch(fetchSingleStatement());
  return makeAPICall(
    {
      path: `transactions/${number}`,
      method: "GET",
    },
    null,
    null,
    "v2"
  )
    .then((res) => {
      const { transaction } = res.data;

      dispatch(fetchSingleStatementSuccess(transaction));
    })
    .catch((err) => {
      dispatch(fetchSingleStatementError(err.message));
    });
};

const deleteStatementAction = () => (dispatch) => {
  dispatch(deleteStatament());
};

export {
  fetchStatementAction,
  fetchSingleStatementAction,
  deleteStatementAction,
};
