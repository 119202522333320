import React, { useState } from "react";
import { ReactComponent as Logo } from "../../../assets/images/Eversend conf.4 for white bcgrnd.svg";
import { ReactComponent as Envelope } from "../../../assets/images/email.svg";
import { Input, Button } from "antd";
import ResendOTPRegisterModal from "../../../components/modals/ResendOTPRegister";
import WelcomeModal from "../../../components/modals/Welcome";
import { VERIFICATION_EMAIL } from "../../../utilities/constants";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import { verifyAccountAction } from "../../../services/actions";
//import { AuthError } from "../../../components/Errors/AuthError";

export default function VerificationEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.verificationReducer);
  const email = window.sessionStorage.getItem(VERIFICATION_EMAIL);
  const pinId = window.sessionStorage.getItem("PIN_ID");
  const [show, setShow] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [code, setCode] = useState("");
  const [hide, setHide] = useState(true);
  // const [loadingState, setLoadingState] = useState(false);

  function showText() {
    setHide(false);
  }

  function handleChange(event) {
    setCode(event.target.value);
  }

  function handleSubmit() {
    // setLoadingState(true);
    dispatch(
      verifyAccountAction({
        email,
        code,
        pinId,
      })
    );
  }

  // useEffect(() => {
  //   setLoadingState(loading);
  // }, [loading]);

  return (
    <>
      <div className="bg-light-grey signup-preview">
        <div className="auth-login-wrap signup-wrap">
          <div className="full-width">
            <div className="logo mb-5">
              <Logo />
            </div>
            <div className="auth__card--wrap">
              <div className="mb-4">
                <div className="section__icon--md mb-3">
                  <Envelope />
                </div>
                <div className="text-center">
                  <p className="text-bold text-md color-bold mb-0 ">
                    We sent you an Email
                  </p>
                  <p className="text-smd">
                    We sent a verification code to your email
                    <span style={{ color: "#0000E0" }}>&nbsp;{email}</span>
                  </p>
                </div>
              </div>

              {/* {errorMessage ? <AuthError text={errorMessage} /> : null} */}

              <div
                className=""
                style={{ maxWidth: "349px", position: "relative" }}
              >
                <div>
                  <Input
                    className="text-center"
                    placeholder="Type verification code"
                    value={code}
                    onChange={handleChange}
                    maxLength={6}
                    style={{ width: "100%", maxWidth: "100%" }}
                  />
                </div>
                {code.length === 6 ? (
                  <div className="mt-5">
                    <Button
                      type="primary"
                      className="resend-modal-btn btn-primary text-bold text-smd"
                      disabled={loading}
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Verify
                    </Button>
                  </div>
                ) : null}
              </div>

              <div>
                <p className="text-smd color-black mt-4">Didnt get email?</p>
                <p className="mt-4 text-normal text-smd">
                  If you dont get an email from us within 5 minutes, one of
                  these could have happened
                  {hide ? (
                    <>
                      <span className="mt-4 text-normal text-smd">... </span>
                      <span
                        className="color-primary cursor-pointer"
                        onClick={showText}
                      >
                        More
                      </span>
                    </>
                  ) : (
                    ":"
                  )}
                </p>
                {!hide ? (
                  <>
                    <p>1. The email you entered has a typo</p>
                    <p>
                      2. The email might be in your spam, please check it,
                      sometimes it happens
                    </p>
                    <p>3. You accidentally entered the wrong email</p>
                  </>
                ) : null}
              </div>
              <p
                className="text-bold color-primary text-smd mt-5 cursor-pointer"
                onClick={() => navigate("/signup")}
              >
                Re-enter your email and try again.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ResendOTPRegisterModal show={show} setShow={setShow} />
      <WelcomeModal
        setShowWelcomeModal={setShowWelcomeModal}
        showWelcomeModal={showWelcomeModal}
      />
    </>
  );
}
