import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roles: {},
  errorMessage: null,
  loading: false,
};

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    fetchRoles(state) {
      state.errorMessage = null;
      state.loading = true;
    },
    fetchRolesSuccess(state, action) {
      state.roles = action.payload;
      state.loading = false;
    },
    fetchRolesError(state, action) {
      state.errorMessage = action.payload;
      state.loading = false;
    },
  },
});

export const { fetchRoles, fetchRolesSuccess, fetchRolesError } =
  roleSlice.actions;

export default roleSlice.reducer;
