import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    modals: {},
  },
  reducers: {
    openModal: (state, action) => {
      const modalKey = action.payload;
      state.modals[modalKey] = true;
    },
    closeModal: (state, action) => {
      const modalKey = action.payload;
      state.modals[modalKey] = false;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectIsModalOpen = (modalKey) => (state) =>
  !!state.modal?.modals[modalKey];

export default modalSlice;
