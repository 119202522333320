import React, { useState } from "react";
import "./pay.css";

import StyledModal from "../styled-modal";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Four";
import { useDispatch } from "../../../utilities/hooks";
import {
  deletePayoutAction,
  deletePayoutQuotationAction,
  resetQuotationParams,
  toggleSendMoneyBeneficiaryModalAction,
} from "../../../services/actions";
import StepsWrapper from "../../ui/StepsWrapper";

import styles from "./payout.module.scss";

export default function PayoutModal({ show, onClose }) {
  const [current, setCurrent] = useState(0);
  const [showNow, setShowNow] = useState(false);
  const [receipientName, setReceipientName] = useState({
    firstName: "",
    lastName: "",
  });
  const [accountType, setAccountType] = useState("");
  const [accountDetails, setAccountDetails] = useState();
  const [currencyDetails, setCurrencyDetails] = useState();
  const [transactionDetails, setTransactionDetails] = useState();
  const dispatch = useDispatch();

  const steps = [
    {
      title: "Reciepient",
      content: (
        <First onClick={nextFirst} showBenModal={handleOpenPayoutModal} />
      ),
    },
    {
      title: "Recievers information",
      content: <Second onClick={nextSecond} goBack={() => prev()} />,
    },
    {
      title: "Review payout",
      content: (
        <Third
          accountType={accountType}
          accountDetails={accountDetails}
          currencyDetails={currencyDetails}
          profile={receipientName}
          onClick={nextLast}
          goBack={() => prev()}
        />
      ),
    },
    {
      title: "Receipt",
      content: (
        <Fourth
          transDetails={transactionDetails}
          onClick={newPayout}
          gotoHome={createNew}
        />
      ),
    },
  ];

  function resetInputs() {
    setReceipientName({ firstName: "", lastName: "" });
    setShowNow(false);
    setAccountType("");
    setAccountDetails();
    setCurrencyDetails();
    setTransactionDetails();
    dispatch(deletePayoutAction());
    dispatch(deletePayoutQuotationAction());
  }

  function nextFirst(firstName, lastName) {
    setReceipientName({ firstName: firstName, lastName: lastName });
    dispatch(deletePayoutAction());
    dispatch(deletePayoutQuotationAction());
    setShowNow(true);
    setCurrent(current + 1);
  }

  function nextSecond(bankType, receipientDetails, quotation) {
    setAccountType(bankType);
    setAccountDetails(receipientDetails);
    setCurrencyDetails(quotation);
    setShowNow(false);
    setCurrent(current + 1);
  }

  function nextLast(payout) {
    setTransactionDetails(payout);
    setCurrent(current + 1);
  }

  function prev() {
    setCurrent(current - 1);
    dispatch(resetQuotationParams());
    dispatch(deletePayoutQuotationAction());
  }

  function onCloseModal(item) {
    setCurrent(item);
    dispatch(resetQuotationParams());
    resetInputs();
    onClose();
  }

  function handleOpenPayoutModal() {
    dispatch(toggleSendMoneyBeneficiaryModalAction());
    onClose();
  }

  function newPayout() {
    dispatch(resetQuotationParams());
    resetInputs();
    // onClose()
    // dispatch(toggleSendMoneyBeneficiaryModalAction());
    setCurrent(0);
  }

  function createNew() {
    dispatch(resetQuotationParams());
    resetInputs();
    onClose();
  }

  return (
    <div>
      <StyledModal show={show} onClose={() => onCloseModal(0)}>
        <StepsWrapper
          steps={steps}
          currentStep={current}
          contentClassName="payout-modal-text"
          onGoBack={prev}
        >
          <>{steps[current].content}</>
          {showNow && (
            <div className={`selected ${styles.userCard}`}>
              <div>
                <div className="text-right"></div>
                <div className="grey-bg-name text-center">
                  <p className="receipient-name">Payments to</p>
                  <p className="receipient-name" style={{ fontWeight: "bold" }}>
                    {receipientName.firstName}&nbsp; {receipientName.lastName}
                  </p>
                </div>
              </div>
            </div>
          )}
        </StepsWrapper>
      </StyledModal>
    </div>
  );
}
