import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { config } from "dotenv";
import { Provider } from "react-redux";
import createStore from "./services/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { unregister } from "./serviceWorkerRegistration";
import { IntercomProvider } from "react-use-intercom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import TagManager from "react-gtm-module";

import "./index.css";

import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";

config();

const tagManagerArgs = {
  gtmId: "GTM-TNX9SR8",
};
TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// H.init('2d12kler', {
// 	tracingOrigins: true,
// 	networkRecording: {
// 		enabled: true,
// 		recordHeadersAndBody: true,
// 	},
// });

const store = createStore();
let persistor = persistStore(store);

const AppInitializer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    store.setNavigate(navigate);
  }, [navigate]);



  return (
    <Provider store={store}>
      <React.StrictMode>
        <PersistGate loading={null} persistor={persistor}>
          <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
            <App />
          </IntercomProvider>
        </PersistGate>
      </React.StrictMode>
    </Provider>
  );
};

export default AppInitializer;

ReactDOM.render(
  <BrowserRouter>
    <AppInitializer />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
