import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../utilities/hooks";
import accessIcon from "../../assets/images/noAccess.svg";
import { useIntercom } from "react-use-intercom";
import { Spin } from "antd";
import GeneralTable from "../../components/GeneralTable";
import { ReactComponent as EmptyState } from "../../assets/images/emptystate-cards.svg";
import moment from "moment";
import { AccessControl } from "accesscontrol";
import { useNavigate } from "react-router-dom";
import makeAPICall from "../../utilities/apiUtils";
import EditCardUserModal from "../../components/modals/editCardUser";
import CreateCardUserModal from "../../components/modals/createCardUser";

export default function CardsUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show, boot } = useIntercom();
  const { roles } = useSelector((state) => state.RoleReducer);
  const { user: userInfo } = useSelector((state) => state.profileReducer);
  const [allUsers, setAllUsers] = useState();
  const [hasKyc, setHasKyc] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editCardUsersModal, setEditCardUsersModal] = useState(false);
  const [createCardUsersModal, setCreateCardUsersModal] = useState(false);
  const [editUser, setEditUser] = useState(null);

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(userInfo?.role).createAny(value);
    return permission;
  }

  boot({
    name: userInfo?.account?.name,
    email: userInfo?.account?.email,
    user_id: userInfo?.account?.id,
    isB2B: "B2B",
  });

  const now = moment().format("YYYY-MM-DD");
  const dateLimits = {
    today: { from: moment().startOf("day").format("YYYY-MM-DD"), to: now },
    week: {
      from: moment().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    month: {
      from: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    year: {
      from: moment().subtract(1, "year").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
  };

  const filterOptions = [{ label: "Date Created", clickable: true }];
  // const filterOptions = [];
  const filterSelectOptions = {
    "Date Created": [
      "Select date",
      "Today",
      "This week",
      "This month",
      "Past year",
      "Custom",
    ],
  };
  // const filterSelectOptions = {};

  useEffect(() => {
    if (userInfo.account && userInfo.account.isVerified === false) {
      setHasKyc(false);
    } else {
      getCards();
      setHasKyc(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userInfo]);

  function getCards(params = null) {
    setLoading(true);
    return makeAPICall({
      path: "cards/getUsers",
      method: "GET",
      params,
    })
      .then((res) => {
        // console.log(mergedArray, "newArray");
        setLoading(false);
        setAllUsers(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "err");
      });
  }

  const isArrayEmpty = !allUsers?.users || allUsers?.users.length === 0;

  const goToCard = () => {
    navigate("/cards");
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "id",
      render: (id, row) =>
        checkAccess("cards").granted &&
        (!id.startsWith("AUI") ? (
          <button
            type="button"
            className="general-btn"
            onClick={() => handleOpenEditModal(row)}
          >
            Edit user
          </button>
        ) : null),
    },
  ];

  const paginateAll = (page, pageSize) => {
    const params = {
      limit: pageSize,
      page: page,
    };
    getCards(params);
  };

  const filterTransactions = (value, custom) => {
    if (
      (value === "Today" ||
        value === "This week" ||
        value === "This month" ||
        value === "Past year") &&
      !custom
    ) {
      const params = {
        from: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "Past year"
            ? "year"
            : "today"
        ].from,
        to: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "Past year"
            ? "year"
            : "today"
        ].to,
      };
      getCards(params);
    } else if (value === "Custom") {
      const params = {
        from: custom.from,
        to: custom.to,
      };
      getCards(params);
    } else if (value === "cleared") {
      getCards();
    } else {
      getCards();
    }
    // console.log(value, custom, 'text');
    // } else if (value === "cleared") {
    //     dispatch(fetchCardsAction({}));
    // } else {
    //     dispatch(
    //       fetchCardsAction({
    //         params: {
    //           status:
    //             value === "Active"
    //               ? "active"
    //               : value === "Frozen"
    //               ? "frozen"
    //               : value === "Deleted"
    //               ? "terminated"
    //               : value === "Deleting"
    //               ? "terminating"
    //               : null,
    //         },
    //       })
    //     );
    // }
  };

  const handleInputChange = (value) => {
    const params = {
      query: value,
    };
    getCards(params);
  };

  function handleOpenEditModal(item) {
    setEditCardUsersModal(true);
    setEditUser(item);
  }

  function handleCloseEditModal() {
    setEditCardUsersModal(false);
  }

  function handleOpenCreateModal() {
    setCreateCardUsersModal(true);
    getCards();
  }

  function handleCloseCreateModal() {
    setCreateCardUsersModal(false);
    getCards();
  }
  console.log({ hasKyc });
  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <div>
          <h1 className="text-md color-bold text-bold mt-2">
            Cards users management
          </h1>
        </div>
        {hasKyc ? (
          <div>
            <div>
              {isArrayEmpty && !loading ? (
                <div className="completed-kyc-state">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <EmptyState />
                      <h1>Manage your virtual cards users here</h1>
                      <p>
                        Create and edit users that have access to your virtual
                        card
                      </p>
                      {checkAccess("cards").granted ? (
                        <div>
                          <button
                            type="button"
                            className="general-btn me-2"
                            onClick={goToCard}
                          >
                            Back to cards
                          </button>
                          <button
                            type="button"
                            className="general-btn"
                            onClick={handleOpenCreateModal}
                          >
                            Create card user
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      type="button"
                      className="general-btn"
                      onClick={goToCard}
                    >
                      Back to cards
                    </button>
                    {checkAccess("cards").granted && (
                      <div>
                        <button
                          type="button"
                          className="general-btn"
                          onClick={handleOpenCreateModal}
                        >
                          Create card user
                        </button>
                      </div>
                    )}
                  </div>
                  <br />

                  <div className="card-table-h1 mt-4">
                    <h1>Card users</h1>

                    <GeneralTable
                      columns={columns}
                      placeHolder="Search user by email, name or phone number"
                      filter={filterOptions}
                      filterSelect={filterSelectOptions}
                      download={false}
                      filterTransactions={filterTransactions}
                      handleInputChange={handleInputChange}
                      pagination={allUsers}
                      paginateAll={paginateAll}
                      data={allUsers?.users}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="completed-kyc-state">
            <div className="row">
              <div className="col-md-12 text-center">
                <img src={accessIcon} alt="" />
                <h1>You do not have access</h1>
                <p>
                  Please complete your KYB verification, to be able to view
                  contents of this page, click on the “complete KYB” button
                </p>
                <button onClick={show} style={{ cursor: "pointer" }}>
                  Complete KYB
                </button>
              </div>
            </div>
          </div>
        )}
        {editCardUsersModal && (
          <EditCardUserModal
            show={editCardUsersModal}
            onClose={handleCloseEditModal}
            user={editUser}
          />
        )}
        {createCardUsersModal && (
          <CreateCardUserModal
            show={createCardUsersModal}
            onClose={handleCloseCreateModal}
          />
        )}
      </Spin>
    </React.Fragment>
  );
}
