import { useState } from "react";
import plus from "../../assets/images/Add_plain.svg";
import close from "../../assets/images/Close.svg";

const AccordionItem = ({ title, description, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item mb-3">
      <div className="d-flex align-items-center" onClick={handleClick}>
        <img src={isOpen ? close : plus} alt="open faq" />
        <div className="accordion-item-title">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>

      {isOpen && (
        <div className="accordion-item-content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
