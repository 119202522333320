import React, { useState } from "react";
import { Select, InputNumber, Input } from "antd";
import { useSelector } from "../../../../utilities/hooks";

const { TextArea } = Input;

export default function Second({ next, currencyDetails }) {
  const { wallets } = useSelector((state) => state.walletsReducer);
  const [currency, setCurrency] = useState(
    currencyDetails?.currency
      ? currencyDetails.currency
      : wallets[0]?.currency
      ? wallets[0].currency
      : "USD"
  );
  const [amount, setAmount] = useState(
    currencyDetails?.amount ? currencyDetails.amount : ""
  );
  const [reason, setReason] = useState(
    currencyDetails?.reason ? currencyDetails.reason : ""
  );

  const { Option } = Select;

  function handleChange(value) {
    setCurrency(value);
  }

  const onChange = (value) => {
    setAmount(value);
  };

  return (
    <div className="w-100 h-100">
      <div className="banktransfer-others">
        <div>
          <div className="wallet-request-title mb-2">Enter request amount</div>
          <div className="custom-select-card d-flex justify-content-between mb-3">
            <Select onChange={handleChange} value={currency}>
              {wallets
                .filter((data) => data.enabled)
                .map((wallet) => {
                  return (
                    <Option key={wallet.currency} value={wallet.currency}>
                      <img src={wallet.icon} alt="flag" />
                      {wallet.currency}
                    </Option>
                  );
                })}
            </Select>
            <div style={{ textAlign: "right" }}>
              <InputNumber
                value={amount}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={onChange}
                bordered={false}
                placeholder="5000"
              />
            </div>
          </div>
          <div className="custom-play">
            <TextArea
              value={reason}
              placeholder="Enter reason for request"
              onChange={(e) => setReason(e.target.value)}
              classNames="custom-select-card"
            />
          </div>
        </div>
        <br />
        <div className="text-center">
          <button
            className="btn-btn-btn"
            style={{ cursor: "pointer" }}
            disabled={amount === 0 || amount === ""}
            onClick={() =>
              next({ currency: currency, amount: amount, reason: reason })
            }
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
